import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import env from './env';

export const paths = {
    component: './components/',
    partial: './components/_partials/',
    container: './container/',
    icons: './icons'
};

export const monthsArr = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export function formatDate(date) {
    return date.replace(/-/g, '/');
}

export function formatQuestion(question, name, gender) {
    let formatted = question;
    if (question != undefined) {
        const pronoun = (gender == 0) ? 'her' : 'his';
        const altPronoun = (gender == 0) ? 'she' : 'he';
        const PersonalTonicPronouns = (gender == 0) ? 'her' : 'him';
        const possessivePronoun = (gender == 0) ? 'herself' : 'himself';
        formatted = formatted.replace(/\[BABY NAME\]/g, name)
            .replace(/\[BABYNAME\]/g, name)
            .replace(/\[HIS\/HER\]/g, pronoun)
            .replace(/\[HIM\/HER\]/g, PersonalTonicPronouns)
            .replace(/\[HIMSELF\/HERSELF\]/g, possessivePronoun)
            .replace(/\[HE\/SHE\]/g, altPronoun);
    }
    return formatted;
}

export function formatAnswer(answer, exportText, child, isCustom = false) {
    let formatted = exportText;
    const gender = child.gender;
    const name = capName(child.first);
    let answerText = null;
    if (answer.length > 0 && !answer[0].hasOwnProperty('answer') || isCustom) {
        answerText = (answer.length == 1) ? answer[0] : '';
        if (answer.length > 1) {
            for (let i = 0; i < answer.length; i++) {
                if (i == (answer.length - 1)) {
                    answerText = `${answerText} and ${answer[i]}`;
                } else {
                    answerText = `${answerText} ${answer[i]},`;
                }
            }
        }
    } else {
        answerText = (Object.keys(answer).length == 1) ? answer[Object.keys(answer)[0]].answer : '';
        if (Object.keys(answer).length > 1) {
            for (const i in answer) {
                if (i == (Object.keys(answer)[Object.keys(answer).length - 1])) {
                    answerText = `${answerText} and ${answer[i].answer}`;
                } else {
                    answerText = `${answerText} ${answer[i].answer},`;
                }
            }
        }
    }
    const pronoun = (gender == 0) ? 'her' : 'his';
    const altPronoun = (gender == 0) ? 'she' : 'he';
    const PersonalTonicPronouns = (gender == 0) ? 'her' : 'him';
    const possessivePronoun = (gender == 0) ? 'herself' : 'himself';


    formatted = formatted.replace(/\[BABY NAME\]/g, name)
        .replace(/\[BABYNAME\]/g, name)
        .replace(/\[HIS\/HER\]/g, pronoun)
        .replace(/\[HE\/SHE\]/g, altPronoun)
        .replace(/\[HIM\/HER\]/g, PersonalTonicPronouns)
        .replace(/\[HIMSELF\/HERSELF\]/g, possessivePronoun)
        .replace(/\[ANSWER\]/g, answerText);
    return capName(formatted);
}

export function capName(name) {
    if (name != '' && name != undefined) {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }
    return name;
}

export function truncMod(dividend, divisor, numDec = 1) {
    let res = 0;
    if (dividend % divisor != 0) {
        res = (dividend / divisor).toString();
        res = parseInt(parseFloat(res.substr(res.indexOf('.'))) * Math.pow(10, numDec), 10);
    }
    return res;
}

export function setTitle(child, event) {
    let title = '';
    const months = event.age_months;
    const weeks = event.age_weeks;
    let childBday = child.birthdate;
    childBday = childBday.replace(/-/g, '/');
    childBday = new Date(childBday);
    let birthdate;
    const time = event.title_name.toLowerCase();
    if (time == '1 week') {
        birthdate = Moment(childBday).add(weeks, 'week');
    } else if (time == '2 weeks') {
        birthdate = Moment(childBday).add(weeks, 'week');
    } else if (time == '3 weeks') {
        birthdate = Moment(childBday).add(weeks, 'week');
    } else {
        birthdate = Moment(childBday).add(months, 'M');
    }
    const age = birthdate.format('MMMM Do, YYYY');
    title = `${capName(child.first)} is`;
    title = title.charAt(0).toUpperCase() + title.slice(1);

    const old = `${title} ${time} old!`;
    return { title: age, subtitle: old };
}

export function holidayTitle(event) {
    let date = event.active_date;
    date = date.replace(/-/g, '/');
    date = new Date(date);

    const title = event.title_name;
    const subtitle = (Moment(date).isValid()) ?
        `${monthsArr[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}` : '';

    return { title, subtitle };
}

export function checkBackground(data) {
    return axios.get('/api/web/check-background', { params: data })
        .then((response) => response.data.data)
        .catch((error) => {
            handleError(error);
        });
}

export function defaultBackground(id) {
    const data = { params: { id } };
    return axios.get('/api/web/default-background', data)
        .then((response) => {
            const result = response.data.data;
            const backgrounds = result.style.backgrounds;
            const indexRamdomFrame = Math.floor(Math.random() * (backgrounds.length));
            const selectBackground = backgrounds[indexRamdomFrame];
            const folder = result.style;
            return {
                backgroundImage: `url(/backgrounds/${folder.folder}/${selectBackground.frame})`,
                style: id
            };
        })
        .catch((error) => {
            handleError(error);
        });
}

export function getMilestoneByTitle(title) {
    return axios({
        method: 'post',
        url: `/api/get-milestone?lang=${env.GENERAL.lang}`,
        headers: {
            'Content-Type': 'application/json',
            Accept: env.GENERAL.accept
        },
        data: {
            event: title
        }
    }).then((response) => response.data.data).catch((error) => {
        handleError(error);
        return {};
    });
}

export function defaultCover() {
    return axios({
        method: 'get',
        url: `/api/default-cover?lang=${env.GENERAL.lang}`,
        headers: {
            'Content-Type': 'application/json',
            Accept: env.GENERAL.accept
        }
    }).then((response) => {
        const covers = response.data.data;
        const index = Math.floor(Math.random() * (_.size(covers)));
        return covers[index].image;
    }).catch((error) => {
        handleError(error);
    });
}

export function logOut(userId = null) {
    const payloadData = {
        token_device: env.GENERAL.token_device,
        operative_system: 'web',
        lang: env.GENERAL.lang
    };
    if (userId !== null) {
        payloadData.user_id = userId;
    }
    return axios({
        method: 'post',
        url: '/api/logout',
        headers: {
            'Content-Type': 'application/json',
            Accept: env.GENERAL.accept
        },
        data: payloadData
    });
}

export function downloadBabypageImage(babyPageId) {
    return axios({
        method: 'get',
        url: `/api/get-babypage-image/${babyPageId}`,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            Accept: env.GENERAL.accept
        }
    });
}

export function slugify(string) {
    return string
        .toString()
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}

export function getProductDefaultItem() {
    return {
        id: '',
        stripe_id: '',
        title: '',
        subtitle: '',
        type: '',
        item: '',
        total: 0.00,
        qty: 1,
        options: [],
        prices: [],
        babypage_id: null,
        book_id: null,
        event: '',
        thumbnail: '',
        gift: {
            status: false,
            receiver: '',
            email: '',
            message: ''
        }
    };
}

export function getItemTitle(type) {
    let title;
    switch (type) {
        case 'subscription':
            title = 'BabyPage Plus Subscription';
            break;
        case 'print':
            title = 'Single Prints';
            break;
        case 'magnet':
            title = 'Magnet';
            break;
        case 'owelet':
            title = 'Owelet Package';
            break;
        case 'baby-shower':
            title = 'Baby Shower Package';
            break;
        case 'deluxe':
            title = 'Deluxe Package';
            break;
        case 'giftcard':
            title = 'BabyPage Giftcard';
            break;
        default:
            title = `BabyPage ${type.charAt(0).toUpperCase()}${type.slice(1)}`;
            break;
    }
    return title;
}

export function createOptionsArray(stripeObject) {
    let optionsArray = [];
    if (stripeObject.object === 'product') {
        optionsArray = createOptionsForProduct(stripeObject, optionsArray);
    } else if (stripeObject.object === 'plan') {
        optionsArray = createOptionsForPlan(stripeObject, optionsArray);
    }
    return optionsArray;
}

export function createOptionsForProduct(stripeProduct, optionsArray) {
    let optionProductObject = {};
    const skusObject = stripeProduct.skus.data;
    if (skusObject.length > 0) {
        skusObject.forEach((sku) => {
            Object.assign(optionProductObject, {
                id: stripeProduct.id,
                title: stripeProduct.name,
                description: stripeProduct.description,
                shippable: stripeProduct.shippable,
                attributes: stripeProduct.attributes,
                package_dimensions: stripeProduct.package_dimensions,
                active: stripeProduct.active,
                metadata: stripeProduct.metadata,
                images: stripeProduct.images,
                price: parseFloat(sku.price) / 100,
                qty: (sku.inventory.quantity !== null) ? sku.inventory.quantity : 1,
                sku_id: sku.id,
                sku_type: sku.object,
                sku_image: sku.image,
                sku_attributes: sku.attributes,
                sku_package_dimensions: sku.package_dimensions,
                sku_inventory: sku.inventory,
                selected: false
            });
            optionsArray.push(optionProductObject);
        });
    } else {
        optionProductObject = {
            id: stripeProduct.id,
            title: stripeProduct.name,
            description: stripeProduct.description,
            shippable: stripeProduct.shippable,
            attributes: stripeProduct.attributes,
            package_dimensions: stripeProduct.package_dimensions,
            active: stripeProduct.active,
            metadata: stripeProduct.metadata,
            images: stripeProduct.images,
            price: 0.00,
            qty: 1,
            sku_id: null,
            sku_type: null,
            sku_image: null,
            sku_attributes: null,
            sku_package_dimensions: null,
            sku_inventory: null,
            selected: false
        };
        optionsArray.push(optionProductObject);
    }
    return optionsArray;
}

export function createOptionsForPlan(stripeProduct, optionsArray) {
    const optionProductObject = {
        id: stripeProduct.id,
        title: stripeProduct.name,
        description: stripeProduct.statement_descriptor,
        shippable: false,
        attributes: null,
        package_dimensions: null,
        active: null,
        metadata: stripeProduct.metadata,
        images: null,
        price: parseFloat(stripeProduct.amount) / 100,
        qty: 1,
        sku_id: null,
        sku_type: null,
        sku_image: null,
        sku_attributes: null,
        sku_package_dimensions: null,
        sku_inventory: null,
        selected: false
    };

    optionsArray.push(optionProductObject);

    return optionsArray;
}

export function findLocalProducts(localProducts, type, item, itemObject) {
    const pricesObject = {};
    let localProductsArray = localProducts;
    const localProductsLength = (localProductsArray.length === undefined) ?
        Object.keys(localProductsArray).length : localProductsArray.length;
    if (localProductsLength > 0) {
        localProductsArray = localProductsArray.filter((product) => product.type === type);
        if (localProductsArray.length > 0) {
            localProductsArray.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
            localProductsArray.forEach((product) => {
                Object.assign(pricesObject,
                    { [product.description]: parseFloat(product.price) / 100 });
            });
        }
        if (Object.keys(pricesObject).length > 0) {
            itemObject.prices = pricesObject;
        }
        const product = localProductsArray.find((product) => product.description === item);
        if (product !== undefined) {
            itemObject.id = (product.stripe_id !== null) ? product.stripe_id : product.description;
            itemObject.stripe_id = (product.stripe_id !== null) ? product.stripe_id : product.description;
            itemObject.title = getItemTitle(product.type);
            itemObject.subtitle = product.title;
            itemObject.total = parseFloat(product.price) / 100;
        }
    }
    return itemObject;
}

export function restoreMilestone(event, setEvent) {
    if (localStorage.getItem('event') !== null) {
        setEvent(localStorage.getItem('event'));
    } else {
        localStorage.setItem('event', event);
    }
}

export function handleError(error) {
    if (error !== undefined) {
        if (error.response) {
            if (error.response.status < 500) {
                const errorMessage = error.response.data.message;
                console.error(errorMessage);
            }
        } else {
            console.error(error);
        }
    }
}

export function layoutDimensionsUpdate(key, layout, layoutDimensions, setLayoutDimensions) {
    switch (layout) {
        case 'one':
            if (key == 1) { setLayoutDimensions([]); }
            break;
        case 'two':
            if (key == 0) { setLayoutDimensions([]); }
            break;
        case 'three':
            if (key == 2) { setLayoutDimensions([]); }
            break;
        case 'four':
            if (key == 0) { setLayoutDimensions([]); }
            break;
        case 'five':
            if (key == 0) {
                if (layoutDimensions != null && layoutDimensions[1] != undefined) {
                    const position = { 1: { width: layoutDimensions[1].width, height: layoutDimensions[1].height } };
                    setLayoutDimensions(position);
                } else { setLayoutDimensions([]); }
            }
            if (key == 1) {
                if (layoutDimensions != null && layoutDimensions[0] != undefined) {
                    const position = { 0: { width: layoutDimensions[0].width, height: layoutDimensions[0].height } };
                    setLayoutDimensions(position);
                } else { setLayoutDimensions([]); }
            }
            break;
        case 'six':
            if (key == 0) {
                if (layoutDimensions != null && layoutDimensions[1] != undefined) {
                    const position = { 1: { width: layoutDimensions[1].width, height: layoutDimensions[1].height } };
                    setLayoutDimensions(position);
                } else { setLayoutDimensions([]); }
            }
            if (key == 1) {
                if (layoutDimensions != null && layoutDimensions[0] != undefined) {
                    const position = { 0: { width: layoutDimensions[0].width, height: layoutDimensions[0].height } };
                    setLayoutDimensions(position);
                } else { setLayoutDimensions([]); }
            }
            break;
    }
}

export function cleanReduxandLocalStorage(props) {
    props.clearAnswers();
    props.clearQuestions();
    props.setContentPosition([]);
    props.setOverlay([]);
    props.setBabypageFont([]);
    props.setLayoutDimensions([]);
    props.setTextContainer('');
    props.setBabypageTitle([]);
    props.setSelectedStickers([]);
    localStorage.removeItem('contentPosition');
    localStorage.removeItem('overlay');
    localStorage.removeItem('babypageFont');
    localStorage.removeItem('event');
    localStorage.removeItem('answers');
    localStorage.removeItem('questions');
    localStorage.removeItem('overlayAnswers');
    localStorage.removeItem('frame');
}

export function getBeginPurchaseEventTypeText(type) {
    let eventTypeText = 'Begin Purchase';
    switch (type) {
        case 'monthly':
            eventTypeText = `${eventTypeText} - 1 Month`;
            break;
        case 'three-month':
            eventTypeText = `${eventTypeText} - 3 Months`;
            break;
        case 'three month':
            eventTypeText = `${eventTypeText} - 3 Months`;
            break;
        case 'one year':
            eventTypeText = `${eventTypeText} - 12 Months`;
            break;
        case 'yearly':
            eventTypeText = `${eventTypeText} - 12 Months`;
            break;
        default:
            break;
    }
    return eventTypeText;
}

// Add optional afterInitFacebook Function
export function initFB() {
    window.fbAsyncInit = function () {
        window.FB.init({
            appId: env.FACEBOOK_API.id,
            cookie: true,
            xfbml: true,
            version: env.FACEBOOK_API.version
        });
        window.FB.AppEvents.logPageView();
    };
    (function (d, s, id) {
        let js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    if (typeof window.FB !== 'undefined' && window.FB != null) {
        window.FB.XFBML.parse();
    }
}
