// Checked
import {
    SET_LOADING,
    SET_ERROR,
    SET_COVERS_CATEGORY,
} from '../actions/types';

const INITIAL_STATE = {
    coverCategoryList: [],
    error: null,
    loading: false,
};

const coversReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {...state, loading: action.payload, error: null};
        case SET_ERROR:
            return {...state, loading: false, error: action.payload};
        case SET_COVERS_CATEGORY:
            return {
                ...state,
                loading: false,
                coverCategoryList: action.payload,
            };
        default:
            return state;
    }
};

export default coversReducer;
