// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Table, {
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from 'material-ui/Table';
import Download from '@material-ui/icons/Save';
import Button from 'material-ui/Button';
import Paper from 'material-ui/Paper';
import { FormControl } from 'material-ui/Form';
import TextField from 'material-ui/TextField';
import { withStyles } from 'material-ui/styles';
import { InputAdornment } from 'material-ui/Input';
import Search from '@material-ui/icons/Search';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import { LoaderModal } from '../components/common';
import { retrieveAllMetrics, generateHistoricalReport } from '../actions';
import { mapKeysToTitle } from '../containers/Metrics';

class HistoricalMetrics extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleGenerateReport = this.handleGenerateReport.bind(this);
    const from = new moment().subtract(10, 'days').format('YYYY-MM-DD');
    const now = new moment().format('YYYY-MM-DD');

    this.state = {
      searchFrom: from,
      searchTo: now,
    };
  }

  componentWillMount() {
    this.props.retrieveAllMetrics({});
  }

  handleChange(event) {
    const { searchTo } = this.state;
    const { target } = event;
    const { name } = target;
    let { value } = target;
    const now = new moment().format('YYYY-MM-DD');
    if (value !== '') {
      value = moment(value).format('YYYY-MM-DD');
      value = value > now ? now : value;
      const searchToValue = moment(searchTo).format('YYYY-MM-DD');
      value =
        name === 'searchFrom' && searchTo !== '' && value > searchToValue
          ? searchToValue
          : value;
    }
    this.setState({
      [name]: value
    });
  }

  handleSearch() {
    const { searchFrom, searchTo } = this.state;
    if (searchFrom !== '' && searchTo !== '') {
      this.props.retrieveAllMetrics({ searchFrom, searchTo });
    }
  }

  handleGenerateReport() {
    const { searchFrom, searchTo } = this.state;
    if (searchFrom !== '' && searchTo !== '') {
      this.props.generateHistoricalReport({ searchFrom, searchTo });
    }
  }

  renderContent() {
    const { classes, loading, historicalMetrics } = this.props;
    if (historicalMetrics === null || loading) {
      return <LoaderModal loading={loading} />;
    }
    return (
      <AuxHoc>
        <Paper className={classes.root}>
          {historicalMetrics && (
            <div className={classes.tableWrapper}>

              <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                <div style={{ display: 'inline-flex' }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="searchFrom"
                      name="searchFrom"
                      label="From"
                      type="date"
                      className={classes.textField}
                      margin="normal"
                      onChange={this.handleChange}
                      value={this.state.searchFrom}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="searchTo"
                      name="searchTo"
                      label="To"
                      type="date"
                      className={classes.textField}
                      margin="normal"
                      onChange={this.handleChange}
                      value={this.state.searchTo}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Search
                              className={classes.searchButton}
                              onClick={this.handleSearch}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>

                </div>
                <Button variant="contained" color="default" onClick={this.handleGenerateReport}>
                  Download
                  <Download />
                </Button>
              </div>

              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{mapKeysToTitle.date_of_metrics}</TableCell>
                    <TableCell numeric>
                      {mapKeysToTitle.total_users_signed_today.replace(
                        /Today/g,
                        ''
                      )}
                    </TableCell>
                    <TableCell numeric>
                      {mapKeysToTitle.total_plus_signed_today}
                    </TableCell>
                    <TableCell numeric>
                      {mapKeysToTitle.total_plus_signed.replace(/Today/g, '')}
                    </TableCell>
                    <TableCell numeric>
                      {mapKeysToTitle.total_users_upgraded.replace(
                        /Today/g,
                        ''
                      )}
                    </TableCell>
                    <TableCell numeric>
                      {mapKeysToTitle.daily_canceled_subscriptions.replace(
                        /Today/g,
                        ''
                      )}
                    </TableCell>
                    <TableCell numeric>
                      {mapKeysToTitle.daily_in_app_canceled.replace(
                        /Today/g,
                        ''
                      )}
                    </TableCell>
                    <TableCell numeric>
                      {mapKeysToTitle.daily_stripe_revenue.replace(
                        /Today/g,
                        ''
                      )}
                    </TableCell>
                    <TableCell numeric>
                      {mapKeysToTitle.daily_paypal_revenue.replace(
                        /Today/g,
                        ''
                      )}
                    </TableCell>
                    <TableCell numeric>
                      {mapKeysToTitle.daily_in_app_revenue.replace(
                        /Today/g,
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historicalMetrics.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.dateWidth}>
                        {row.date_of_metrics}
                      </TableCell>
                      <TableCell numeric>
                        {row.total_users_signed_today}
                      </TableCell>
                      <TableCell numeric>
                        {row.total_plus_signed_today}
                      </TableCell>
                      <TableCell numeric>{row.total_plus_signed}</TableCell>
                      <TableCell numeric>{row.total_users_upgraded}</TableCell>
                      <TableCell numeric>
                        {row.daily_canceled_subscriptions}
                      </TableCell>
                      <TableCell numeric>{row.daily_in_app_canceled}</TableCell>
                      <TableCell numeric>{row.daily_stripe_revenue}</TableCell>
                      <TableCell numeric>{row.daily_paypal_revenue}</TableCell>
                      <TableCell numeric>{row.daily_in_app_revenue}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </Paper>
      </AuxHoc>
    );
  }

  render() {
    return <AuxHoc>{this.renderContent()}</AuxHoc>;
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'auto'
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  icon: {
    margin: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  formControl: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  dateWidth: {
    minWidth: 70
  },
  searchButton: {
    cursor: 'pointer'
  }
});

const mapStateToProps = ({ metrics }) => {
  const { error, loading, historicalMetrics } = metrics;

  return { error, loading, historicalMetrics };
};

const mapDispatchToProps = {
  retrieveAllMetrics,
  generateHistoricalReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HistoricalMetrics));
