// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table, {
    TableBody,
    TableCell,
    TableFooter,
    TablePagination,
    TableRow,
    TableHead
} from 'material-ui/Table';
import Paper from 'material-ui/Paper';
import { FormControl } from 'material-ui/Form';
import TextField from 'material-ui/TextField';
import { withStyles } from 'material-ui/styles';
import { InputAdornment } from 'material-ui/Input';
import Search from '@material-ui/icons/Search';
import Button from 'material-ui/Button';
import Tooltip from 'material-ui/Tooltip';
import AddIcon from 'material-ui-icons/Add';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import TablePaginationActions from '../components/TablePaginationActions';
import { LoaderModal } from '../components/common';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import { retrieveStickerCategories, retrieveStickerCategoryById } from '../actions';

class StickerCategories extends Component {

    constructor(props, context) {
        super(props, context);

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleLabelDisplayedRows = this.handleLabelDisplayedRows.bind(this);
        this.handleReadStickerCategory = this.handleReadStickerCategory.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.state = {
            page: 1,
            search: '',
        };
    }

    componentWillMount() {
        this.props.retrieveStickerCategories();
    }

    handleChangePage(event, page) {
        const { search } = this.state;
        if (event !== null) {
            this.setState({ page });
            this.props.retrieveStickerCategories(page, search);
        }
    }

    handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: event.target.value });
    }

    handleLabelDisplayedRows(from, to, count) {
        return `${from} - ${to} of ${count}`;
    }

    handleReadStickerCategory(id) {
        this.props.retrieveStickerCategoryById(id);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleSearch() {
        const { search } = this.state;
        this.props.retrieveStickerCategories(1, search);
    }

    renderContent() {
        const { classes, loading, stickerCategoriesList, paginator } = this.props;
        let rowsPerPage = 10,
            currentPage = 0,
            total = 0,
            firstItem = 0,
            lastItem = 0;
        if (loading) {
            return <LoaderModal loading={loading} />;
        }
        if (paginator) {
            rowsPerPage = parseInt(paginator.per_page, 10);
            currentPage = parseInt(paginator.current_page, 10);
            total = parseInt(paginator.total, 10);
            firstItem = parseInt(paginator.first_item, 10);
            lastItem = parseInt(paginator.last_item, 10);
        }
        return (
            <AuxHoc>
                <Paper className={classes.root}>
                    {stickerCategoriesList &&
                        <div className={classes.tableWrapper}>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    id="search"
                                    name="search"
                                    label="Search"
                                    type="search"
                                    className={classes.textField}
                                    margin="normal"
                                    onChange={this.handleChange}
                                    onBlur={this.handleSearch}
                                    value={this.state.search}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search
                                                    className={classes.searchButton}
                                                    onClick={this.handleSearch}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Category Name</TableCell>
                                        <TableCell>Category Folder</TableCell>
                                        <TableCell>Category Type</TableCell>
                                        <TableCell>Created</TableCell>
                                        <TableCell>Plus</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        stickerCategoriesList.map(row => (
                                            <TableRow
                                                key={row.id}
                                                onClick={() => this.handleReadStickerCategory(row.id)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.folder}</TableCell>
                                                <TableCell>{row.type}</TableCell>
                                                <TableCell>{row.created_at}</TableCell>
                                                <TableCell>
                                                    {(row.plus == 1) ?
                                                        <Check color="primary" />
                                                        :
                                                        <Close color="error" />
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={8}
                                            count={total}
                                            rowsPerPage={rowsPerPage}
                                            page={currentPage}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            rowsPerPageOptions={[10]}
                                            labelDisplayedRows={
                                                () =>
                                                    this.handleLabelDisplayedRows(firstItem, lastItem, total)
                                            }
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </div>
                    }
                </Paper>
                <Tooltip title="Create a new Sticker Category">
                    <Button
                        variant="fab"
                        color="secondary"
                        className={this.props.classes.absolute}
                        onClick={() => this.props.history.push('/app/sticker-categories/new')}
                    >
                        <AddIcon />
                    </Button>
                </Tooltip>
            </AuxHoc>
        );
    }

    render() {
        return (
            <AuxHoc>
                {this.renderContent()}
            </AuxHoc>
        );
    }
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    icon: {
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    formControl: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    searchButton: {
        cursor: 'pointer',
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3,
    }
});

const mapStateToProps = ({ stickerCategories }) => {
    const { error, loading, paginator, stickerCategoriesList } = stickerCategories;

    return { error, loading, paginator, stickerCategoriesList };
};

export default connect(mapStateToProps,
    {
        retrieveStickerCategories,
        retrieveStickerCategoryById
    }
)(withStyles(styles)(StickerCategories));
