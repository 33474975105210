// Checked
import {
    SET_LOADING,
    SET_ERROR,
    RETRIEVE_USERS_SUCCESS,
    RETRIEVE_USER_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    user: null,
    usersList: null,
    paginator: null,
    error: null,
    loading: false,
};

const usersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: true, error: null };
        case SET_ERROR:
            return { ...state, loading: false, error: action.payload };
        case RETRIEVE_USERS_SUCCESS:
            return { 
                ...state, 
                ...INITIAL_STATE, 
                paginator: action.payload, 
                usersList: action.payload.data 
            };
        case RETRIEVE_USER_SUCCESS:
            return { 
                ...state, 
                ...INITIAL_STATE,
                user: action.payload 
            };
        default:
            return state;
    }
};

export default usersReducer;
