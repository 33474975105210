// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import Typography from 'material-ui/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from 'material-ui/IconButton';

import { withStyles } from 'material-ui/styles';
import Card, { CardActions, CardContent } from 'material-ui/Card';
import Button from 'material-ui/Button';
import {
    retrieveQuestions,
    updateQuestions,
    retrieveQuestion,
    createQuestion,
    updateQuestion,
    deleteQuestion,
} from '../actions';

import Question from './Question';

import { HeaderCard, LoaderModal } from './common';

const styles = {
  card: {
    maxWidth: '100%',
  },
};

const SortableItem = SortableElement((props) => {
    const { question } = props;
    return (
        <div
            key={question.id}
            className="col-12 milestone-card-header"
        >
            <div className="box">
                <HeaderCard 
                    id={question.id}
                    title={question.title}
                    subtitle={question.question}
                    Action={
                        <IconButton>
                            <MoreVertIcon
                                onClick={
                                    () => {
                                        props.retrieveQuestion(question);
                                    }
                                }
                            />
                        </IconButton>
                    }
                />
            </div>
        </div>
    );
});

const SortableList = SortableContainer((props) => {
    const { items } = props;
    return (
        <div className="row milestone-cards-container">        {
            items.map((question, index) => (
                    <SortableItem
                        key={question.id}
                        index={index}
                        question={question}
                        retrieveQuestion={props.retrieveQuestion}
                    />
                ))
            }
        </div>
    );
});

class Questions extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.onSortStart = this.onSortStart.bind(this);
        this.state = { };
    }

    componentDidMount() {
        const { id } = this.props;
        if (id !== 'new') {
            this.props.retrieveQuestions(id);
        }
    }

    componentDidUpdate(prevProps) {
        const { questionsList } = this.props;
        const { id } = this.props;
        if (questionsList.length === 0 && (prevProps.questionsList.length != questionsList.length)) {
            this.props.retrieveQuestions(id);
        }
    }

    onSortEnd({ oldIndex, newIndex }) {
        let { questionsList } = this.props;
        questionsList = arrayMove(questionsList, oldIndex, newIndex);
        questionsList.forEach((question, index) => { question.order = index });
        this.props.updateQuestions(questionsList);
    }

    onSortStart() {
    }

    handleChange(event) {
        
    }
    

    render() {
        const { classes, questionsList, loading, currentQuestion, id } = this.props;
        return (
            <div>
                {
                    loading ?
                    <LoaderModal loading={loading} /> :
                    <Card className={classes.card}>
                        {
                            Object.keys(currentQuestion).length > 0 ?
                            <Question id={id} questionsList={questionsList} />
                            :
                            <div>
                                <CardContent>
                                    <Typography gutterBottom variant="headline" component="h2">
                                        Questions
                                    </Typography>
                                    <SortableList
                                        items={questionsList}
                                        pressDelay={200}
                                        lockAxis={'y'}
                                        lockToContainerEdges
                                        onSortEnd={this.onSortEnd}
                                        helperClass="SortableHelper"
                                        onSortStart={this.onSortStart}
                                        isCollage={false}
                                        retrieveQuestion={this.props.retrieveQuestion}
                                    />
                                </CardContent>
                                <CardActions>
                                    <div className="row around-xs" style={{ width: '100%' }}>
                                        <Button
                                            size="medium"
                                            color="primary"
                                            onClick={
                                                () => {
                                                    this.props.retrieveQuestion({
                                                        title: '',
                                                        question: '',
                                                        export: '',
                                                        answers: '',
                                                        multiple_choice: 0,
                                                    });
                                                }
                                            }
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </CardActions>
                            </div>
                        }
                    </Card>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ questions }) => {
    const { error, loading, currentQuestion, questionsList } = questions;

    return { error, loading, currentQuestion, questionsList };
};

export default connect(mapStateToProps, 
    {
        retrieveQuestions,
        retrieveQuestion,
        updateQuestions,
        createQuestion,
        updateQuestion,
        deleteQuestion,
    }
)(withStyles(styles)(Questions));
