// Checked
import axios from '../axios-admin';
import env from '../js/env';
import { handleError } from '../js/helpers';
import {
    RETRIEVE_PURCHASE,
    RETRIEVE_PURCHASE_ERROR,
    RETRIEVE_PURCHASE_SUCCESS
} from './types';

const ROOT_URL = '/api';

export function retrievePurchaseHistory(url) {
    const purchaseUrl = url || `${ROOT_URL}/purchase-history/${env.GENERAL.purchase_paginator_limit}?lang=${env.GENERAL.lang}`;
    return function (dispatch) {
        dispatch({ type: RETRIEVE_PURCHASE });
        axios({
            method: 'get',
            url: purchaseUrl,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const purchase = response.data.data;
            dispatch(retrieveSuccess(purchase, RETRIEVE_PURCHASE_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

function retrieveSuccess(object, action) {
    return {
        type: action,
        payload: object
    };
}

function retrieveFail(error) {
    return {
        type: RETRIEVE_PURCHASE_ERROR,
        payload: error
    };
}

