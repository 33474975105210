// Checked
import moment from 'moment';
import axios from '../axios-admin';
import env from '../js/env';
import { handleError } from '../js/helpers';
import history from './../history';
import {
    AUTH_USER,
    AUTH_USER_ERROR,
    AUTH_USER_SUCCESS,
    UNAUTH_USER,
} from './types';

const ROOT_URL = '/api';

export function signInUser({ email, password }) {
    return function (dispatch) {
        // Submit email/password to the server
        dispatch({ type: AUTH_USER });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/login`,
            data: {
                grant_type: 'password',
                client_id: env.CLIENT_CREDENTIALS.key,
                client_secret: env.CLIENT_CREDENTIALS.secret,
                token_device: env.GENERAL.token_device,
                lang: env.GENERAL.lang,
                operative_system: env.GENERAL.operative_system,
                username: email,
                password
            },
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json'
            }
        }).then((response) => {
            const loginResponse = response.data.data;
            // If request is good...
            // - Update state to indicate user is authenticated
            dispatch(loginUserSuccess(loginResponse));
            // - Save the oauth token
            localStorage.setItem('token', loginResponse.access_token);
            localStorage.setItem('last_date_hit', moment());
            // - Redirect to the route '/admin/app/dashboard'
            history.push('/app/books');
        }).catch((error) => {
            handleError(error);
            dispatch(loginUserFail(error.response.data.message));
        });
    };
}

export function signOutUser() {
    return function (dispatch) {
        dispatch({ type: UNAUTH_USER });
        localStorage.removeItem('token');
        localStorage.removeItem('last_date_hit');
        history.push('/login');
    };    
}

export function retrieveAuthUser() {
    return function (dispatch) {
        dispatch({ type: AUTH_USER });
        axios({
            method: 'get',
            url: `${ROOT_URL}/get-user?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const userResponse = response.data.data;
            // If request is good...
            // - Update state to indicate the auth user
            dispatch(loginUserSuccess(userResponse));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(loginUserFail(error.response.data.message));
                }
            }
        });
    };
}

function loginUserSuccess(user) {
    return {
        type: AUTH_USER_SUCCESS,
        payload: user
    };
}

function loginUserFail(error) {
    return {
        type: AUTH_USER_ERROR,
        payload: error
    };
}
