import env from '../js/env';
import { handleError } from '../js/helpers';
import axios from '../axios-admin';
import {
    SET_ERROR,
    SET_LOADING,
    GET_MAINTENANCE,
    TOGGLE_MAINTENANCE,
    SET_MAINTENANCE_MESSAGE
} from './types';

const ROOT_URL = '/api';


export function getMaintenance() {
    let url = `${ROOT_URL}/system-status`;
    return function (dispatch) {
        dispatch({type: SET_LOADING});
        return axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const maintenanceResponse = response.data.data;
            dispatch(retrieveSuccess(maintenanceResponse, GET_MAINTENANCE));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function toggleMaintenance(in_maintenance) {
    let url = `${ROOT_URL}/admin/system-status`
    return function (dispatch) {
        return axios({
            method: 'post',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            dispatch(retrieveSuccess(!!in_maintenance, TOGGLE_MAINTENANCE));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    }
}

export function changeMaintenanceMessage(body_text) {
    let url = `${ROOT_URL}/admin/system-message`

    return function (dispatch) {
        return axios({
            method: 'put',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            },
            data: {
                body_message: body_text

            }
        }).then((response) => {
            const messageResponse = response.data.data;
            dispatch(retrieveSuccess(messageResponse, SET_MAINTENANCE_MESSAGE));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    }
}


function retrieveSuccess(object, action) {
    return {
        type: action,
        payload: object
    };
}

function retrieveFail(error) {
    return {
        type: SET_ERROR,
        payload: error
    };
}
