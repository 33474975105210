// Checked
import axios from '../axios-admin';
import {handleError} from '../js/helpers';
import env from '../js/env';
import {SET_COVERS_CATEGORY, SET_ERROR, SET_LOADING} from './types';

const ROOT_URL = '/api';

export function getCoverCategories() {
    return function (dispatch) {
        dispatch({type: SET_LOADING, payload: true});
        const url = `${ROOT_URL}/admin/covers/categories?lang=${env.GENERAL.lang}`;
        return axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const data = response.data.data;
            dispatch({
                type: SET_COVERS_CATEGORY,
                payload: data
            });

            return response;
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch({type: SET_ERROR, payload: error.response.data.message});
                }
            }

            throw error;
        });
    };
}

export function changeCoverCategory(data) {
    return function (dispatch) {
        dispatch({type: SET_LOADING, payload: true});
        return axios({
            method: 'post',
            url: `${ROOT_URL}/admin/covers/change-category?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            dispatch({type: SET_LOADING, payload: false});

            return response;
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch({type: SET_ERROR, payload: error.response.data.message});
                }
            }

            throw error;
        });
    };
}