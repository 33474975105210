// Checked
import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames';
import Drawer from 'material-ui/Drawer';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import List from 'material-ui/List';
import Typography from 'material-ui/Typography';
import Divider from 'material-ui/Divider';
import IconButton from 'material-ui/IconButton';
import MenuIcon from 'material-ui-icons/Menu';
import ChevronLeftIcon from 'material-ui-icons/ChevronLeft';
import { connect } from 'react-redux';
import { menuListItems } from '../hoc/Layout/tileData';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import { retrieveUsers, signOutUser } from '../actions';
import moment from 'moment';
import Table, {
    TableBody,
    TableCell,
    TableRow,
    TableHead
} from 'material-ui/Table';
import Paper from 'material-ui/Paper';
import { FormControl } from 'material-ui/Form';
import TextField from 'material-ui/TextField';
import { InputAdornment } from 'material-ui/Input';
import Search from '@material-ui/icons/Search';
import { LoaderModal } from '../components/common';
import { retrieveAllMetrics } from '../actions';
import { mapKeysToTitle } from '../containers/Metrics';

const mapStateToPropsLayout = ({ auth }) => {
    const { user } = auth;

    return { user };
};

const drawerWidth = 240;

const stylesLayout = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        height: '100vh',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        position: 'absolute',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    'appBarShift-left': {
        marginLeft: drawerWidth,
    },
    'appBarShift-right': {
        marginRight: drawerWidth,
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        overflow: 'auto',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    'content-left': {
        marginLeft: -drawerWidth,
    },
    'content-right': {
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    'contentShift-left': {
        marginLeft: 0,
    },
    'contentShift-right': {
        marginRight: 0,
    },
});

class Layout extends Component {
    constructor(props) {
        super(props);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.handleChangeAnchor = this.handleChangeAnchor.bind(this);

        this.state = {
            open: false,
            anchor: 'left'
        };
    }

    handleDrawerOpen() {
        this.setState({ open: true });
    }

    handleDrawerClose() {
        this.setState({ open: false });
    }

    handleChangeAnchor(event) {
        this.setState({
            anchor: event.target.value,
        });
    }

    render() {
        const { classes } = this.props;
        const { anchor, open } = this.state;
        const drawer = (
            <Drawer
                variant="persistent"
                anchor={anchor}
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={this.handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{menuListItems(this.props)}</List>
            </Drawer>
        );

        let before = null;
        let after = null;

        if (anchor === 'left') {
            before = drawer;
        } else {
            after = drawer;
        }

        return (
            <AuxHoc>
                <div className={classes.appFrame}>
                    <AppBar
                        className={classNames(classes.appBar, {
                            [classes.appBarShift]: open,
                            [classes[`appBarShift-${anchor}`]]: open,
                        })}
                    >
                        <Toolbar disableGutters={!open}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(classes.menuButton, open && classes.hide)}
                                style={{ visibility: 'hidden' }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="title" color="inherit" noWrap>
                                BabyPage - Admin Panel
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {before}
                    <main
                        className={classNames(classes.content, classes[`content-${anchor}`], {
                            [classes.contentShift]: open,
                            [classes[`contentShift-${anchor}`]]: open,
                        })}
                    >
                        <div className={classes.drawerHeader} />
                        {this.props.children ? this.props.children : null}
                    </main>
                    {after}
                </div>
            </AuxHoc>
        );
    }
}

export const InternalLayout = connect(mapStateToPropsLayout, { retrieveUsers, signOutUser })(withStyles(stylesLayout)(Layout));



class HistoricalMetricsDashboard extends Component {

    constructor(props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        const now = new moment().format('YYYY-MM-DD');

        this.state = {
            searchFrom: now,
            searchTo: now,
        };
    }

    componentWillMount() {
        this.props.retrieveAllMetrics({});
    }

    handleChange(event) {
        const { searchTo } = this.state;
        const { target } = event;
        const { name } = target;
        let { value } = target;
        const now = new moment().format('YYYY-MM-DD');
        if (value !== '') {
            value = moment(value).format('YYYY-MM-DD');
            value = (value > now) ? now : value;
            const searchToValue = moment(searchTo).format('YYYY-MM-DD');
            value = (name === 'searchFrom' && searchTo !== '' && value > searchToValue) ? searchToValue : value;
        }
        this.setState({
            [name]: value,
        });
    }

    handleSearch() {
        const { searchFrom, searchTo } = this.state;
        if (searchFrom !== '' && searchTo !== '') {
            this.props.retrieveAllMetrics({ searchFrom, searchTo });
        }
    }

    renderContent() {
        const { classes, loading, historicalMetrics } = this.props;
        if (historicalMetrics === null || loading) {
            return <LoaderModal loading={loading} />;
        }
        return (
            <InternalLayout>
                <AuxHoc>
                    <Paper className={classes.root}>
                        {historicalMetrics &&
                            <div className={classes.tableWrapper}>
                                <div style={{ display: 'inline-flex' }}>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            id="searchFrom"
                                            name="searchFrom"
                                            label="From"
                                            type="date"
                                            className={classes.textField}
                                            margin="normal"
                                            onChange={this.handleChange}
                                            value={this.state.searchFrom}
                                            inputProps={{
                                                min: '2019-01-01'
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            id="searchTo"
                                            name="searchTo"
                                            label="To"
                                            type="date"
                                            className={classes.textField}
                                            margin="normal"
                                            onChange={this.handleChange}
                                            value={this.state.searchTo}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Search
                                                            className={classes.searchButton}
                                                            onClick={this.handleSearch}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            inputProps={{
                                                min: '2019-01-01'
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{mapKeysToTitle.date_of_metrics}</TableCell>
                                            <TableCell numeric>
                                                {mapKeysToTitle.total_users_signed_today.replace(/Today/g, '')}
                                            </TableCell>
                                            <TableCell numeric>
                                                {mapKeysToTitle.total_plus_signed_today.replace(/Today/g, '')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            historicalMetrics.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                >
                                                    <TableCell>{row.date_of_metrics}</TableCell>
                                                    <TableCell numeric>{row.total_users_signed_today}</TableCell>
                                                    <TableCell numeric>{row.total_plus_signed_today}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        }
                    </Paper>
                </AuxHoc>
            </InternalLayout>
        );
    }

    render() {
        return (
            <AuxHoc>
                {this.renderContent()}
            </AuxHoc>
        );
    }
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    icon: {
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    formControl: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    searchButton: {
        cursor: 'pointer',
    },
});

const mapStateToProps = ({ metrics }) => {
    const { error, loading, historicalMetrics } = metrics;

    return { error, loading, historicalMetrics };
};

const mapDispatchToProps = {
    retrieveAllMetrics
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HistoricalMetricsDashboard));

