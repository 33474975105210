// Checked
import { combineReducers } from 'redux';
import authReducer from './auth_reducer';
import usersReducer from './users_reducer';
import questionsReducer from './questions_reducer';
import milestonesReducer from './milestones_reducer';
import userPurchasesReducer from './user_purchases_reducer';
import messagesNotificationsReducer from './messages_notifications_reducer';
import stickerCategoriesReducer from './sticker_categories_reducer';
import stickersReducer from './stickers_reducer';
import booksReducer from './books_reducer';
import fontsReducer from './fonts_reducer';
import metricsReducer from './metrics_reducer';
import customNotificationsReducer from "./custom_notifications_reducer";
import alexandersOrdersReducer from "./alexanders_orders_reducer";
import coversReducer from "./covers_reducer";
import maintenanceReducer from "./maintenance_reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    milestones: milestonesReducer,
    questions: questionsReducer,
    userPurchases: userPurchasesReducer,
    messagesNotifications: messagesNotificationsReducer,
    stickerCategories: stickerCategoriesReducer,
    stickers: stickersReducer,
    books: booksReducer,
    fonts: fontsReducer,
    metrics: metricsReducer,
    customNotifications: customNotificationsReducer,
    alexandersOrders: alexandersOrdersReducer,
    covers: coversReducer,
    maintenance:maintenanceReducer,
});

export default rootReducer;
