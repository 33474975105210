// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'material-ui';
import { withStyles } from 'material-ui/styles';
import AddIcon from 'material-ui-icons/Add';
import Button from 'material-ui/Button';
import Tooltip from 'material-ui/Tooltip';
import Card, { CardHeader, CardActions } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import IconButton from 'material-ui/IconButton';
import ContentCopy from 'material-ui-icons/ContentCopy';

import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import {
    retrieveMilestones,
    retrieveMilestone,
    filterMilestones,
    createMilestone,
    updateMilestone,
    deleteMilestone,
    retrieveQuestions,
    retrieveQuestion,
    duplicateMilestone
} from '../actions';


const styles = theme => ({
    fab: {
        margin: theme.spacing.unit * 2,
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3,
    },
});

class Milestones extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleDuplicateMilestone = this.handleDuplicateMilestone.bind(this);

        this.state = {
            searchWords: '',
            wait: null
        };
    }

    componentDidMount() {
        this.props.retrieveMilestones();
        this.props.retrieveMilestone(undefined, {});
        this.props.retrieveQuestions();
        this.props.retrieveQuestion();
        this.props.filterMilestones([]);
    }

    handleChange(event) {
        const self = this;
        const { milestonesList } = this.props;
        const searchWords = event.target.value;
        let { wait } = this.state;

        if (wait !== null) {
            clearTimeout(wait);
        }

        wait = setTimeout(() => {
            const searchWordsArray = searchWords.trim()
                .split(' ')
                .filter(word => word.length > 0)
                .map(word => word.toLowerCase());
            const filteredMilestones = milestonesList.filter(
                milestone => {
                    const matchesArray = searchWordsArray.filter(
                        word => milestone.title_name.toLowerCase().indexOf(word) >= 0
                    );
                    return matchesArray.length > 0;
                }
            );
            self.props.filterMilestones(filteredMilestones);
        }, 300);
        this.setState({ searchWords, wait });
    }

    handleDuplicateMilestone(id) {
        this.props.duplicateMilestone(id);
    }

    renderMilestones(milestonesList) {
        const self = this;
        return (
            milestonesList.length > 0 &&
            milestonesList.map(milestone => {
                const imgUrl = milestone.url_image == null ?
                    milestone.image_url
                    : milestone.url_image;
                const milestoneId = milestone.id;
                return (
                    <div
                        key={milestoneId}
                        className="col-12 col-md-6 col-lg-4 milestone-card-header"
                    >
                        <div className="box">
                            <Card key={milestoneId}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            alt={'Milestone Image'}
                                            src={imgUrl}
                                        />
                                    }
                                    title={milestone.title_name}
                                    subheader={milestone.description}
                                    onClick={() =>
                                        self.props.retrieveMilestone(milestoneId, milestone)
                                    }
                                />
                                <CardActions style={{ display: 'flex' }} disableActionSpacing >
                                    <IconButton
                                        aria-label="Duplicate milestone"
                                        onClick={() => self.handleDuplicateMilestone(milestoneId)}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        <ContentCopy />
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </div>
                    </div>
                );
            })
        );
    }

    render() {
        const { milestonesList, milestonesFilteredList } = this.props;
        return (
            <AuxHoc>
                <div className="row justify-content-center align-items-center search-bar">
                    <div className="col-6">
                        <div className="box">
                            <TextField
                                id="search"
                                name="search"
                                placeholder="Search"
                                value={this.state.searchWords}
                                onChange={this.handleChange}
                                margin="normal"
                                inputProps={{ required: false }}
                                autoFocus
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
                <div className="row milestone-cards-container">
                    {
                        milestonesFilteredList.length > 0 ?
                            this.renderMilestones(milestonesFilteredList)
                            :
                            this.renderMilestones(milestonesList)
                    }

                </div>
                <Tooltip title="Create a new Milestone">
                    <Button
                        variant="fab"
                        color="secondary"
                        className={this.props.classes.absolute}
                        onClick={() => this.props.history.push('/app/milestones/new')}
                    >
                        <AddIcon />
                    </Button>
                </Tooltip>
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ milestones }) => {
    const { error, loading, milestonesList, milestonesFilteredList } = milestones;

    return { error, loading, milestonesList, milestonesFilteredList };
};

export default connect(mapStateToProps,
    {
        retrieveMilestones,
        retrieveMilestone,
        filterMilestones,
        createMilestone,
        updateMilestone,
        deleteMilestone,
        retrieveQuestions,
        retrieveQuestion,
        duplicateMilestone
    }
)(withStyles(styles)(Milestones));
