// Checked
import {
    SET_LOADING,
    UNSET_LOADING,
    SET_ERROR,
    RETRIEVE_STICKERS_SUCCESS,
    RETRIEVE_STICKER_SUCCESS,
    CREATE_STICKER_SUCCESS,
    UPDATE_STICKER_SUCCESS,
    DELETE_STICKER_SUCCESS,
    RETRIEVE_FILTERED_STICKERS_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    sticker: null,
    stickersList: null,
    filteredStickersList: null,
    error: null,
    loading: false,
};

const stickersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: true, error: null };
        case UNSET_LOADING:
            return { ...state, loading: false };
        case SET_ERROR:
            return { ...state, loading: false, error: action.payload };
        case RETRIEVE_STICKERS_SUCCESS:
            return { 
                ...state, 
                ...INITIAL_STATE,
                stickersList: action.payload 
            };
        case RETRIEVE_FILTERED_STICKERS_SUCCESS:
            return { 
                ...state,
                loading: false,
                filteredStickersList: action.payload
            };
        case RETRIEVE_STICKER_SUCCESS:
            return { 
                ...state, 
                ...INITIAL_STATE,
                sticker: action.payload 
            };
        case CREATE_STICKER_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                sticker: action.payload
            };
        case UPDATE_STICKER_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                sticker: action.payload
            };
        case DELETE_STICKER_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};

export default stickersReducer;
