// Checked
import {
    SET_LOADING,
    SET_ERROR,
    RETRIEVE_BOOKS_SUCCESS,
    RETRIEVE_BOOK_SUCCESS,
    RETRIEVE_FILTERED_BOOKS_SUCCESS,
    GENERATE_BOOK_SUCCESS,
    SET_LOADING_AND_ERROR_FALSE
} from '../actions/types';

const INITIAL_STATE = {
    booksList: [],
    booksFilteredList: [],
    paginator: null,
    book: {},
    error: null,
    loading: false,
};

const booksReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: true, error: null };
        case SET_ERROR:
            return { ...state, loading: false, error: action.payload };
        case RETRIEVE_BOOKS_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE,
                paginator: action.payload,
                booksList: action.payload.data
            };
        case RETRIEVE_BOOK_SUCCESS:
            return { 
                ...state,
                error: null,
                loading: false,
                book: action.payload.data
            };
        case RETRIEVE_FILTERED_BOOKS_SUCCESS:
            return { 
                ...state,
                booksFilteredList: action.payload
            };
        case GENERATE_BOOK_SUCCESS:
            return { 
                ...state,
                error: null,
                loading: false
            };
        case SET_LOADING_AND_ERROR_FALSE:
            return {
                ...state,
                error: null,
                loading: false
            };
        default:
            return state;
    }
};

export default booksReducer;
