// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table, {
    TableBody,
    TableCell,
    TableFooter,
    TablePagination,
    TableRow,
    TableHead
} from 'material-ui/Table';
import Paper from 'material-ui/Paper';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import { FormControl, FormControlLabel } from 'material-ui/Form';
import Switch from 'material-ui/Switch';
import TextField from 'material-ui/TextField';
import { withStyles } from 'material-ui/styles';
import { InputAdornment } from 'material-ui/Input';
import Search from '@material-ui/icons/Search';
import TablePaginationActions from '../components/TablePaginationActions';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import { LoaderModal } from '../components/common';
import { retrieveUsers, retrieveUserById } from '../actions';
import { InternalLayout } from "./HistoricalMetricsDashboard";

class UsersEngage extends Component {

    constructor(props, context) {
        super(props, context);

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleLabelDisplayedRows = this.handleLabelDisplayedRows.bind(this);
        this.handleReadUser = this.handleReadUser.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChangeSwitch = this.handleChangeSwitch.bind(this);

        this.state = {
            page: 1,
            search: '',
            switchPlus: false,
        };
    }

    componentWillMount() {
        this.props.retrieveUsers();
    }

    handleChangePage(event, page) {
        const { search, switchPlus } = this.state;
        if (event !== null) {
            this.setState({ page });
            this.props.retrieveUsers(page, search, switchPlus);
        }
    }

    handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: event.target.value });
    }

    handleLabelDisplayedRows(from, to, count) {
        return `${from} - ${to} of ${count}`;
    }

    handleReadUser(id) {
        this.props.retrieveUserById(id);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleChangeSwitch(event) {
        const { search } = this.state;
        this.setState({ [event.target.name]: event.target.checked });
        this.props.retrieveUsers(1, search, event.target.checked);
    }

    handleSearch() {
        const { search, switchPlus } = this.state;
        this.props.retrieveUsers(1, search, switchPlus);
    }

    renderContent() {
        const { classes, loading, usersList, paginator } = this.props;
        if (usersList === null || loading) {
            return <LoaderModal loading={loading} />;
        }
        const rowsPerPage = parseInt(paginator.per_page, 10);
        const currentPage = parseInt(paginator.current_page, 10);
        const total = parseInt(paginator.total, 10);
        const firstItem = parseInt(paginator.first_item, 10);
        const lastItem = parseInt(paginator.last_item, 10);
        return (
            <InternalLayout>
                <AuxHoc>
                    <Paper className={classes.root}>
                        {usersList &&
                            <div className={classes.tableWrapper}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        id="search"
                                        name="search"
                                        label="Search"
                                        type="search"
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={this.handleChange}
                                        onBlur={this.handleSearch}
                                        value={this.state.search}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Search
                                                        className={classes.searchButton}
                                                        onClick={this.handleSearch}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.switchPlus}
                                                onChange={this.handleChangeSwitch}
                                                value='switchPlus'
                                                name='switchPlus'
                                            />
                                        }
                                        label="Plus Users"
                                    />
                                </FormControl>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Created</TableCell>
                                            <TableCell>Deleted</TableCell>
                                            <TableCell>Last Login</TableCell>
                                            <TableCell numeric>Nº Babies</TableCell>
                                            <TableCell numeric>Nº Babypages</TableCell>
                                            <TableCell numeric>Nº Books</TableCell>
                                            <TableCell>Plus</TableCell>
                                            {/* <TableCell>Actions</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            usersList.map(row => {
                                                return (
                                                    <TableRow
                                                        key={row.id}
                                                        onClick={() => this.handleReadUser(row.id)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell>
                                                            {`${row.first_name} ${row.last_name}`}
                                                        </TableCell>
                                                        <TableCell>{row.email}</TableCell>
                                                        <TableCell>{row.created_at}</TableCell>
                                                        <TableCell>{row.deleted_at}</TableCell>
                                                        <TableCell>{row.last_login_at}</TableCell>
                                                        <TableCell numeric>{row.total_babies}</TableCell>
                                                        <TableCell numeric>{row.total_babypages}</TableCell>
                                                        <TableCell numeric>{row.total_books}</TableCell>
                                                        <TableCell>
                                                            {(row.user_level === 1) ?
                                                                <Check color="primary" />
                                                                :
                                                                <Close color="error" />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                colSpan={8}
                                                count={total}
                                                rowsPerPage={rowsPerPage}
                                                page={currentPage}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                                rowsPerPageOptions={[10]}
                                                labelDisplayedRows={
                                                    () =>
                                                        this.handleLabelDisplayedRows(firstItem, lastItem, total)
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </div>
                        }
                    </Paper>
                </AuxHoc>
            </InternalLayout>
        );
    }

    render() {
        return (
            <AuxHoc>
                {this.renderContent()}
            </AuxHoc>
        );
    }
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    icon: {
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    formControl: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    searchButton: {
        cursor: 'pointer',
    },
});

const mapStateToProps = ({ users }) => {
    const { error, loading, paginator, usersList } = users;

    return { error, loading, paginator, usersList };
};

export default connect(mapStateToProps,
    {
        retrieveUsers,
        retrieveUserById
    }
)(withStyles(styles)(UsersEngage));

