// Checked
import {
    SET_LOADING,
    SET_ERROR,
    RETRIEVE_STICKER_CATEGORIES_SUCCESS,
    RETRIEVE_ALL_STICKER_CATEGORIES_SUCCESS,
    RETRIEVE_STICKER_CATEGORY_SUCCESS,
    CREATE_STICKER_CATEGORY_SUCCESS,
    UPDATE_STICKER_CATEGORY_SUCCESS,
    DELETE_STICKER_CATEGORY_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    stickerCategory: null,
    stickerCategoriesList: null,
    allStickerCategories: null,
    paginator: null,
    error: null,
    loading: false,
};

const stickerCategoriesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: true, error: null };
        case SET_ERROR:
            return { ...state, loading: false, error: action.payload };
        case RETRIEVE_STICKER_CATEGORIES_SUCCESS:
            return { 
                ...state, 
                ...INITIAL_STATE, 
                paginator: action.payload, 
                stickerCategoriesList: action.payload.data 
            };
        case RETRIEVE_ALL_STICKER_CATEGORIES_SUCCESS:
            return { 
                ...state, 
                ...INITIAL_STATE,
                allStickerCategories: action.payload 
            };
        case RETRIEVE_STICKER_CATEGORY_SUCCESS:
            return { 
                ...state, 
                ...INITIAL_STATE,
                stickerCategory: action.payload 
            };
        case CREATE_STICKER_CATEGORY_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                stickerCategory: action.payload
            };
        case UPDATE_STICKER_CATEGORY_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                stickerCategory: action.payload
            };
        case DELETE_STICKER_CATEGORY_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};

export default stickerCategoriesReducer;
