// Checked
import axios from '../axios-admin';
import { handleError } from '../js/helpers';
import {
    SET_LOADING_QUESTION,
    SET_LOADING_QUESTION_FALSE,
    SET_ERROR,
    RETRIEVE_QUESTIONS_SUCCESS,
    UPDATE_QUESTIONS_SUCCESS,
    RETRIEVE_QUESTION_SUCCESS,
    CREATE_QUESTION_SUCCESS,
    UPDATE_QUESTION_SUCCESS,
    DELETE_QUESTION_SUCCESS,
} from './types';

const ROOT_URL = '/api';

export function retrieveQuestions(id = null) {
    const url = `${ROOT_URL}/admin/questions`;
    return function (dispatch) {
        dispatch({ type: SET_LOADING_QUESTION });
        if (id) {
            axios({
                method: 'get',
                url,
                params: {
                    milestone: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/x.babypage.v1.0.0+json',
                    Authorization: localStorage.getItem('token')
                }
            }).then((response) => {
                const questionsResponse = response.data;
                dispatch(retrieveSuccess(questionsResponse, RETRIEVE_QUESTIONS_SUCCESS));
            }).catch((error) => {
                handleError(error);
                // dispatch({ type: SET_LOADING_QUESTION_FALSE });
                /* if (error !== undefined) {
                    if (error.response !== undefined) {
                        dispatch(retrieveFail(error.response.data.message));
                    }
                } */
            });
        } else {
            dispatch(retrieveSuccess({ data: [] }, RETRIEVE_QUESTIONS_SUCCESS));            
        }
    };
}

export function retrieveQuestion(question = null) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING_QUESTION });
        if (question !== null) {
            dispatch(retrieveSuccess({ data: { ...question } }, RETRIEVE_QUESTION_SUCCESS));
        } else {
            dispatch(retrieveSuccess({ data: { } }, RETRIEVE_QUESTION_SUCCESS));
        }
    };
}

export function createQuestion(data) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING_QUESTION });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/question`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            const questionResponse = response.data;
            dispatch(retrieveSuccess(questionResponse, CREATE_QUESTION_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function updateQuestions(data) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING_QUESTION });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/questions`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data: {
                questions: data
            }
        }).then((response) => {
            const questionsResponse = response.data;
            dispatch(retrieveSuccess(questionsResponse, UPDATE_QUESTIONS_SUCCESS));
        }).catch((error) => {
            handleError(error);
            /* if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            } */
        });
    };
}

export function updateQuestion(id, data) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING_QUESTION });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/question/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            const questionResponse = response.data;
            dispatch(retrieveSuccess(questionResponse, UPDATE_QUESTION_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function deleteQuestion(id) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING_QUESTION });
        axios({
            method: 'delete',
            url: `${ROOT_URL}/admin/question/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const questionResponse = response.data;
            dispatch(retrieveSuccess(questionResponse, DELETE_QUESTION_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

function retrieveSuccess(object, action) {
    return {
        type: action,
        payload: object
    };
}

function retrieveFail(error) {
    return {
        type: SET_ERROR,
        payload: error
    };
}

