// Checked
import axios from '../axios-admin';
import env from '../js/env';
import { saveAs } from 'file-saver';
import { handleError } from '../js/helpers';
import {
    SET_LOADING,
    SET_ERROR,
    RETRIEVE_METRICS_SUCCESS,
    RETRIEVE_METRIC_SUCCESS,
    STORE_METRIC_SUCCESS,
    RETRIEVE_METRIC_REPORT_SUCCESS,
    RETRIEVE_ALL_METRICS_SUCCESS,
    SET_LOADING_OFF
} from './types';


const ROOT_URL = '/api';

export function retrieveMetrics() {
    const url = `${ROOT_URL}/admin/metrics?lang=${env.GENERAL.lang}`;
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const metricsResponse = response.data.data;
            dispatch(retrieveSuccess(metricsResponse, RETRIEVE_METRICS_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function retrieveMetric(dateOfMetrics) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url: `${ROOT_URL}/admin/metrics/${dateOfMetrics}?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const metricResponse = response.data.data;
            dispatch(retrieveSuccess(metricResponse, RETRIEVE_METRIC_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function storeMetric() {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/metrics`,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            },
            data: { lang: env.GENERAL.lang }
        }).then((response) => {
            const metricResponse = response.data.data;
            dispatch(retrieveSuccess(metricResponse, STORE_METRIC_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function retrieveMetricReport(dateOfMetrics) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        const newWindow = (isMobileDevice()) ? window.open('', '_blank') : null;
        axios({
            method: 'get',
            url: `${ROOT_URL}/admin/metrics/generate-report/${dateOfMetrics}?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            },
            responseType: 'blob'
        }).then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            if (!isMobileDevice()) {
                const urlLink = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = urlLink;
                link.setAttribute('download', `metrics_report.pdf`);
                document.body.appendChild(link);
                link.click();
            } else {
                const reader = new FileReader();
                const urlLink = window.URL.createObjectURL(blob);
                reader.onloadend = () => {
                    newWindow.location = urlLink;
                };
                reader.readAsDataURL(blob);
            }
            dispatch(retrieveSuccess({}, RETRIEVE_METRIC_REPORT_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function retrieveAllMetrics(params) {
    const searchWord = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    const url = `${ROOT_URL}/admin/metrics-all?lang=${env.GENERAL.lang}${searchWord ? `&${searchWord}` : ''}`;

    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const alMetricsResponse = response.data.data;
            dispatch(retrieveSuccess(alMetricsResponse, RETRIEVE_ALL_METRICS_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function generateHistoricalReport(params) {
    const searchWord = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    const url = `${ROOT_URL}/admin/metrics-all?lang=${env.GENERAL.lang}&xls=1${searchWord ? `&${searchWord}` : ''}`;
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token'),
                responseType: 'arraybuffer',
            }
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, 'historical_metrics_report.csv');
                dispatch({ type: SET_LOADING_OFF });
            })
            .catch(error => {
                handleError(error);
                if (error !== undefined) {
                    if (error.response !== undefined) {
                        dispatch(retrieveFail(error.response.data.message));
                    }
                }
            });
    };
}

function isMobileDevice() {
    return (typeof window.orientation !== 'undefined')
        || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

function retrieveSuccess(object, action) {
    return {
        type: action,
        payload: object
    };
}

function retrieveFail(error) {
    return {
        type: SET_ERROR,
        payload: error
    };
}
