// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'material-ui/styles';
import Card, { CardActions, CardContent } from 'material-ui/Card';
import Button from 'material-ui/Button';
import TextField from 'material-ui/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from 'material-ui/Switch';
import { LoaderModal } from './common';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import {
    retrieveStickerCategoryById,
    createStickerCategory,
    updateStickerCategory,
    deleteStickerCategory
} from '../actions';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let container;

class StickerCategory extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderStickerCategoryForm = this.renderStickerCategoryForm.bind(this);
        this.handleChangeSwitch = this.handleChangeSwitch.bind(this);

        this.state = {
            name: {
                value: '',
                required: true,
                error: false,
            },
            type: {
                value: '',
                required: false,
                error: false,
            },
            plus: {
                value: false,
                required: false,
                error: false,
            }
        };
    }

    componentDidMount() {
        const { stickerCategory } = this.props;
        const { id } = this.props.match.params;
        if (stickerCategory && Object.keys(stickerCategory).length > 0) {
            const stickerCategoryName = stickerCategory.name;
            const stickerCategoryType = stickerCategory.type;
            const stickerCategoryPlus = stickerCategory.plus;
            this.setState({
                name: { ...this.state.name, value: stickerCategoryName },
                type: { ...this.state.type, value: stickerCategoryType },
                plus: { ...this.state.plus, value: stickerCategoryPlus }
            });
        }
        if (id !== 'new') {
            this.props.retrieveStickerCategoryById(id);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { error, stickerCategory } = nextProps;
        if (error !== null) {
            if (container) {
                toast.error (error);
            }
        }
        if (this.props.stickerCategory !== stickerCategory) {
            this.setState({
                name: { ...this.state.name, value: stickerCategory.name },
                type: { ...this.state.type, value: stickerCategory.type },
                plus: { ...this.state.plus, value: stickerCategory.plus }
            });
        }
    }

    handleChange(event) {
        const property = event.target.name;
        const value = event.target.value;
        this.setState({
            [property]: { ...this.state[property], value }
        });
    }

    handleChangeSwitch(event) {
        const property = event.target.name;
        const checked = event.target.checked;
        this.setState({
            [property]: { ...this.state[property], value: checked }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const { name, type, plus } = this.state;
        const { id } = this.props.match.params;
        if (name.value === '') {
            this.setState({ name: { ...name, error: true } });
        } else {
            const data = {
                name: name.value,
                type: type.value,
                plus: plus.value
            };
            if (id === 'new') {
                this.props.createStickerCategory(data);
            } else {
                this.props.updateStickerCategory(id, data);
            }
        }
    }

    renderStickerCategoryForm() {
        const { classes } = this.props;
        const { name, type, plus } = this.state;
        const { id } = this.props.match.params;

        return (
            <Card className={classes.card}>
                <form onSubmit={this.handleSubmit}>
                    <CardContent>
                        <TextField
                            id="name"
                            name="name"
                            label="Name"
                            placeholder="Sticker Category Name"
                            value={name.value}
                            error={name.error}
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: true }}
                            fullWidth
                        />
                        <TextField
                            id="type"
                            name="type"
                            label="Type"
                            placeholder="Sticker Category Type"
                            value={type.value}
                            error={type.error}
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: false }}
                            fullWidth
                        />
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={plus.value}
                                        onChange={this.handleChangeSwitch}
                                        value="plus"
                                        name='plus'
                                    />
                                }
                                label="Plus"
                            />
                        </FormGroup>
                    </CardContent>
                    <CardActions>
                        <div className="row around-xs" style={{ width: '100%' }}>
                            <Button
                                size="medium"
                                color="primary"
                                type="submit"
                            >
                                {id !== 'new' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="medium"
                                onClick={() => this.props.history.push('/app/sticker-categories')}
                            >
                                Cancel
                            </Button>
                            {
                                id !== 'new' &&
                                <Button
                                    size="medium"
                                    color="secondary"
                                    onClick={() => {
                                        if (window.confirm('Confirm required to delete this sticker category')) {
                                            this.props.deleteStickerCategory(id);
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            }
                        </div>
                    </CardActions>
                </form>
            </Card>
        );
    }

    render() {
        const { loading } = this.props;
        if (loading) {
            return <LoaderModal loading={loading} />;
        }
        return (
            <AuxHoc>
                 <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                <div className="row around-xs">
                    <div className="col-12 col-md-6">
                        <div className="box">
                            {
                                this.renderStickerCategoryForm()
                            }
                        </div>
                    </div>
                </div>
            </AuxHoc>
        );
    }
}

const styles = {
    card: {
        maxWidth: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
};

const mapStateToProps = ({ stickerCategories }) => {
    const { error, loading, paginator, stickerCategory, stickerCategoriesList } = stickerCategories;

    return { error, loading, paginator, stickerCategory, stickerCategoriesList };
};

export default connect(mapStateToProps,
    {
        retrieveStickerCategoryById,
        createStickerCategory,
        updateStickerCategory,
        deleteStickerCategory,
    }
)(withStyles(styles)(StickerCategory));
