// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'material-ui/styles';
import Card, { CardActions, CardContent } from 'material-ui/Card';
import Button from 'material-ui/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import Switch from 'material-ui/Switch';
import TextField from 'material-ui/TextField';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import {
    retrieveFont,
    createFont,
    updateFont,
    deleteFont
} from '../actions';
import { LoaderModal } from './common';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let container;

const styles = theme => ({
    card: {
        maxWidth: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    formControl: {
        //margin: theme.spacing.unit,
        minWidth: 120,
    },
});

class Font extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderFontCard = this.renderFontCard.bind(this);
        this.handleFontChange = this.handleFontChange.bind(this);
        this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
        this.setFontPropertiesState = this.setFontPropertiesState.bind(this);

        this.state = {
            family: {
                value: '',
                required: true,
                error: false,
            },
            plus: {
                value: false,
                required: false,
                error: false,
            },
            isActive: {
                value: false,
                required: false,
                error: false,
            },
            source: {
                value: '',
                file: null,
                required: true,
                error: false,
            },
        };
    }

    componentDidMount() {
        const { font } = this.props;
        const { id } = this.props.match.params;
        if (font && Object.keys(font).length > 0) {
            this.setFontPropertiesState(font);
        }
        if (id !== 'new') {
            this.props.retrieveFont(id);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { error, font } = nextProps;
        if (error != null) {
            toast.error (error);
        }
        if (this.props.font !== font) {
            this.setFontPropertiesState(font);
        }
    }

    setFontPropertiesState(font) {
        const fontFamily = font.family;
        const fontSource = font.source;
        const fontPlus = font.plus;
        const fontIsActive = font.is_active;
        this.setState({
            family: { ...this.state.family, value: fontFamily },
            source: { ...this.state.source, value: fontSource },
            plus: { ...this.state.plus, value: fontPlus },
            isActive: { ...this.state.isActive, value: fontIsActive }
        });
    }

    handleChange(event) {
        const property = event.target.name;
        const value = event.target.value;
        this.setState({
            [property]: { ...this.state[property], value }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const { family, plus, isActive, source } = this.state;
        const { id } = this.props.match.params;
        if (family.value === '') {
            this.setState({ family: { ...family, error: true } });
        } else {
            const payload = {
                family: family.value,
                plus: plus.value,
                is_active: isActive.value,
                source: (source.file !== null) ? source.file : undefined,
            };
            const formData = new FormData();
            for (const index in payload) {
                if (payload[index] !== undefined) {
                    formData.append(index, payload[index]);
                }
            }
            if (id === 'new') {
                if (source.file === null) {
                    this.setState({ image: { ...source, error: true } });
                } else {
                    this.props.createFont(formData);
                }
            } else {
                this.props.updateFont(id, formData);
            }
        }
    }

    handleFontChange(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.fileName = file.name;
        reader.readAsDataURL(file);
        reader.onloadend = function (readerEvent) {
            this.setState({
                source: {
                    ...this.state.source,
                    file,
                    error: false,
                    value: readerEvent.target.fileName
                }
            });
        }.bind(this);
    }

    handleChangeSwitch(event) {
        const property = event.target.name;
        const checked = event.target.checked;
        this.setState({
            [property]: { ...this.state[property], value: checked }
        });
    }

    renderFontCard() {
        const { classes } = this.props;
        const { id } = this.props.match.params;
        const { source, family, plus, isActive } = this.state;
        return (
            <Card className={classes.card}>
                <form onSubmit={this.handleSubmit}>
                    <CardContent>
                        <TextField
                            className={classes.margin}
                            id="input-with-icon-textfield"
                            label="Font"
                            value={source.value}
                            fullWidth
                            readOnly
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FontDownloadIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div className="row justify-content-center align-items-center" style={{ width: '100%' }}>
                            <input
                                id="raised-button-file"
                                type="file"
                                accept="application/vnd.ms-fontobject, font/woff, font/woff2, font/ttf, font/svg, font/opentype"
                                style={{ display: 'none' }}
                                onChange={this.handleFontChange}
                            />
                            <label htmlFor="raised-button-file">
                                {
                                    source.error &&
                                    <FormHelperText
                                        id="name-error-text"
                                        style={{
                                            textAlign: 'center',
                                            padding: 5,
                                            color: 'red',
                                        }}
                                    >
                                        Font required
                                    </FormHelperText>
                                }
                                <Button
                                    color="primary"
                                    variant="raised"
                                    component="span"
                                    style={{
                                        marginTop: 10,
                                    }}
                                >
                                    Upload font
                                </Button>
                            </label>
                        </div>
                        <TextField
                            id="family"
                            name="family"
                            label="Family"
                            value={family.value}
                            onChange={this.handleChange}
                            placeholder="Family"
                            margin="normal"
                            inputProps={{ required: true }}
                            fullWidth
                        />
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={plus.value}
                                        onChange={this.handleChangeSwitch}
                                        value="plus"
                                        name='plus'
                                    />
                                }
                                label="Plus"
                            />
                        </FormGroup>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isActive.value}
                                        onChange={this.handleChangeSwitch}
                                        value="isActive"
                                        name='isActive'
                                    />
                                }
                                label="Is Active"
                            />
                        </FormGroup>
                    </CardContent>
                    <CardActions>
                        <div className="row around-xs" style={{ width: '100%' }}>
                            <Button
                                size="medium"
                                color="primary"
                                type="submit"
                            >
                                {id !== 'new' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="medium"
                                onClick={() => this.props.history.push('/app/fonts')}
                            >
                                Cancel
                            </Button>
                            {
                                id !== 'new' &&
                                <Button
                                    size="medium"
                                    color="secondary"
                                    onClick={() => {
                                        if (window.confirm('Confirm required to delete this font')) {
                                            this.props.deleteFont(id);
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            }
                        </div>
                    </CardActions>
                </form>
            </Card>
        );
    }

    render() {
        const { loading } = this.props;
        return (
            <AuxHoc>
                <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                {
                    loading ?
                        <LoaderModal loading={loading} /> :
                        <div className="row around-xs">
                            <div className="col-12 col-md-6">
                                <div className="box">
                                    {
                                        this.renderFontCard()
                                    }
                                </div>
                            </div>
                        </div>
                }
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ fonts }) => {
    const { error, loading, font } = fonts;

    return { error, loading, font };
};

export default connect(mapStateToProps,
    {
        retrieveFont,
        createFont,
        updateFont,
        deleteFont
    }
)(withStyles(styles)(Font));
