import React, { Component } from 'react';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import { LoaderModal } from './common';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from 'material-ui/Switch';
import TextField from '@material-ui/core/TextField';
import Button from 'material-ui/Button';
import { connect } from 'react-redux';
import { getMaintenance, toggleMaintenance, changeMaintenanceMessage } from '../actions';



class ChangeMaintenance extends Component {

    constructor(props) {
        super(props);
        this.toggleMaintenance = this.toggleMaintenance.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.changeMessage = this.changeMessage.bind(this);

        this.state = {
            body_message: this.props.body_message.length > 0 ? this.props.body_message : "",
        }
    }


    componentWillMount() {
        this.props.getMaintenance().then(() => {
        this.setState((state) => ({
            ...state,
            body_message: this.props.body_message 
        }))
        }).catch((error) => {
            toast.error (error.response.data.message);
        });
    }

    toggleMaintenance() {
        this.props.toggleMaintenance(this.props.in_maintenance).then(() => {
            toast.success(`Maintenance Mode ${!!this.props.in_maintenance ? "Activated" : "Deactivated"} Succesfully`); 
        }).catch((error) => {
            toast.error (error.response.data.message);
        })
    }

    onTextChange(event) {
        const property = event.target.name;
        let value = event.target.value;

        this.setState((state) => ({
            ...state,
            body_message: value
        }))
    }

    changeMessage() {
        if(this.state.body_message.length <= 0) {
            toast.error ("Maintenance Message is Required");
        } else {
            this.props.changeMaintenanceMessage(this.state.body_message).then(() => {
                toast.success ('Maintenance message updated  succesfully'); 
            }).catch((error) => {
                toast.error (error.response.data.message);
            })     
        }
    }

    render() {
        return  (
            <AuxHoc>
                <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                    <div className="row around-xs justify-content-center align-items-center"> 
                        <h2 className='text-center'>Maintenance Section</h2>
                        <div className="col-6">
                            <div className="box">
                                <TextField
                                    id="maintenanceMessage"
                                    name="maintenanceMessage"
                                    label="Maintenance Message"
                                    value={this.state.body_message}
                                    onChange={this.onTextChange}
                                    margin="normal"
                                    
                                    inputProps={{
                                        required: true,
                                        maxLength: 255
                                    }}
                                    disabled={false}
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                                <span>{this.state.body_message.length}/255</span>
                            </div>
                        </div>
                        <div className="col-3">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={!!this.props.in_maintenance}
                                        onChange={this.toggleMaintenance}
                                        value='usePush'
                                        name='Toggle Maintenance'
                                    />
                                }
                                label="Toggle Maintenance"
                            />
                        </div>   
                       
                    </div>
                    <div className='row justify-content-center align-items-center'>
                    <div className="col-6">
                        <Button  
                        color="primary"
                        variant="raised"
                        component="span"
                        onClick={this.changeMessage}
                        >
                            Update Maintenance Message
                        </Button>
                        </div>
                    </div>
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ maintenance }) => {
    const {  loading, body_message, in_maintenance } = maintenance;
    return { loading, body_message, in_maintenance };
};

export default connect(mapStateToProps,
    {
        getMaintenance,
        toggleMaintenance,
        changeMaintenanceMessage
    }
)(ChangeMaintenance);

