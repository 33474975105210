// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Table, {
    TableBody,
    TableCell,
    TableFooter,
    TablePagination,
    TableRow
} from 'material-ui/Table';
import Paper from 'material-ui/Paper';
import { withStyles } from 'material-ui/styles';
import { CircularProgress } from 'material-ui/Progress';
import ExpansionPanel, {
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from 'material-ui/ExpansionPanel';
import Typography from 'material-ui/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from 'material-ui/Avatar';
import List, { ListItem, ListItemText } from 'material-ui/List';
import _ from 'lodash';
import TablePaginationActions from './TablePaginationActions';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import { retrievePurchaseHistory } from '../actions';

class PurchaseHistory extends Component {

    constructor(props, context) {
        super(props, context);

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleLabelDisplayedRows = this.handleLabelDisplayedRows.bind(this);

        this.state = {
            purchases: this.props.purchases,
            rowsPerPage: 5,
            currentPage: 1,
            total: 5,
        };
    }

    componentWillReceiveProps(nextProps) {
        const { purchases } = nextProps;
        if (this.props.purchases !== purchases && purchases !== null) {
            this.setState({ purchases });
        }
    }

    handleChangePage(event, page) {
        this.props.retrieveUsers(page);
    }

    handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: event.target.value });
    }

    handleLabelDisplayedRows(from, to, count) {
        return `${from} - ${to} of ${count}`;
    }

    renderData(title, subtitle) {
        return (
            <ListItem>
                <ListItemText inset primary={title} />
                <ListItemText inset primary={subtitle} />
            </ListItem>
        );
    }

    renderProducts(products) {
        const numProducts = products.length - 1;
        const listProducts = products.map((product, index) => {
            let title = '';
            let subtitle = '';
            switch (product.type) {
                case 'print':
                    title = 'Single Print';
                    subtitle = `$ ${product.amount / 100} - ${product.description} - ${product.quantity}`;
                    break;
                case 'magnet':
                    title = 'Magnet';
                    subtitle = `$ ${product.amount / 100} - ${product.description} - ${product.quantity}`;
                    break;
                case 'book':
                    title = 'Book';
                    subtitle = `$ ${product.amount / 100} - ${product.description} - ${product.quantity}`;
                    break;
                default:
                    break;

            }
            const element = [
                <ListItem key={`${index} li`} dense button>
                    <Avatar alt="product" src={product.thumbnail} />
                    <ListItemText primary={`${title} ${subtitle}`} />
                </ListItem>
            ];
            if (index < numProducts) {
                element.push(<hr key={`${index} hr`} />);
            }
            return element;
        });
        return listProducts;
    }

    renderContent() {
        const { classes, loading } = this.props;
        const { purchases } = this.state;
        if (purchases == null || loading) {
            return <CircularProgress size={24} className={classes.buttonProgress} />;
        }
        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        <TableBody>
                            {
                                (_.size(purchases) > 0 && !purchases.hasOwnProperty('error') && purchases.order.length > 0) &&
                                purchases.order.map((order, index) => (
                                    <div className={classes.rootDivPanel}>
                                        <ExpansionPanel key={order.id}>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography className={classes.heading}>
                                                    {
                                                        [
                                                            `Order Date: ${moment(order.created).format('YYYY-MM-DD')}`,
                                                            `Order Total: $ ${order.amount / 100}`,
                                                            `Payment Method: **** ${order.source.last4}`,
                                                            `Order #: ${order.id}`,
                                                        ].join(' | ')
                                                    }
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <List component="nav" key={`${order.id} ul0`}>
                                                    {this.renderData('Order Total:', `$ ${(order.amount / 100)}`)}
                                                    {this.renderData('Order Date:', moment(order.created).format('YYYY-MM-DD'))}
                                                    {this.renderData('Payment Method:', `**** ${order.source.last4}`)}
                                                </List>
                                                <List component="nav" key={`${order.id} ul1`}>
                                                    {this.renderProducts(order.products)}
                                                </List>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </div>
                                ))
                            }
                            {
                                (_.size(purchases) > 0 && !purchases.hasOwnProperty('error') && purchases.invoices.length > 0) &&
                                purchases.invoices.map((invoice, index) => (
                                    <ExpansionPanel key={invoice.id}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography className={classes.heading}>
                                                {
                                                    [
                                                        `Invoice Date: ${moment(invoice.created).format('YYYY-MM-DD')}`,
                                                        `Invoice Total: $ ${invoice.amount / 100}`,
                                                        `Payment Method: **** ${invoice.source.last4}`,
                                                        `Invoice #: ${invoice.id}`,
                                                    ].join(' | ')
                                                }
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List component="nav" key={`${invoice.id} ul0`}>
                                                {this.renderData('Invoice Date', moment(invoice.created).format('YYYY-MM-DD'))}
                                                {this.renderData('Invoice Total', `$ ${invoice.amount / 100}`)}
                                                {this.renderData('Payment Method', `**** ${invoice.source.last4}`)}
                                            </List>
                                            <List component="nav" key={`${invoice.id} ul1`}>
                                                <ListItem key={`${index} li`} dense button>
                                                    <Avatar alt="subscription" src={'/images/plus-thumbnail.png'} />
                                                    <ListItemText primary={`${invoice.lines.data[0].plan.name} ${`$ ${invoice.amount / 100} - ${invoice.lines.data[0].plan.statement_descriptor} - 1`}`} />
                                                </ListItem>
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                ))
                            }
                            {
                                (_.size(purchases) > 0 && !purchases.hasOwnProperty('error') && purchases.charges.length > 0) &&
                                purchases.charges.map((charge, index) => (
                                    <ExpansionPanel key={charge.id}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography className={classes.heading}>
                                                {
                                                    [
                                                        `Charge Date: ${moment(charge.created).format('YYYY-MM-DD')}`,
                                                        `Charge Total: $ ${charge.amount / 100}`,
                                                        `Payment Method: **** ${charge.source.last4}`,
                                                        `Charge #: ${charge.id}`,
                                                    ].join(' | ')
                                                }
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List component="nav" key={`${charge.id} ul0`}>
                                                {this.renderData('Charge Date:', moment(charge.created).format('YYYY-MM-DD'))}
                                                {this.renderData('Charge Total:', `$ ${charge.amount / 100}`)}
                                                {this.renderData('Payment Method:', `**** ${charge.source.last4}`)}
                                            </List>
                                            <List component="nav" key={`${charge.id} ul1`}>
                                                <ListItem key={`${index} li`} dense button>
                                                    <Avatar alt="giftCard" src={'/images/giftcard_new_version.png'} />
                                                    <ListItemText primary={`Gift Card $ ${(charge.amount / 100)} - ${(charge.description)} - 1`} />
                                                </ListItem>
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                ))
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                {/* <TablePagination
                                    colSpan={8}
                                    count={total}
                                    rowsPerPage={rowsPerPage}
                                    page={currentPage}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    Actions={TablePaginationActions}
                                    rowsPerPageOptions={false}
                                    labelDisplayedRows={false}
                                /> */}
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </Paper>
        );
    }

    render() {
        return (
            <AuxHoc>
                {this.renderContent()}
            </AuxHoc>
        );
    }
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    rootDivPanel: {
        width: '100%',
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    icon: {
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
    },
});

const mapStateToProps = ({ userPurchases }) => {
    const { error, loading, purchases } = userPurchases;

    return { error, loading, purchases };
};

export default connect(mapStateToProps,
    { retrievePurchaseHistory }
)(withStyles(styles)(PurchaseHistory));

