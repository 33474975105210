// Checked
import {
    SET_LOADING,
    SET_ERROR,
    RETRIEVE_FONTS_SUCCESS,
    RETRIEVE_FONT_SUCCESS,
    CREATE_FONT_SUCCESS,
    UPDATE_FONT_SUCCESS,
    DELETE_FONT_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    fontsList: [],
    paginator: null,
    font: {},
    error: null,
    loading: false,
};

const fontsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: true, error: null };
        case SET_ERROR:
            return { ...state, loading: false, error: action.payload };
        case RETRIEVE_FONTS_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE,
                paginator: action.payload,
                fontsList: action.payload.data
            };
        case RETRIEVE_FONT_SUCCESS:
            return { 
                ...state,
                error: null,
                loading: false,
                font: action.payload
            };
        case CREATE_FONT_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                font: action.payload
            };
        case UPDATE_FONT_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                font: action.payload
            };
        case DELETE_FONT_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};

export default fontsReducer;
