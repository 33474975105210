// Checked
import axios from '../axios-admin';
import env from '../js/env';
import { handleError } from '../js/helpers';
import history from './../history';
import {
    SET_LOADING,
    SET_ERROR,
    RETRIEVE_STICKERS_SUCCESS,
    RETRIEVE_STICKER_SUCCESS,
    CREATE_STICKER_SUCCESS,
    UPDATE_STICKER_SUCCESS,
    DELETE_STICKER_SUCCESS,
    RETRIEVE_FILTERED_STICKERS_SUCCESS
} from './types';

const ROOT_URL = '/api';

export function retrieveStickers() {
    const url = `${ROOT_URL}/admin/stickers?lang=${env.GENERAL.lang}`;
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const stickersResponse = response.data.data;
            dispatch(retrieveSuccess(stickersResponse, RETRIEVE_STICKERS_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function filterStickers(filteredStickers) {
    return function (dispatch) {
        dispatch(retrieveSuccess(filteredStickers, RETRIEVE_FILTERED_STICKERS_SUCCESS));
    };
}

export function retrieveStickerById(id) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url: `${ROOT_URL}/admin/stickers/${id}?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const stickerResponse = response.data.data;
            dispatch(retrieveSuccess(stickerResponse, RETRIEVE_STICKER_SUCCESS));
            history.push(`/app/stickers/${id}`);
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function createSticker(data) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/stickers?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            const stickerResponse = response.data.data;
            dispatch(retrieveSuccess(stickerResponse, CREATE_STICKER_SUCCESS));
            history.push(`/app/stickers/${stickerResponse.id}`);
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function updateSticker(id, data) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/stickers/${id}?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            const stickerResponse = response.data.data;
            dispatch(retrieveSuccess(stickerResponse, UPDATE_STICKER_SUCCESS));
            history.push('/app/stickers');
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function deleteSticker(id) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'delete',
            url: `${ROOT_URL}/admin/stickers/${id}?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const stickerCategoryResponse = response.data.data;
            dispatch(retrieveSuccess(stickerCategoryResponse, DELETE_STICKER_SUCCESS));
            history.push('/app/stickers');
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

function retrieveSuccess(object, action) {
    return {
        type: action,
        payload: object
    };
}

function retrieveFail(error) {
    return {
        type: SET_ERROR,
        payload: error
    };
}

