export const env = {
    BASE_URL: 'https://api.babypage.com/',
    DEBUG: false,
    FACEBOOK_API: {
        id: '132994857336675',
        version: 'v2.10'
    },
    SESSION_TIMEOUT: {
        value: 2,
        unit: 'hours'
    },
    FACEBOOK_PIXEL: {
        name: 'Babypage',
        id: '1930345967189621'
    },
    GOOGLE_ANALYTICS: {
        name: 'Babypage',
        id: 'UA-67390821-1'
    },
    GOOGLE_PHOTOS: {
        name: 'Babypage Prueba', 
        id: '636675358823-jbnh9fasfhr6rlf8v56aphu7bndnip6q.apps.googleusercontent.com',
        apiKey: 'AIzaSyDvwfTmK42rTREKgdMu0X7maAkyiC3OM1U'
    },
    CLIENT_CREDENTIALS: {
        key: '78ece834-1e30-3c68-a5cc-2f4cdfa2bd0c',
    },
    GENERAL: {
        token_device: '',
        lang: 'en',
        operative_system: 'web',
        paginator_limit: 10,
        purchase_paginator_limit: 5,
        default_discount: 'one_year_subscription_discount',
        accept: 'application/x.babypage.v1.0.0+json',
        app_env: 'production'
    },
    STICKERS: {
        max_stickers_per_bp: 10 
    },
    BABYPAGES: {
        max_babypages_per_free_user: 10 
    },
    AMPLITUDE: {
        apiKey: '32cb1860fd904a71902dc3d1157fa6a1' 
    },
    PAYPAL: {
        client: {
            sandbox: 'AVMxMLV_0Kng1fc-WECeQHWkUF-mhcZJlkk7OvaZ7-hii6GFhv6BA52mMCFPo1TbbT7YX4FNS8h3DMAu',
            production: 'AcKhkyg_cEypwkcArJS2I-u1PMJ6K31T3TqCgflExNxsUPXOb0LjJVndfQBTOHUZaXPWX6VxhT00Udw5'
        },
        merchantId: 'B3AQ9R3QGLSW2',
        mode: 'production',
        commit: true,
        locale: 'en_US',
        style: {
            label: 'checkout', // checkout | credit | pay | buynow | generic
            size: 'responsive',    // small | medium | large | responsive
            shape: 'rect',     // pill | rect
            color: 'blue',      // gold | blue | silver | black
            tagline: false    
        },
        buttons: {
            monthly: 'N9A29G9RF552A',
            three_month: 'A2UE2VT95KKW2',
            one_year: 'N5P29HNJPZXUQ'
        }
    }
};

export default env;
