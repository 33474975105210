// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table, {
    TableBody,
    TableCell,
    TableFooter,
    TablePagination,
    TableRow,
    TableHead
} from 'material-ui/Table';
import Paper from 'material-ui/Paper';
import { FormControl } from 'material-ui/Form';
import { InputAdornment } from 'material-ui/Input';
import Search from '@material-ui/icons/Search';
import { TextField } from 'material-ui';
import { withStyles } from 'material-ui/styles';
import _ from 'lodash';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import TablePaginationActions from '../components/TablePaginationActions';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import {
    retrieveBooks,
    retrieveBook,
    filterBooks,
} from '../actions';
import { HeaderCard, LoaderModal } from '../components/common';
import env from '../js/env';

const styles = theme => ({
    fab: {
        margin: theme.spacing.unit * 2,
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    icon: {
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    formControl: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    searchButton: {
        cursor: 'pointer',
    },
});

class Books extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.renderBooks = this.renderBooks.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleLabelDisplayedRows = this.handleLabelDisplayedRows.bind(this);

        this.state = {
            page: 1,
            search: '',
            searchWords: '',
            wait: null,
            rowsPerPage: 10
        };
    }

    componentWillMount() {
        this.props.retrieveBooks();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleChangePage(event, page) {
        const { search } = this.state;
        if (event !== null) {
            this.setState({ page });
            this.props.retrieveBooks(page, search);
        }
    }

    handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: event.target.value });
    }

    handleLabelDisplayedRows(from, to, count) {
        return `${from} - ${to} of ${count}`;
    }

    handleSearch() {
        const { search } = this.state;
        this.props.retrieveBooks(1, search);
    }

    renderBooks(booksList) {
        const self = this;
        return (
            booksList.length > 0 &&
            booksList.map(book => {
                let imgUrl = '/owls/owl.png';
                if (['qa', 'staging', 'production'].indexOf(env.GENERAL.app_env) >= 0) {
                    imgUrl = book.thumbnail_with_url !== null ? book.thumbnail_with_url : imgUrl;
                }
                return (
                    <div
                        key={book.id}
                        className="col-12 col-md-6 col-lg-4 milestone-card-header"
                        onClick={() => self.props.retrieveBook(book.id, book)}
                    >
                        <div className="box">
                            <HeaderCard
                                id={book.id}
                                image={imgUrl}
                                alt={'Book Image'}
                                title={`${book.id} - ${book.parent_email}`}
                                subtitle={book.description}
                            />
                        </div>
                    </div>
                );
            })
        );
    }

    renderContent() {
        const { classes, loading, booksList, paginator } = this.props;
        if (_.size(booksList) <= 0 || loading) {
            return <LoaderModal loading={loading} />;
        }
        const rowsPerPage = parseInt(paginator.per_page, 10);
        const currentPage = parseInt(paginator.current_page, 10);
        const total = parseInt(paginator.total, 10);
        const firstItem = parseInt(paginator.first_item, 10);
        const lastItem = parseInt(paginator.last_item, 10);
        return (
            <AuxHoc>
                <Paper className={classes.root}>
                    {booksList &&
                        <div className={classes.tableWrapper}>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    id="search"
                                    name="search"
                                    label="Search"
                                    type="search"
                                    className={classes.textField}
                                    margin="normal"
                                    onChange={this.handleChange}
                                    onBlur={this.handleSearch}
                                    value={this.state.search}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search
                                                    className={classes.searchButton}
                                                    onClick={this.handleSearch}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell numeric>Id</TableCell>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Subtitle</TableCell>
                                        <TableCell>User Name</TableCell>
                                        <TableCell>User Email</TableCell>
                                        <TableCell>Purchase Date</TableCell>
                                        <TableCell>Is in Cart</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        booksList.map(book => {
                                            return (
                                                <TableRow
                                                    key={book.id}
                                                    onClick={() =>
                                                        this.props.retrieveBook(book.id, book)
                                                    }
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell numeric>{book.id}</TableCell>
                                                    <TableCell>{book.title}</TableCell>
                                                    <TableCell>{book.subtitle}</TableCell>
                                                    <TableCell>
                                                        {`${book.parent_first_name} ${book.parent_last_name}`}
                                                    </TableCell>
                                                    <TableCell>{book.parent_email}</TableCell>
                                                    <TableCell>{book.purchase_date}</TableCell>
                                                    <TableCell>
                                                        {(book.is_in_cart === true) ?
                                                            <Check color="primary" />
                                                            :
                                                            <Close color="error" />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={8}
                                            count={total}
                                            rowsPerPage={rowsPerPage}
                                            page={currentPage}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            rowsPerPageOptions={[10]}
                                            labelDisplayedRows={
                                                () =>
                                                    this.handleLabelDisplayedRows(firstItem, lastItem, total)
                                            }
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </div>
                    }
                </Paper>
            </AuxHoc>
        );
    }

    render() {
        return (
            <AuxHoc>
                {this.renderContent()}
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ books }) => {
    const { error, loading, booksList, paginator, booksFilteredList } = books;

    return { error, loading, booksList, paginator, booksFilteredList };
};

export default connect(mapStateToProps,
    {
        retrieveBooks,
        retrieveBook,
        filterBooks,
    }
)(withStyles(styles)(Books));
