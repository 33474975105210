// Checked
import {
    DUPLICATE_CUSTOM_NOTIFICATION_SUCCESS,
    RETRIEVE_CUSTOM_NOTIFICATION_LIST_SUCCESS,
    RETRIEVE_CUSTOM_NOTIFICATION_SUCCESS,
    RETRIEVE_FILTERED_CUSTOM_NOTIFICATIONS_SUCCESS,
    SET_ERROR,
    SET_LOADING,
} from '../actions/types';

const INITIAL_STATE = {
    customNotificationList: [],
    customNotificationListFiltered: [],
    currentCustomNotification: {},
    frames: [],
    error: null,
    loading: false,
};

const customNotificationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {...state, loading: true, error: null};
        case SET_ERROR:
            return {...state, loading: false, error: action.payload};
        case RETRIEVE_CUSTOM_NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
                customNotificationList: action.payload.data
            };
        case RETRIEVE_FILTERED_CUSTOM_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                customNotificationListFiltered: action.payload
            };
        case RETRIEVE_CUSTOM_NOTIFICATION_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
                currentCustomNotification: action.payload.data
            };
        default:
            return state;
    }
};

export default customNotificationsReducer;
