// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import Tooltip from 'material-ui/Tooltip';
import AddIcon from 'material-ui-icons/Add';
import { LoaderModal, HeaderCard } from '../components/common';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import { retrieveStickers, retrieveStickerById, filterStickers } from '../actions';

class Stickers extends Component {

    constructor(props, context) {
        super(props, context);

        this.handleReadSticker = this.handleReadSticker.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.state = {
            search: '',
            wait: null
        };
    }

    componentWillMount() {
        this.props.retrieveStickers();
    }

    handleReadSticker(id) {
        this.props.retrieveStickerById(id);
    }

    handleSearch(event) {
        const self = this;
        const { stickersList } = this.props;
        let { wait } = this.state;
        const search = event.target.value;
        if (wait !== null) {
            clearTimeout(wait);
        }
        wait = setTimeout(() => {
            const searchWordsArray = search.trim()
                .split(' ')
                .filter(word => word.length > 0)
                .map(word => word.toLowerCase());
            const filteredStickers = stickersList.filter(
                sticker => {
                    const matchesArray = searchWordsArray.filter(
                        word => sticker.name.toLowerCase().indexOf(word) >= 0
                    );
                    return matchesArray.length > 0;
                }
            );
            self.props.filterStickers(filteredStickers);
        }, 300);
        this.setState({ search, wait });
    }

    renderStickers(stickersList) {
        return (
            stickersList &&
            stickersList.map(sticker => {
                const imageUrl = sticker.thumnail !== null ? sticker.thumbnail : sticker.image;
                return (
                    <div
                        key={sticker.id}
                        className="col-12 col-md-6 col-lg-4 milestone-card-header"
                        onClick={() => this.handleReadSticker(sticker.id)}
                    >
                        <div className="box">
                            <HeaderCard
                                id={sticker.id}
                                image={imageUrl}
                                alt={'Sticker Image'}
                                title={sticker.name}
                                subtitle={sticker.sticker_category.folder}
                            />
                        </div>
                    </div>
                );
            })
        );
    }

    render() {
        const { stickersList, filteredStickersList, loading } = this.props;
        if (loading) {
            return <LoaderModal loading={loading} />;
        }
        return (
            <AuxHoc>
                <div className="row justify-content-center align-items-center search-bar">
                    <div className="col-6">
                        <div className="box">
                            <TextField
                                id="search"
                                name="search"
                                placeholder="Search"
                                value={this.state.search}
                                onChange={this.handleSearch}
                                margin="normal"
                                inputProps={{ required: false }}
                                autoFocus
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
                <div className="row milestone-cards-container">
                    {
                        (filteredStickersList && filteredStickersList.length) > 0 ?
                            this.renderStickers(filteredStickersList)
                            :
                            this.renderStickers(stickersList)
                    }
                </div>
                <Tooltip title="Create a new Sticker">
                    <Button
                        variant="fab"
                        color="secondary"
                        className={this.props.classes.absolute}
                        onClick={() => this.props.history.push('/app/stickers/new')}
                    >
                        <AddIcon />
                    </Button>
                </Tooltip>
            </AuxHoc>
        );
    }
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    icon: {
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    formControl: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    searchButton: {
        cursor: 'pointer',
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3,
    }
});

const mapStateToProps = ({ stickers }) => {
    const { error, loading, stickersList, filteredStickersList } = stickers;

    return { error, loading, stickersList, filteredStickersList };
};

export default connect(mapStateToProps,
    {
        retrieveStickers,
        retrieveStickerById,
        filterStickers
    }
)(withStyles(styles)(Stickers));
