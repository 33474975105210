// Dependecies
import React from 'react';
import ReactDOM from 'react-dom';

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import App from './App';

const app = (
        <App />
);

ReactDOM.render(app, document.getElementById('admin'));
