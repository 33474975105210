// Checked
import axios from '../axios-admin';
import { handleError } from '../js/helpers';
import env from '../js/env';
import history from './../history';
import {
    SET_LOADING,
    SET_ERROR,
    RETRIEVE_BOOKS_SUCCESS,
    RETRIEVE_BOOK_SUCCESS,
    RETRIEVE_FILTERED_BOOKS_SUCCESS,
    GENERATE_BOOK_SUCCESS,
    SET_LOADING_AND_ERROR_FALSE
} from './types';

const ROOT_URL = '/api';

export function retrieveBooks(page, search) {
    const currentPage = page || 1;
    const searchWord = search || null;
    let url = '';
    if (searchWord === null) {
        url = `${ROOT_URL}/admin/books?limit=${env.GENERAL.paginator_limit}&lang=${env.GENERAL.lang}&page=${currentPage}`;
    } else {
        url = `${ROOT_URL}/admin/books?limit=${env.GENERAL.paginator_limit}&lang=${env.GENERAL.lang}&page=${currentPage}&search=${searchWord}`;
    }
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const paginatorResponse = response.data.data;
            dispatch(retrieveSuccess(paginatorResponse, RETRIEVE_BOOKS_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function filterBooks(filteredBooks) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        dispatch(retrieveSuccess(filteredBooks, RETRIEVE_FILTERED_BOOKS_SUCCESS));
    };
}

export function retrieveBook(id, book = null) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        if (!book) {
            axios({
                method: 'get',
                url: `${ROOT_URL}/admin/books/${id}?lang=${env.GENERAL.lang}`,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: env.GENERAL.accept,
                    Authorization: localStorage.getItem('token')
                }
            }).then((response) => {
                const bookResponse = response.data;
                dispatch(retrieveSuccess(bookResponse, RETRIEVE_BOOK_SUCCESS));
            }).catch((error) => {
                handleError(error);
                if (error !== undefined) {
                    if (error.response !== undefined) {
                        dispatch(retrieveFail(error.response.data.message));
                    }
                }
            });
        } else {
            dispatch(retrieveSuccess({ data: { ...book } }, RETRIEVE_BOOK_SUCCESS));
            if (id) {
                history.push(`/app/books/${id}`);
            }
        }
    };
}

export function generateBook(id, data) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/generate-book/${id}?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            const bookResponse = response.data.data;
            dispatch(retrieveSuccess(bookResponse, GENERATE_BOOK_SUCCESS));
            history.push('/app/books');
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function sendThroughAlexanders(bookId, alexandersData) {
    return function (dispatch) {
        dispatch({type: SET_LOADING});
        return axios({
            method: 'post',
            url: `${ROOT_URL}/admin/books/${bookId}/to-alexanders?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            },
            data: {
                ...alexandersData
            }
        }).then((response) => {
            dispatch({type: SET_LOADING_AND_ERROR_FALSE});

            return response;
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch({type: SET_ERROR, payload: error.response.data.message});
                }
            }

            throw error;
        });
    };
}

function retrieveSuccess(object, action) {
    return {
        type: action,
        payload: object
    };
}

function retrieveFail(error) {
    return {
        type: SET_ERROR,
        payload: error
    };
}

