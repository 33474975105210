// Checked
import React from 'react';
import Card, { CardHeader } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';

const HeaderCard = ({ id, image, title, subtitle, alt, Action = null }) => (
    <Card key={id}>
        <CardHeader
            avatar={
                <Avatar  
                    alt={alt}
                    src={image}
                />
            }
            action={
                Action === null ? false : Action
            }
            title={title}
            subheader={subtitle}
        />
    </Card>
);

export { HeaderCard };
