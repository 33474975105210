// Checked
import axios from '../axios-admin';
import { handleError } from '../js/helpers';
import history from './../history';
import {
    SET_LOADING_NOTIFICATION,
    SET_ERROR,
    RETRIEVE_NOTIFICATIONS_SUCCESS,
    RETRIEVE_NOTIFICATION_SUCCESS,
    RETRIEVE_FILTERED_NOTIFICATIONS_SUCCESS,
    CREATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_SUCCESS,
} from './types';

const ROOT_URL = '/api';

export function retrieveMessagesNotifications() {
    const url = `${ROOT_URL}/admin/notifications`;
    return function (dispatch) {
        dispatch({ type: SET_LOADING_NOTIFICATION });
        axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const result = response.data;
            dispatch(retrieveSuccess(result, RETRIEVE_NOTIFICATIONS_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function filterMessagesNotifications(filteredMessagesNotifications) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING_NOTIFICATION });
        dispatch(retrieveSuccess(filteredMessagesNotifications, RETRIEVE_FILTERED_NOTIFICATIONS_SUCCESS));
    };
}

export function retrieveMessagesNotification(id, notification = null) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING_NOTIFICATION });
        if (!notification) {
            axios({
                method: 'get',
                url: `${ROOT_URL}/admin/notification/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/x.babypage.v1.0.0+json',
                    Authorization: localStorage.getItem('token')
                }
            }).then((response) => {
                const notificationResponse = response.data;
                dispatch(retrieveSuccess(notificationResponse, RETRIEVE_NOTIFICATION_SUCCESS));
            }).catch((error) => {
                handleError(error);
                if (error !== undefined) {
                    if (error.response !== undefined) {
                        dispatch(retieveFail(error.response.data.message));
                    }
                }
            });
        } else {
            dispatch(retrieveSuccess({ data: { ...notification } }, RETRIEVE_NOTIFICATION_SUCCESS));
            if (id) {
                history.push(`/app/messages-notifications/${id}`);
            }
        }
    };
}

export function createMessagesNotification(data) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING_NOTIFICATION });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/notification`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            const notificationResponse = response.data;
            dispatch(retrieveSuccess(notificationResponse, CREATE_NOTIFICATION_SUCCESS));
            history.push(`/app/messages-notifications/${notificationResponse.data.id}`);
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function updateMessagesNotification(id, data) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING_NOTIFICATION });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/notification/${id}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            const notificationResponse = response.data;
            dispatch(retrieveSuccess(notificationResponse, UPDATE_NOTIFICATION_SUCCESS));
            history.push('/app/messages-notifications');
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function deleteMessagesNotification(id) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING_NOTIFICATION });
        axios({
            method: 'delete',
            url: `${ROOT_URL}/admin/notification/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const notificationResponse = response.data;
            dispatch(retrieveSuccess(notificationResponse, DELETE_NOTIFICATION_SUCCESS));
            history.push('/app/messages-notifications');
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retieveFail(error.response.data.message));
                }
            }
        });
    };
}

function retrieveSuccess(object, action) {
    return {
        type: action,
        payload: object
    };
}

function retieveFail(error) {
    return {
        type: SET_ERROR,
        payload: error
    };
}

