// Checked
import {
    SET_LOADING_NOTIFICATION,
    SET_ERROR,
    RETRIEVE_NOTIFICATIONS_SUCCESS,
    RETRIEVE_NOTIFICATION_SUCCESS,
    RETRIEVE_FILTERED_NOTIFICATIONS_SUCCESS,
    CREATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
    messagesNotificationsList: [],
    messagesNotificationsFilteredList: [],
    currentMessagesNotification: {},
    error: null,
    loadingMessagesNotifications: false,
};

const messagesNotificationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING_NOTIFICATION:
            return { ...state, loadingMessagesNotifications: true, error: null };
        case SET_ERROR:
            return { ...state, loadingMessagesNotifications: false, error: action.payload };
        case RETRIEVE_NOTIFICATIONS_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                messagesNotificationsList: action.payload.data
            };
        case RETRIEVE_NOTIFICATION_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE,
                currentMessagesNotification: action.payload.data
            };
        case RETRIEVE_FILTERED_NOTIFICATIONS_SUCCESS:
            return { 
                ...state,
                messagesNotificationsFilteredList: action.payload
            };
        case CREATE_NOTIFICATION_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                messagesNotificationsList: action.payload.data
            };
        case UPDATE_NOTIFICATION_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                messagesNotificationsList: action.payload.data
            };
        case DELETE_NOTIFICATION_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                messagesNotificationsList: action.payload.data
            };
        default:
            return state;
    }
};

export default messagesNotificationsReducer;
