import React from 'react';
import loaderAnimation from "../../../images/loading-button-animation.gif"

export default class LoaderModal extends React.Component {
    render() {
        const { loading } = this.props;
        const status = (loading) ? 'on' : 'off';
        return (
            <div id='loaderModal' className={`loader-modal ${status}`}>
                <div className="modal-content">
                    <img src={loaderAnimation} alt="loader" />
                </div>
            </div>
        );
    }

}
