// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card, { CardContent, CardActions } from 'material-ui/Card';
import { toast, ToastContainer } from "react-toastify";
import { TextField, AppBar, Toolbar, Typography, Button, Avatar, withStyles } from 'material-ui';
import { CircularProgress } from 'material-ui/Progress';
import green from 'material-ui/colors/green';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import { signInUser } from '../actions';
import '../sass/admin.scss'
import "react-toastify/dist/ReactToastify.css";
// import '../sass/_admin/milestones.scss'
// import '../sass/_admin/components.scss'
// import '../sass/_pages/giftcard.scss'

import owl from "../images/owls/owl.png"

let container;

class Login extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            email: '',
            password: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.error != null) {
             toast.error (nextProps.error);
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleSubmit() {
        const { email, password } = this.state;
        this.props.signInUser({ email, password });
    }

    render() {
        const { classes, loading } = this.props;
        return (
            <AuxHoc>
                <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                <div
                    className="row justify-content-center align-items-center middle-md middle-xs full-height login-bg"
                    style={{ margin: 0 }}
                >
                    <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                        <div className="box">
                            <Card>
                                <AppBar position="static">
                                    <Toolbar>
                                        {/* <div className={classes.row}> */}
                                        <Avatar
                                            alt="Logo"
                                            src={owl}
                                            className={classes.avatar}
                                        />
                                        <Typography
                                            variant="title"
                                            color="inherit"
                                            align="center"
                                        >
                                            BabyPage Admin
                                                </Typography>
                                        {/* </div> */}
                                    </Toolbar>
                                </AppBar>
                                <CardContent>
                                    <form autoComplete="off" method="post" onSubmit={this.handleSubmit}>
                                        <div className="col-12">
                                            <TextField
                                                id="email"
                                                name="email"
                                                label="Email"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                inputProps={{ required: true }}
                                                autoFocus
                                                fullWidth
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextField
                                                id="password"
                                                name="password"
                                                label="Password"
                                                type="password"
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                                inputProps={{ required: true }}
                                                margin="normal"
                                                fullWidth
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <CardActions>
                                                <div className="col-12 justify-content-center align-items-center">
                                                    <Button
                                                        size="large"
                                                        color="primary"
                                                        className="capitalize"
                                                        onClick={this.handleSubmit}
                                                        disabled={loading}
                                                    >
                                                        Sign In
                                                    </Button>
                                                    {loading &&
                                                        <CircularProgress
                                                            size={24}
                                                            className={classes.buttonProgress}
                                                        />
                                                    }
                                                </div>
                                            </CardActions>
                                        </div>
                                    </form>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </AuxHoc>
        );
    }
}

const styles = theme => ({
    row: {
        display: 'flex',
        justifyContent: 'center',
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 60,
        height: 60,
    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const mapStateToProps = ({ auth }) => {
    const { error, loading } = auth;

    return { error, loading };
};

export default connect(mapStateToProps, { signInUser })(withStyles(styles)(Login));
