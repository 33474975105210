
// Checked
import { FormControl } from 'material-ui/Form';
import { InputAdornment } from 'material-ui/Input';
import Paper from 'material-ui/Paper';
import { withStyles } from 'material-ui/styles';
import Table, { TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from 'material-ui/Table';
import TextField from 'material-ui/TextField';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAlexandersOrderById, getAlexandersOrders } from '../actions';
import Search from '@material-ui/icons/Search';
import { LoaderModal } from '../components/common';
import TablePaginationActions from '../components/TablePaginationActions';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import _ from 'lodash';

class AlexandersOrderList extends Component {

    constructor(props, context) {
        super(props, context);

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleLabelDisplayedRows = this.handleLabelDisplayedRows.bind(this);
        this.getAlexandersOrderById = this.getAlexandersOrderById.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.state = {
            page: 1,
            search: '',
        };
    }

    componentWillMount() {
        this.props.getAlexandersOrders();
    }

    handleChangePage(event, page) {
        const { search } = this.state;
        if (event !== null) {
            this.setState({ page });
            this.props.getAlexandersOrders(page, search);
        }
    }

    handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: event.target.value });
    }

    handleLabelDisplayedRows(from, to, count) {
        return `${from} - ${to} of ${count}`;
    }

    getAlexandersOrderById(id) {
        this.props.getAlexandersOrderById(id);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleSearch() {
        const { search } = this.state;
        this.props.getAlexandersOrders(1, search);
    }

    renderContent() {
        const { classes, loading, alexandersOrderList, paginator } = this.props;
     
        if (_.size(alexandersOrderList) <= 0 || loading) {
            return <LoaderModal loading={loading} />;
        } else {
        const rowsPerPage = parseInt(paginator.per_page, 10);
        const currentPage = parseInt(paginator.current_page, 10);
        const total = parseInt(paginator.total, 10);
        const firstItem = parseInt(paginator.first_item, 10);
        const lastItem = parseInt(paginator.last_item, 10);
        return (
            <AuxHoc>
                <Paper className={classes.root}>
                    {alexandersOrderList.length > 0  &&
                        <div className={classes.tableWrapper}>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    id="search"
                                    name="search"
                                    label="Search"
                                    type="search"
                                    className={classes.textField}
                                    margin="normal"
                                    onChange={this.handleChange}
                                    onBlur={this.handleSearch}
                                    value={this.state.search}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search
                                                    className={classes.searchButton}
                                                    onClick={this.handleSearch}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Order ID</TableCell>
                                        <TableCell>Stripe Order ID</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Created At</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        alexandersOrderList.map(row => (
                                            <TableRow
                                                key={row.id}
                                                onClick={() => this.getAlexandersOrderById(row.id)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <TableCell>{row.id}</TableCell>
                                                <TableCell>{row.stripe_order_id}</TableCell>
                                                <TableCell>{row.user.email}</TableCell>
                                                <TableCell>{row.current_status.status}</TableCell>
                                                <TableCell>{row.created_at}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={8}
                                            count={total}
                                            rowsPerPage={rowsPerPage}
                                            page={currentPage}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            rowsPerPageOptions={[10]}
                                            labelDisplayedRows={
                                                () =>
                                                    this.handleLabelDisplayedRows(firstItem, lastItem, total)
                                            }
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </div>
                    }
                </Paper>
            </AuxHoc>
        );
        }
        
    }
    
    render() {
        
        return (
            <AuxHoc>
                {this.renderContent()}
            </AuxHoc>
        );
    }
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    icon: {
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '40%',
    },
    formControl: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    searchButton: {
        cursor: 'pointer',
    },
});

const mapStateToProps = ({ alexandersOrders }) => {
    const { error, loading, paginator, alexandersOrderList } = alexandersOrders;
    
    return { error, loading, paginator, alexandersOrderList };
};

export default connect(mapStateToProps,
    {
        getAlexandersOrders,
        getAlexandersOrderById
    }
)(withStyles(styles)(AlexandersOrderList));

