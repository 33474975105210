// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'material-ui/styles';
import Card, { CardActions, CardContent, CardMedia } from 'material-ui/Card';
import Button from 'material-ui/Button';
import TextField from 'material-ui/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from 'material-ui/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import {
    retrieveAllStickerCategories,
    retrieveStickerById,
    createSticker,
    updateSticker,
    deleteSticker,

} from '../actions';
import { LoaderModal } from './common';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let container;

const styles = theme => ({
    card: {
        maxWidth: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    formControl: {
        //margin: theme.spacing.unit,
        minWidth: 120,
    },
});

class Sticker extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.renderStickerCard = this.renderStickerCard.bind(this);

        this.state = {
            name: {
                value: '',
                required: true,
                error: false,
            },
            stickerCategoryId: {
                value: '',
                required: true,
                error: false,
            },
            image: {
                value: '',
                file: null,
                required: true,
                error: false,
            },
            categories: null
        };
    }

    componentWillMount() {
        this.props.retrieveAllStickerCategories();
    }

    componentDidMount() {
        const { sticker, allStickerCategories } = this.props;
        const { id } = this.props.match.params;
        if (sticker && Object.keys(sticker).length > 0) {
            this.setState({
                name: { ...this.state.name, value: sticker.name },
                stickerCategoryId: { ...this.state.stickerCategoryId, value: sticker.sticker_category_id },
                image: { ...this.state.image, value: sticker.image },
            });
        }
        if (allStickerCategories && Object.keys(allStickerCategories).length > 0) {
            this.setState({ categories: allStickerCategories });
        }
        if (id !== 'new') {
            this.props.retrieveStickerById(id);
        } else {
            const imageUrl = '/images/no-image-icon.png';
            this.setState({
                image: { ...this.state.image, value: imageUrl },
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { error, allStickerCategories, sticker } = nextProps;
        if (error != null) {
            toast.error (error);
        }
        if (allStickerCategories !== null
            && this.props.allStickerCategories !== allStickerCategories) {
            this.setState({
                categories: allStickerCategories,
            });
        }
        if (sticker !== null && this.props.sticker !== sticker) {
            this.setState({
                name: { ...this.state.name, value: sticker.name },
                stickerCategoryId: { ...this.state.stickerCategoryId, value: sticker.sticker_category_id },
                image: { ...this.state.image, value: sticker.image },
            });
        }
    }

    handleChange(event) {
        const property = event.target.name;
        const value = event.target.value;
        this.setState({
            [property]: { ...this.state[property], value },
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const { name, stickerCategoryId, image } = this.state;
        const { id } = this.props.match.params;
        if (name.value === '') {
            this.setState({ name: { ...name, error: true } });
        } else if (stickerCategoryId.value === '') {
            this.setState({ stickerCategoryId: { ...stickerCategoryId, error: true } });
        } else {
            const payload = {
                name: name.value,
                sticker_category_id: stickerCategoryId.value,
                image: (image.file !== null) ? image.file : undefined,
            };
            const formData = new FormData();
            for (const index in payload) {
                if (payload[index] !== undefined) {
                    formData.append(index, payload[index]);
                }
            }
            if (id === 'new') {
                if (image.file === null) {
                    this.setState({ image: { ...image, error: true } });
                } else {
                    this.props.createSticker(formData);
                }
            } else {
                this.props.updateSticker(id, formData);
            }
        }
    }

    handleImage(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            this.setState({
                image: {
                    ...this.state.image,
                    file,
                    error: false,
                    value: reader.result
                }
            });
        }.bind(this);
    }

    renderStickerCard() {
        const { classes } = this.props;
        const { name, image, stickerCategoryId, categories } = this.state;
        const { id } = this.props.match.params;
        return (
            <Card className={classes.card}>
                <form onSubmit={this.handleSubmit}>
                    <CardMedia
                        className={classes.media}
                        image={image.value}
                        src='img'
                        title={name.value}
                        style={{ backgroundSize: 'contain' }}
                    />
                    <CardContent>
                        <div className="row justify-content-center align-items-center" style={{ width: '100%' }}>
                            <input
                                id="raised-button-file"
                                type="file"
                                accept="image/x-png"
                                style={{ display: 'none' }}
                                onChange={this.handleImage}
                            />
                            <label htmlFor="raised-button-file">
                                {
                                    image.error &&
                                    <FormHelperText
                                        id="name-error-text"
                                        style={{
                                            textAlign: 'center',
                                            padding: 5,
                                            color: 'red',
                                        }}
                                    >
                                        Image required
                                        </FormHelperText>
                                }
                                <Button
                                    color="primary"
                                    variant="raised"
                                    component="span"
                                >
                                    Upload image
                                    </Button>
                            </label>
                        </div>
                        <TextField
                            id="name"
                            name="name"
                            label="Name"
                            value={name.value}
                            placeholder="Name"
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: true }}
                            error={name.error}
                            fullWidth
                        />
                        <FormControl className={classes.formControl} style={{ width: '100%' }}>
                            <InputLabel htmlFor="stickerCategoryId">Sticker Category</InputLabel>
                            <Select
                                native
                                value={stickerCategoryId.value}
                                onChange={this.handleChange}
                                inputProps={{
                                    name: 'stickerCategoryId',
                                    id: 'stickerCategoryId',
                                    required: true
                                }}
                            >
                                <option value="" />
                                {
                                    categories && categories.map((category, index) => {
                                        return (
                                            <option key={index} value={category.id}>
                                                {category.name}
                                            </option>
                                        );
                                    })
                                }
                                {
                                    stickerCategoryId.error &&
                                    <FormHelperText
                                        id="name-error-text"
                                        style={{
                                            textAlign: 'center',
                                            padding: 5,
                                            color: 'red',
                                        }}
                                    >
                                        Category required
                                        </FormHelperText>
                                }
                            </Select>
                        </FormControl>
                    </CardContent>
                    <CardActions>
                        <div className="row around-xs" style={{ width: '100%' }}>
                            <Button
                                size="medium"
                                color="primary"
                                type="submit"
                            >
                                {id !== 'new' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="medium"
                                onClick={() => this.props.history.push('/app/stickers')}
                            >
                                Cancel
                            </Button>
                            {
                                id !== 'new' &&
                                <Button
                                    size="medium"
                                    color="secondary"
                                    onClick={() => {
                                        if (window.confirm('Confirm required to delete this sticker')) {
                                            this.props.deleteSticker(id);
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            }
                        </div>
                    </CardActions>
                </form>
            </Card>
        );
    }

    render() {
        const { loading } = this.props;
        return (
            <AuxHoc>
                <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                {
                    loading ?
                        <LoaderModal loading={loading} /> :
                        <div className="row around-xs">
                            <div className="col-12 col-md-6">
                                <div className="box">
                                    {
                                        this.renderStickerCard()
                                    }
                                </div>
                            </div>
                        </div>
                }
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ stickers, stickerCategories }) => {
    const { error, loading, sticker } = stickers;
    const { allStickerCategories } = stickerCategories;

    return { error, loading, sticker, allStickerCategories };
};

export default connect(mapStateToProps,
    {
        retrieveAllStickerCategories,
        retrieveStickerById,
        createSticker,
        updateSticker,
        deleteSticker,
    }
)(withStyles(styles)(Sticker));
