// Checked
export const AUTH_USER = 'auth_user';
export const AUTH_USER_SUCCESS = 'auth_user_success';
export const AUTH_USER_ERROR = 'auth_user_error';
export const UNAUTH_USER = 'unauth_user';
export const RETRIEVE_USERS_SUCCESS = 'retrieve_users_success';
export const RETRIEVE_USER_SUCCESS = 'retrieve_user_success';
export const RETRIEVE_PURCHASE = 'retrieve_purchase';
export const RETRIEVE_PURCHASE_SUCCESS = 'retrieve_purchase_success';
export const RETRIEVE_PURCHASE_ERROR = 'retrieve_purchase_error';
export const SET_LOADING = 'set_loading';
export const SET_LOADING_OFF = 'set_loading_off';
export const SET_LOADING_NOTIFICATION = 'set_loading_notification';
export const UNSET_LOADING = 'unset_loading';
export const SET_ERROR = 'set_error';
export const UNSET_ERROR = 'unset_error';
export const SET_LOADING_QUESTION = 'set_loading_question';
export const SET_LOADING_QUESTION_FALSE = 'set_loading_question_false';
export const RETRIEVE_MILESTONES_SUCCESS = 'retrieve_milestones_success';
export const RETRIEVE_MILESTONE_SUCCESS = 'retrieve_milestone_success';
export const RETRIEVE_FILTERED_MILESTONES_SUCCESS = 'retrieve_filtered_milestones_success';
export const CREATE_MILESTONE_SUCCESS = 'create_milestone_success';
export const UPDATE_MILESTONE_SUCCESS = 'update_milestone_success';
export const DELETE_MILESTONE_SUCCESS = 'delete_milestone_success';
export const DUPLICATE_MILESTONE_SUCCESS = 'duplicate_milestone_success';
export const RETRIEVE_QUESTIONS_SUCCESS = 'retrieve_questions_success';
export const RETRIEVE_QUESTION_SUCCESS = 'retrieve_question_success';
export const CREATE_QUESTION_SUCCESS = 'create_question_success';
export const UPDATE_QUESTION_SUCCESS = 'update_question_success';
export const UPDATE_QUESTIONS_SUCCESS = 'update_questions_success';
export const DELETE_QUESTION_SUCCESS = 'delete_question_success';
export const RETRIEVE_NOTIFICATIONS_SUCCESS = 'retrieve_notifications_success';
export const RETRIEVE_NOTIFICATION_SUCCESS = 'retrieve_notification_success';
export const RETRIEVE_FILTERED_NOTIFICATIONS_SUCCESS = 'retrieve_filtered_notifications_success';
export const CREATE_NOTIFICATION_SUCCESS = 'create_notification_success';
export const UPDATE_NOTIFICATION_SUCCESS = 'update_notification_success';
export const DELETE_NOTIFICATION_SUCCESS = 'delete_notification_success';
export const RETRIEVE_STICKER_CATEGORIES_SUCCESS = 'retrieve_sticker_categories_success';
export const RETRIEVE_ALL_STICKER_CATEGORIES_SUCCESS = 'retrieve_all_sticker_categories_success';
export const RETRIEVE_STICKER_CATEGORY_SUCCESS = 'retrieve_sticker_category_success';
export const CREATE_STICKER_CATEGORY_SUCCESS = 'create_sticker_category_success';
export const UPDATE_STICKER_CATEGORY_SUCCESS = 'update_sticker_category_success';
export const DELETE_STICKER_CATEGORY_SUCCESS = 'delete_sticker_category_success';
export const RETRIEVE_STICKERS_SUCCESS = 'retrieve_stickers_success';
export const RETRIEVE_STICKER_SUCCESS = 'retrieve_sticker_success';
export const CREATE_STICKER_SUCCESS = 'create_sticker_success';
export const UPDATE_STICKER_SUCCESS = 'update_sticker_success';
export const DELETE_STICKER_SUCCESS = 'delete_sticker_success';
export const RETRIEVE_FILTERED_STICKERS_SUCCESS = 'retrieve_filtered_stickers_success';
export const RETRIEVE_BOOKS_SUCCESS = 'retrieve_books_success';
export const RETRIEVE_BOOK_SUCCESS = 'retrieve_book_success';
export const RETRIEVE_FILTERED_BOOKS_SUCCESS = 'retrieve_filtered_books_success';
export const GENERATE_BOOK_SUCCESS = 'generate_book_success';
export const RETRIEVE_FONTS_SUCCESS = 'retrieve_fonts_success';
export const RETRIEVE_FONT_SUCCESS = 'retrieve_font_success';
export const CREATE_FONT_SUCCESS = 'create_font_success';
export const UPDATE_FONT_SUCCESS = 'update_font_success';
export const DELETE_FONT_SUCCESS = 'delete_font_success';
export const RETRIEVE_FRAMES_SUCCESS = 'retrieve_frames_success';
export const RETRIEVE_METRICS_SUCCESS = 'retrieve_metrics_success';
export const RETRIEVE_METRIC_SUCCESS = 'retrieve_metric_success';
export const STORE_METRIC_SUCCESS = 'store_metric_success';
export const RETRIEVE_METRIC_REPORT_SUCCESS = 'retrieve_metric_report_success';
export const RETRIEVE_ALL_METRICS_SUCCESS = 'retrieve_all_metrics_success';
export const RETRIEVE_CUSTOM_NOTIFICATION_LIST_SUCCESS = 'retrieve_custom_notification_list_success';
export const RETRIEVE_CUSTOM_NOTIFICATION_SUCCESS = 'retrieve_custom_notification_success';
export const SEND_CUSTOM_NOTIFICATION_SUCCESS = 'send_custom_notification_success';
export const RETRIEVE_FILTERED_CUSTOM_NOTIFICATIONS_SUCCESS = 'retrieve_filtered_custom_notification_success';
export const RETRIEVE_ALEXANDERS_ORDERS_SUCCESS = 'retrieve_orders_success';
export const RETRIEVE_ALEXANDERS_ORDER_SUCCESS = 'retrieve_order_success';
export const UNSET_LOADING_ALEXANDERS = 'unset_loading';
export const SET_LOADING_AND_ERROR_FALSE = 'set_loading_and_error_false';
export const SET_COVERS_CATEGORY = 'set_covers_category';
export const GET_MAINTENANCE = 'retrieve_maintenance_status';
export const SET_MAINTENANCE_LOADING = 'maintenance_loading';
export const TOGGLE_MAINTENANCE = 'toggle_maintenance';
export const SET_MAINTENANCE_MESSAGE = 'update_maintenance_message';

