// Checked
import {
    SET_LOADING_QUESTION,
    SET_LOADING_QUESTION_FALSE,
    SET_ERROR,
    RETRIEVE_QUESTIONS_SUCCESS,
    UPDATE_QUESTIONS_SUCCESS,
    RETRIEVE_QUESTION_SUCCESS,
    CREATE_QUESTION_SUCCESS,
    UPDATE_QUESTION_SUCCESS,
    DELETE_QUESTION_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
    questionsList: [],
    currentQuestion: {},
    error: null,
    loading: false,
};

const questionsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING_QUESTION:
            return { ...state, loading: true, error: null };
        case SET_LOADING_QUESTION_FALSE:
            return { ...state, loading: false, error: null };
        case SET_ERROR:
            return { ...state, loading: false, error: action.payload };
        case RETRIEVE_QUESTIONS_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                questionsList: action.payload.data
            };
        case RETRIEVE_QUESTION_SUCCESS:
            return { 
                ...state,
                loading: false,
                error: null,
                currentQuestion: action.payload.data
            };
        case CREATE_QUESTION_SUCCESS:
            return { 
                ...state,
                loading: false,
                error: null,
                questionsList: state.questionsList.concat([{ ...action.payload.data }]),
                currentQuestion: {},
            };
        case UPDATE_QUESTIONS_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                questionsList: action.payload.data
            };
        case UPDATE_QUESTION_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                currentQuestion: action.payload.data
            };
        case DELETE_QUESTION_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                currentQuestion: {}
            };
        default:
            return state;
    }
};

export default questionsReducer;
