
// Dependencies
import React, { Component } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import ReduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import axios from './axios-admin';
import history from './history';


// Components
import AlexandersOrder from './components/AlexandersOrder';
import User from './components/User';
import Milestone from './components/Milestone';
import MessagesNotification from './components/MessagesNotification';
import StickerCategory from './components/StickerCategory';
import Sticker from './components/Sticker';
import Book from './components/Book';
import Font from './components/Font';
import ChangeCoverCategory from './components/ChangeCoverCategory';
import ChangeMaintenance from './components/ChangeMaintenance';



// Containers
import Login from './containers/Login';
import Books from './containers/Books';
import Fonts from './containers/Fonts';
import Metrics from './containers/Metrics';
import HistoricalMetrics from './containers/HistoricalMetrics';
import HistoricalMetricsDashboard from './containers/HistoricalMetricsDashboard';
import Users from './containers/Users';
import UsersEngage from './containers/UsersEngage';
import MessagesNotifications from './containers/MessagesNotifications';
import Milestones from './containers/Milestones';
import StickerCategories from './containers/StickerCategories';
import Stickers from './containers/Stickers';
import NotificationList from './containers/NotificationList';
import Notifications from './containers/Notifications';
import NotificationsEdit from './containers/NotificationEdit';
import AlexandersOrderList from './containers/AlexandersOrderList';



// HOC
import Layout from './hoc/Layout/Layout';
import AuxHoc from './hoc/AuxHoc/AuxHoc';

// In repo imports
import env from './js/env';
import { AUTH_USER, AUTH_USER_ERROR, AUTH_USER_SUCCESS, } from './actions/types';
import { handleError } from './js/helpers';
import reducers from './reducers';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());
// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
jss.options.insertionPoint = document.getElementById('jss-insertion-point');



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* const enhancers = compose(
    window.devToolsExtension ? window.devToolsExtension() : f => f
); */
const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(ReduxThunk)));

const token = localStorage.getItem('token');
// If we have a token, consider the user to be signed in
if (token) {
    // we need to update application state
    const ROOT_URL = '/api';
    store.dispatch({ type: AUTH_USER });
    axios({
        method: 'get',
        url: `${ROOT_URL}/get-user?lang=${env.GENERAL.lang}`,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/x.babypage.v1.0.0+json',
            Authorization: localStorage.getItem('token')
        }
    }).then((response) => {
        const userResponse = response.data.data;
        // If request is good...
        // - Update state to indicate the auth user
        store.dispatch({ type: AUTH_USER_SUCCESS, payload: userResponse });
    }).catch((error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
        }

        handleError(error);
        if (error !== undefined) {
            if (error.response !== undefined) {
                store.dispatch({ type: AUTH_USER_ERROR, payload: error });
            }
        }
    });
}

class App extends Component {

    render() {
        return (
            <JssProvider jss={jss} generateClassName={generateClassName}>
            <Provider store={store}>
                <Router history={history}>
                    <AuxHoc>
                        <Switch>
                            <Route path="/" exact component={Login} />
                            <Route path="/login" exact component={Login} />
                            <Route
                                path="/app" exact={false}
                                component={
                                    () => {
                                        if (localStorage.getItem('token')) {
                                            return (
                                                <Switch>
                                                    <Route
                                                        exact path="/app/dashboard"
                                                        component={HistoricalMetricsDashboard}
                                                    />
                                                    <Route
                                                        exact path="/app/userengage"
                                                        component={UsersEngage}
                                                    />
                                                    <Layout>
                                                        <Route
                                                            exact path="/app/books"
                                                            component={Books}
                                                        />
                                                        <Route
                                                            exact path="/app/users"
                                                            component={Users}
                                                        />
                                                        <Route
                                                            exact path="/app/alexanders-orders"
                                                            component={AlexandersOrderList}
                                                        />
                                                        <Route
                                                            exact path="/app/alexanders-orders/:id"
                                                            component={AlexandersOrder}
                                                        />
                                                        <Route
                                                            exact path="/app/fonts"
                                                            component={Fonts}
                                                        />
                                                        <Route
                                                            exact path="/app/metrics"
                                                            component={Metrics}
                                                        />
                                                        <Route
                                                            exact path="/app/historical-metrics"
                                                            component={HistoricalMetrics}
                                                        />
                                                        <Route
                                                            exact path="/app/milestones"
                                                            component={Milestones}
                                                        />
                                                        <Route
                                                            exact path="/app/custom-notifications"
                                                            component={NotificationList}
                                                        />
                                                        <Route
                                                            exact path="/app/custom-notification/new"
                                                            component={Notifications}
                                                        />
                                                        <Route
                                                            exact path="/app/custom-notifications/:id"
                                                            component={NotificationsEdit}
                                                        />
                                                        <Route
                                                            exact path="/app/messages-notifications"
                                                            component={MessagesNotifications}
                                                        />
                                                        <Route
                                                            exact path="/app/messages-notifications/:id"
                                                            component={MessagesNotification}
                                                        />
                                                        <Route
                                                            exact path="/app/sticker-categories"
                                                            component={StickerCategories}
                                                        />
                                                        <Route
                                                            exact path="/app/stickers"
                                                            component={Stickers}
                                                        />
                                                        <Route
                                                            exact path="/app/users/:id"
                                                            component={User}
                                                        />
                                                        <Route
                                                            exact path="/app/milestones/:id"
                                                            component={Milestone}
                                                        />
                                                        <Route
                                                            exact path="/app/sticker-categories/:id"
                                                            component={StickerCategory}
                                                        />
                                                        <Route
                                                            exact path="/app/stickers/:id"
                                                            component={Sticker}
                                                        />
                                                        <Route
                                                            exact path="/app/books/:id"
                                                            component={Book}
                                                        />
                                                        <Route
                                                            exact path="/app/fonts/:id"
                                                            component={Font}
                                                        />
                                                        <Route
                                                            exact path="/app/change-maintenance"
                                                            component={ChangeMaintenance}
                                                        />
                                                    </Layout>
                                                </Switch>
                                            );
                                        }
                                        return <Redirect to='/login' />;
                                    }
                                }
                            />
                        </Switch>
                    </AuxHoc>
                </Router>
            </Provider>
            </JssProvider>
        );
    }
}

export default App;

