// Checked
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const PhotoSlider = ({ settings, collection }) => (
    <Slider {...settings}>
        {
            collection.map((object, index) => (
                <img
                    key={`item-${index}`}
                    src={object.thumbnail_with_url ? object.thumbnail_with_url : object.thumbnail}
                    alt={object.title}
                    style={{ height: 'auto' }}
                />
            ))
        }
    </Slider>
);

export { PhotoSlider };
