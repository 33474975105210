// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'material-ui/styles';
import Card, { CardActions, CardContent, CardMedia } from 'material-ui/Card';
import Button from 'material-ui/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from 'material-ui/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from 'material-ui/TextField';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import {
    retrieveBook,
    generateBook,
    sendThroughAlexanders
} from '../actions';
import { LoaderModal } from './common';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from 'material-ui';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let container;

const styles = theme => ({
    card: {
        maxWidth: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    formControl: {
        //margin: theme.spacing.unit,
        minWidth: 120,
    },
});

const bookTypes = ['Saddle Small', 'Saddle Large', 'Hard Cover', 'Digibook'];
const bookTypesForAlexanders = ['Small Soft Cover - 6"x6"', 'Large Hard Cover - 8"x8"', 'Large Soft Cover - 8"x8"'];
const initialAlexandersState = {
    bookType: bookTypesForAlexanders[0],
    firstName: '',
    lastName: '',
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Book extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderBookCard = this.renderBookCard.bind(this);
        this.handleAlexandersChange = this.handleAlexandersChange.bind(this);
        this.handleSendThroughAlexanders = this.handleSendThroughAlexanders.bind(this);
        this.validateAlexandersData = this.validateAlexandersData.bind(this);

        this.state = {
            book: this.props.book,
            type: 'Saddle Small',
            showDialog: false,
            alexanders: initialAlexandersState
        };
    }

    componentDidMount() {
        const { book } = this.props;
        const { id } = this.props.match.params;
        if (book && Object.keys(book).length > 0) {
            this.setState({ book });
        } else {
            this.props.retrieveBook(id);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { error, book } = nextProps;
        if (error != null) {
            toast.error (error);
        }
        if (book !== null & this.props.book !== book) {
            this.setState({ book });
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const { book, type } = this.state;
        const { id } = this.props.match.params;
        const payload = { type };
        if (id) {
            this.props.generateBook(id, payload);
        } else {
            this.props.generateBook(book.id, payload);
        }
    }

    handleAlexandersChange(event) {
        this.setState({
            alexanders: { ...this.state.alexanders, [event.target.name]: event.target.value },
        });
    }

    handleSendThroughAlexanders() {
        const { book, alexanders } = this.state;
        const { id } = this.props.match.params;
        const bookId = id || book.id;
        if (!this.validateAlexandersData(alexanders)) {
            return;
        }
        alexanders.thumbnail_url = book.thumbnail_with_url;

        this.props.sendThroughAlexanders(bookId, alexanders)
            .then(() => {
                toast.success ("You have sent successfully the book to Alexander's");

                this.setState((state) => ({
                    alexanders: initialAlexandersState,
                }));
            })
            .finally(() => this.setState(state => ({ showDialog: !state.showDialog })));
    }

    validateAlexandersData(alexanders) {
        if (!alexanders.bookType) {
            alert('Book Type field is required');
            return false;
        }

        if (!alexanders.firstName) {
            alert('First Name field is required');
            return false;
        }

        if (!alexanders.lastName) {
            alert('Last Name field is required');
            return false;
        }

        if (!alexanders.streetAddress) {
            alert('Street Address field is required');
            return false;
        }

        if (!alexanders.city) {
            alert('City field is required');
            return false;
        }

        if (!alexanders.state) {
            alert('State field is required');
            return false;
        }

        if (!alexanders.postalCode) {
            alert('Postal Code field is required');
            return false;
        }

        if (!alexanders.phoneNumber) {
            alert('Phone Number field is required');
            return false;
        }

        return true;
    }

    renderBookCard() {
        const { classes, book } = this.props;
        const { type } = this.state;
        return (
            <Card className={classes.card}>
                <form onSubmit={this.handleSubmit}>
                    <CardMedia
                        className={classes.media}
                        image={book.thumbnail_with_url}
                        src='img'
                        style={{ backgroundSize: 'contain' }}
                    />
                    <CardContent>
                        <TextField
                            id="id"
                            name="id"
                            label="Id"
                            value={book.id}
                            placeholder="Id"
                            margin="normal"
                            inputProps={{ required: true }}
                            fullWidth
                            readOnly
                        />
                        <TextField
                            id="title"
                            name="title"
                            label="Title"
                            value={book.title}
                            placeholder="Title"
                            margin="normal"
                            inputProps={{ required: true }}
                            fullWidth
                            readOnly
                        />
                        <TextField
                            id="subtitle"
                            name="subtitle"
                            label="Subtitle"
                            value={book.subtitle}
                            placeholder="Subtitle"
                            margin="normal"
                            inputProps={{ required: true }}
                            fullWidth
                            readOnly
                        />
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel htmlFor="type">Type of Book</InputLabel>
                            <Select
                                native
                                value={(type !== null) ? type : ''}
                                onChange={this.handleChange}
                                inputProps={{
                                    name: 'type',
                                    id: 'type',
                                }}
                            >
                                {
                                    bookTypes.map((bookType, index) =>
                                    (
                                        <option key={index} value={bookType}>
                                            {bookType}
                                        </option>
                                    )
                                    )
                                }
                            </Select>
                        </FormControl>
                    </CardContent>
                    <CardActions>
                        <div className="row around-xs" style={{ width: '100%' }}>
                            <Button
                                size="medium"
                                color="primary"
                                type="submit"
                            >
                                Generate Book
                            </Button>
                            <Button
                                size="medium"
                                onClick={() => this.props.history.push('/app/books')}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="medium"
                                color="secondary"
                                type="button"
                                onClick={() => this.setState({ showDialog: true })}
                            >
                                Send through Alexander's
                            </Button>
                        </div>
                    </CardActions>
                </form>
            </Card>
        );
    }

    render() {
        const { loading } = this.props;
        const { alexanders } = this.state;

        return (
            <AuxHoc>
                <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                {loading ? (
                    <LoaderModal loading={loading} />
                ) : (
                    <div className="row around-xs">
                        <div className="col-12 col-md-6">
                            <div className="box">
                                {this.renderBookCard()}
                                <Dialog
                                    open={this.state.showDialog}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle id="alert-dialog-slide-title">
                                        Send this book through Alexander's
                                    </DialogTitle>
                                    <DialogContent>
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel htmlFor="alexandersType">
                                                Book Type
                                            </InputLabel>
                                            <Select
                                                native
                                                value={alexanders.bookType}
                                                onChange={this.handleAlexandersChange}
                                                inputProps={{
                                                    name: 'bookType',
                                                    id: 'bookType',
                                                }}
                                            >
                                                {bookTypesForAlexanders.map((bookType, index) => (
                                                    <option key={index} value={bookType}>
                                                        {bookType}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            id="firstName"
                                            name="firstName"
                                            label="First Name"
                                            value={alexanders.firstName || ''}
                                            onChange={this.handleAlexandersChange}
                                            placeholder="First Name"
                                            margin="normal"
                                            inputProps={{ required: true }}
                                            fullWidth
                                            readOnly
                                        />
                                        <TextField
                                            id="lastName"
                                            name="lastName"
                                            label="Last Name"
                                            value={alexanders.lastName}
                                            onChange={this.handleAlexandersChange}
                                            placeholder="Last Name"
                                            margin="normal"
                                            inputProps={{ required: true }}
                                            fullWidth
                                            readOnly
                                        />
                                        <TextField
                                            id="streetAddress"
                                            name="streetAddress"
                                            label="Street Address"
                                            value={alexanders.streetAddress}
                                            onChange={this.handleAlexandersChange}
                                            placeholder="Street Address"
                                            margin="normal"
                                            inputProps={{ required: true }}
                                            fullWidth
                                            readOnly
                                        />
                                        <TextField
                                            id="city"
                                            name="city"
                                            label="City"
                                            value={alexanders.city}
                                            onChange={this.handleAlexandersChange}
                                            placeholder="City"
                                            margin="normal"
                                            inputProps={{ required: true }}
                                            fullWidth
                                            readOnly
                                        />
                                        <TextField
                                            id="state"
                                            name="state"
                                            label="State"
                                            value={alexanders.state}
                                            onChange={this.handleAlexandersChange}
                                            placeholder="State"
                                            margin="normal"
                                            inputProps={{ required: true }}
                                            fullWidth
                                            readOnly
                                        />
                                        <TextField
                                            id="postalCode"
                                            name="postalCode"
                                            label="Postal Code"
                                            value={alexanders.postalCode}
                                            onChange={this.handleAlexandersChange}
                                            placeholder="Postal Code"
                                            margin="normal"
                                            inputProps={{ required: true }}
                                            fullWidth
                                            readOnly
                                        />
                                        <TextField
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            label="Phone Number"
                                            value={alexanders.phoneNumber}
                                            onChange={this.handleAlexandersChange}
                                            placeholder="Phone Number"
                                            margin="normal"
                                            inputProps={{ required: true }}
                                            fullWidth
                                            readOnly
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={this.handleSendThroughAlexanders}
                                            color="primary"
                                            type="button"
                                        >
                                            Continue
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                this.setState((state) => ({
                                                    showDialog: !state.showDialog,
                                                }))
                                            }
                                            color="secondary"
                                            type="button"
                                        >
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                )}
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ books }) => {
    const { error, loading, book } = books;

    return { error, loading, book };
};

export default connect(mapStateToProps,
    {
        retrieveBook,
        generateBook,
        sendThroughAlexanders
    }
)(withStyles(styles)(Book));
