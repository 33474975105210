// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'material-ui';
import { withStyles } from 'material-ui/styles';
import AddIcon from 'material-ui-icons/Add';
import Button from 'material-ui/Button';
import Tooltip from 'material-ui/Tooltip';

import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import {
    retrieveMessagesNotifications,
    retrieveMessagesNotification,
    filterMessagesNotifications,
} from '../actions';
import { HeaderCard } from '../components/common';
import noImageIcon from '../images/no-image-icon.png'


const styles = theme => ({
    fab: {
        margin: theme.spacing.unit * 2,
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3,
    },
});

class MessagesNotifications extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            searchWords: '',
            wait: null
        };
    }

    componentDidMount() {
        this.props.retrieveMessagesNotifications();
        this.props.retrieveMessagesNotification(undefined, {});
        this.props.filterMessagesNotifications([]);
    }

    handleChange(event) {
        const self = this;
        const { messagesNotificationsList } = this.props;
        const searchWords = event.target.value;
        let { wait } = this.state;

        if (wait !== null) {
            clearTimeout(wait);
        }

        wait = setTimeout(() => {
            const searchWordsArray = searchWords.trim()
                .split(' ')
                .filter(word => word.length > 0)
                .map(word => word.toLowerCase());
            const filteredMessagesNotifications = messagesNotificationsList.filter(
                messagesNotification => {
                    const matchesArray = searchWordsArray.filter(
                        word => messagesNotification.description.toLowerCase().indexOf(word) >= 0
                    );
                    return matchesArray.length > 0;
                }
            );
            self.props.filterMessagesNotifications(filteredMessagesNotifications);
        }, 300);
        this.setState({ searchWords, wait });
    }

    renderMessagesNotifications(messagesNotificationsList) {
        const self = this;
        return (
            messagesNotificationsList.length > 0 &&
            messagesNotificationsList.map(messagesNotification => {
                const imgUrl = messagesNotification.email_image_url == null ?
                    noImageIcon
                    : messagesNotification.email_image_url;

                return (
                    <div
                        key={messagesNotification.id}
                        className="col-12 col-md-6 col-lg-4 messagesNotification-card-header"
                        onClick={() => self.props.retrieveMessagesNotification(messagesNotification.id, messagesNotification)}
                    >
                        <div className="box">
                            <HeaderCard
                                id={messagesNotification.id}
                                image={imgUrl}
                                alt={'MessagesNotification Image'}
                                subtitle={messagesNotification.title}
                                title={messagesNotification.description}
                            />
                        </div>
                    </div>
                );
            })
        );
    }

    render() {
        const { messagesNotificationsList, messagesNotificationsFilteredList } = this.props;
        return (
            <AuxHoc>
                <div className="row justify-content-center align-items-center search-bar">
                    <div className="col-6">
                        <div className="box">
                            <TextField
                                id="search"
                                name="search"
                                placeholder="Search"
                                value={this.state.searchWords}
                                onChange={this.handleChange}
                                margin="normal"
                                inputProps={{ required: false }}
                                autoFocus
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
                <div className="row milestone-cards-container">
                    {
                        messagesNotificationsFilteredList.length > 0 ?
                            this.renderMessagesNotifications(messagesNotificationsFilteredList)
                            :
                            this.renderMessagesNotifications(messagesNotificationsList)
                    }

                </div>
                <Tooltip title="Create a new MessagesNotification">
                    <Button
                        variant="fab"
                        color="secondary"
                        className={this.props.classes.absolute}
                        onClick={() => this.props.history.push('/app/messages-notifications/new')}
                    >
                        <AddIcon />
                    </Button>
                </Tooltip>
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ messagesNotifications }) => {
    const { error, loadingMessagesNotifications, messagesNotificationsList, messagesNotificationsFilteredList } = messagesNotifications;

    return { error, loadingMessagesNotifications, messagesNotificationsList, messagesNotificationsFilteredList };
};

export default connect(mapStateToProps,
    {
        retrieveMessagesNotifications,
        retrieveMessagesNotification,
        filterMessagesNotifications,
    }
)(withStyles(styles)(MessagesNotifications));
