// Checked
import env from '../js/env';
import {handleError} from '../js/helpers';
import axios from '../axios-admin';
import history from '../history';
import {
    RETRIEVE_ALEXANDERS_ORDER_SUCCESS,
    RETRIEVE_ALEXANDERS_ORDERS_SUCCESS,
    SET_ERROR,
    SET_LOADING,
    UNSET_LOADING_ALEXANDERS
} from './types';

const ROOT_URL = '/api';

export function getAlexandersOrders(page, search) {
    const currentPage = page || 1;
    const searchWord = search || null;

    let url = `${ROOT_URL}/admin/alexanders-orders?limit=${env.GENERAL.paginator_limit}&lang=${env.GENERAL.lang}&page=${currentPage}`;
    if (searchWord !== null) {
        url = `${url}&search=${encodeURIComponent(searchWord)}`;
    }

    return function (dispatch) {
        dispatch({type: SET_LOADING});
        axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const paginatorResponse = response.data.data;
            dispatch(retrieveSuccess(paginatorResponse, RETRIEVE_ALEXANDERS_ORDERS_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function getAlexandersOrderById(id) {
    return function (dispatch) {
        dispatch({type: SET_LOADING});
        axios({
            method: 'get',
            url: `${ROOT_URL}/admin/alexanders-orders/${id}?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const userResponse = response.data.data;
            dispatch(retrieveSuccess(userResponse, RETRIEVE_ALEXANDERS_ORDER_SUCCESS));
            history.push(`/app/alexanders-orders/${id}`);
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function regenerateBooks(alexandersOrderId) {
    return function (dispatch) {
        dispatch({type: SET_LOADING});
        return axios({
            method: 'post',
            url: `${ROOT_URL}/admin/alexanders-orders/${alexandersOrderId}/regenerate-books?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const userResponse = response.data;
            dispatch({type: UNSET_LOADING_ALEXANDERS});

            return userResponse;
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }

            throw error;
        });
    };
}

export function resendOrder(id) {
    return function (dispatch) {
        dispatch({type: SET_LOADING});
        return axios({
            method: 'post',
            url: `${ROOT_URL}/admin/alexanders-orders/${id}/resend-order?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const userResponse = response.data;
            dispatch({type: UNSET_LOADING_ALEXANDERS});

            return userResponse;
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }

            throw error;
        });
    };
}

function retrieveSuccess(object, action) {
    return {
        type: action,
        payload: object
    };
}

function retrieveFail(error) {
    return {
        type: SET_ERROR,
        payload: error
    };
}

