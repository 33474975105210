
// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { withStyles } from 'material-ui/styles';
import Card, { CardActions, CardContent, CardMedia } from 'material-ui/Card';
import Button from 'material-ui/Button';
import TextField from 'material-ui/TextField';
import FormControl from '@material-ui/core/FormControl';
import noImageIcon from '../images/no-image-icon.png'
import Select from 'material-ui/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from 'material-ui/Typography';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import {
    createMilestone,
    deleteMilestone,
    retrieveFrames,
    retrieveMessagesNotifications,
    retrieveMilestone,
    updateMilestone
} from '../actions';
import { LoaderModal } from './common';
import Questions from './Questions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from 'material-ui/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let container;
const SPECIAL_MILESTONES_AGE_WEEKS = [
    -1, //Custom Page | id = 58
    0, // Birth Announcement | id = 49
    -2 // Photo-Only Page | id = 206
];

const styles = theme => ({
    card: {
        maxWidth: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop: 15,
        backgroundColor: theme.palette.background.paper,
    },
    row: {
        display: 'inline-flex',
        justifyContent: 'center',
    },
    avatar: {
        margin: 10,
        cursor: 'pointer'
    },
    selected: {
        borderStyle: 'solid',
        borderWidth: 'medium',
        borderColor: '#3adb76'
    },
});

const TabContainer = (props) => (
    <Typography
        component="div"
        style={{ padding: 8 * 3 }}
    >
        {props.children}
    </Typography>
);

class Milestone extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.renderMilestoneCard = this.renderMilestoneCard.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleFrameChange = this.handleFrameChange.bind(this);
        this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
        this.state = {
            title: {
                value: this.props.currentMilestone.title_name,
                required: true,
                error: false,
            },
            description: {
                value: this.props.currentMilestone.description,
                required: true,
                error: false,
            },
            age_weeks: {
                value: this.props.currentMilestone.age_weeks,
                required: false,
                error: false,
            },
            age_months: {
                value: this.props.currentMilestone.age_weeks,
                required: false,
                error: false,
            },
            active_date: {
                value: new moment(this.props.currentMilestone.active_date).isValid() ?
                    new moment(this.props.currentMilestone.active_date).format('YYYY-MM-DD') :
                    new moment().format('YYYY-MM-DD'),
                required: true,
                error: false,
            },
            ending_date: {
                value: this.props.currentMilestone.ending_date,
                required: true,
                error: false,
            },
            image: {
                value: this.props.currentMilestone.url_image ?
                    this.props.currentMilestone.url_image : this.props.currentMilestone.image_url,
                file: null,
                required: true,
                error: false,
            },
            emailNotificationImage: {
                value: this.props.currentMilestone.url_image ?
                    this.props.currentMilestone.url_image : this.props.currentMilestone.image_url,
                file: null,
                required: true,
                error: false,
            },
            emailNotificationMessage: {
                value: this.props.currentMilestone.title_name,
                required: true,
                error: false,
            },
            pushNotificationMessage: {
                value: this.props.currentMilestone.title_name,
                required: true,
                error: false,
            },
            type: {
                value: 'holiday',
                options: [
                    { value: 'holiday', displayName: 'Holiday' },
                    { value: 'weeks', displayName: 'Weeks' },
                    { value: 'months', displayName: 'Months' },
                    { value: 'years', displayName: 'Years' },
                    { value: 'first-event', displayName: 'First Event' },
                    { value: 'pregnancy', displayName: 'Pregnancy' },
                ],
            },
            message: {
                value: this.props.currentMilestone.message_id == null ?
                    'None' : this.props.currentMilestone.message_id,
            },
            frame: {
                value: this.props.currentMilestone.background_id,
                required: false,
                error: false,
            },
            frameTab: '',
            pregnancy_weeks: {
                value: this.props.currentMilestone.pregnancy_weeks > 0 ? this.props.currentMilestone.pregnancy_weeks : undefined,
                required: false,
                error: false,
            },
            date_required: {
                value: this.props.currentMilestone.date_required,
                required: false,
                error: false,
            },
            calendar_date: {
                value: new moment(this.props.currentMilestone.active_date).isValid() ?
                    new moment(this.props.currentMilestone.active_date).format('YYYY-MM-DD') :
                    new moment().format('YYYY-MM-DD'),
                required: false,
                error: false,
            },
        };
        if (this.props.currentMilestone.age_months > 0) {
            if (this.props.currentMilestone.age_months % 12 === 0) {
                this.state.type.value = 'years';
                this.state.age_months.value = this.props.currentMilestone.age_months / 12;
            } else {
                this.state.type.value = 'months';
                this.state.age_months.value = this.props.currentMilestone.age_months;
            }
        } else if (this.props.currentMilestone.age_weeks > 0) {
            this.state.type.value = 'weeks';
            this.state.age_weeks.value = this.props.currentMilestone.age_weeks;
        } else if (this.props.currentMilestone.age_weeks < 0) {
            if (this.props.currentMilestone.age_weeks === -800) {
                this.state.type.value = 'first-event';
            } else if (this.props.currentMilestone.age_weeks === -700) {
                this.state.type.value = 'pregnancy';
            }
        }
    }

    componentDidMount() {
        const { currentMilestone } = this.props;
        const { id } = this.props.match.params;
        this.props.retrieveMessagesNotifications();
        this.props.retrieveFrames();
        if (id !== 'new' && Object.keys(currentMilestone).length === 0) {
            this.props.retrieveMilestone(id);
        } else {
            let imgUrl = '';
            if (id === 'new') {
                imgUrl = noImageIcon;
            } else {
                imgUrl = currentMilestone.url_image == null ?
                    currentMilestone.image_url
                    : currentMilestone.url_image;
            }
            this.setState({
                image: {
                    ...this.state.image,
                    value: imgUrl
                }
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { error, frames, currentMilestone } = nextProps;
        const { id } = nextProps.match.params;
        if (error != null) {
            toast.error (error);
        }
        if (frames !== this.props.frames) {
            if (currentMilestone.background_id !== null) {
                const frame = _.find(frames,
                    (frameObject) => frameObject.id === currentMilestone.background_id);
                if (frame) {
                    this.setState({
                        frameTab: frame.folder
                    });
                }
            }
        }

        let imgUrl = '';
        if (id === 'new') {
            imgUrl = noImageIcon;
        } else {
            imgUrl = currentMilestone.url_image == null ?
                currentMilestone.image_url
                : currentMilestone.url_image;
        }
        this.setState({
            image: {
                ...this.state.image,
                value: imgUrl
            }
        });
    }

    componentDidUpdate(prevProps) {
        const { currentMilestone } = this.props;
        const { id } = this.props.match.params;
        if (currentMilestone !== prevProps.currentMilestone) {
            const self = this;
            Object.keys(currentMilestone).forEach(
                property => {
                    const value = self.props.currentMilestone[property];

                    if (property === 'message_id') {
                        self.setState({
                            message: {
                                value
                            }
                        });
                    }

                    if (self.state.hasOwnProperty(property)) {
                        self.setState({
                            [property]: { ...self.state[property], value },
                        });
                        if (property === 'age_months' && value > 0) {
                            if (value % 12 === 0) {
                                self.setState({
                                    type: {
                                        ...self.state.type,
                                        value: 'years',
                                    },
                                    age_months: {
                                        ...self.state.age_months,
                                        value: value / 12,
                                    },
                                });
                            } else {
                                self.setState({
                                    type: { ...self.state.type, value: 'months' },
                                    age_months: {
                                        ...self.state.age_months,
                                        value,
                                    },
                                });
                            }
                        } else if (property === 'age_weeks'
                            && value > 0 && self.state.type.value !== 'months') {
                            self.setState({
                                type: { ...self.state.type, value: 'weeks' },
                            });
                        } else if (property === 'age_weeks' && value < 0) {
                            let milestoneType = '';
                            if (this.props.currentMilestone.age_weeks === '-800') {
                                milestoneType = 'first-event';
                            } else if (this.props.currentMilestone.age_weeks === '-700') {
                                milestoneType = 'pregnancy';
                            } else {
                                milestoneType = 'holiday';
                            }

                            self.setState({
                                type: {
                                    ...self.state.type,
                                    value: milestoneType
                                },
                            });
                        }
                    }
                }
            );

            let imgUrl = '';
            if (id === 'new') {
                imgUrl = noImageIcon;
            } else {
                imgUrl = currentMilestone.url_image == null ?
                    currentMilestone.image_url
                    : currentMilestone.url_image;
            }
            this.setState({
                image: {
                    ...this.state.image,
                    value: imgUrl
                }
            });
        }
    }

    handleChangeDate(event) {
        if (event.target.name === 'active_date') {
            const { active_date } = this.state;
            this.setState({
                active_date: { ...active_date, value: event.target.value },
            });
        } else {
            const { calendar_date } = this.state;
            this.setState({
                calendar_date: { ...calendar_date, value: event.target.value },
            });
        }
    }

    handleChange(event) {
        const { age_weeks, age_months, active_date } = this.state;
        const property = event.target.name;
        const value = event.target.value;
        const pregDefaultDate = (value === 'pregnancy') ? '00-00-0000' : new moment().format('YYYY-MM-DD');
        if (property === 'type') {
            switch (value) {
                case 'holiday':
                case 'first-event':
                case 'pregnancy':
                    this.setState({
                        age_weeks: {
                            ...age_weeks,
                            value: 0,
                            required: false
                        },
                        age_months: {
                            ...age_months,
                            value: 0,
                            required: false
                        },
                        active_date: {
                            ...active_date,
                            value: new moment(this.props.currentMilestone.active_date).isValid() ?
                                new moment(this.props.currentMilestone.active_date).format('YYYY-MM-DD') :
                                new moment().format('YYYY-MM-DD'),
                            required: true
                        },
                        calendar_date: {
                            value: pregDefaultDate
                        }
                    });
                    break;
                case 'weeks':
                    this.setState({
                        age_weeks: {
                            ...age_weeks,
                            value: this.props.currentMilestone.age_weeks > 0 ? this.props.currentMilestone.age_weeks : 0,
                            required: true
                        },
                        age_months: {
                            ...age_months,
                            value: 0,
                            required: false
                        },
                        active_date: {
                            ...active_date,
                            value: new moment().format('YYYY-MM-DD'),
                            required: false
                        },
                    });
                    break;
                case 'months':
                    this.setState({
                        age_weeks: {
                            ...age_weeks,
                            value: 0,
                            required: false
                        },
                        age_months: {
                            ...age_months,
                            value: 0,
                            required: true
                        },
                        active_date: {
                            ...active_date,
                            value: new moment().format('YYYY-MM-DD'),
                            required: false
                        },
                    });
                case 'years':
                    this.setState({
                        age_weeks: {
                            ...age_weeks,
                            value: 0,
                            required: false
                        },
                        age_months: {
                            ...age_months,
                            value: 0,
                            required: true
                        },
                        active_date: {
                            ...active_date,
                            value: new moment().format('YYYY-MM-DD'),
                            required: false
                        },
                    });
                    break;
                default:
                    break;
            }
        }
        this.setState({
            [property]: { ...this.state[property], value },
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const { title, description, image, type, active_date, age_months,
            age_weeks, message, frame, pregnancy_weeks, date_required, calendar_date } = this.state;
        const { id } = this.props.match.params;
        if (id === 'new') {
            if (image.file == null) {
                this.setState({ image: { ...image, error: true } });
            } else {
                const payload = {
                    title: title.value,
                    description: description.value,
                    image: image.file,
                    message: message.value === 'None' ? null : message.value,
                    background_id: frame.value
                };
                if (payload.background_id === null) {
                    delete payload.background_id;
                }
                switch (type.value) {
                    case 'holiday':
                        payload.active_date = new moment(active_date.value).format('YYYY-MM-DD');
                        payload.age_weeks = -999;
                        payload.age_months = 0;
                        break;
                    case 'weeks':
                        payload.age_weeks = age_weeks.value;
                        payload.age_months = 0;
                        break;
                    case 'months':
                        payload.age_months = age_months.value;
                        payload.age_weeks = age_months.value * 4;
                        break;
                    case 'years':
                        payload.age_months = age_months.value * 12;
                        payload.age_weeks = age_months.value * 12 * 4;
                        break;
                    case 'first-event':
                        payload.active_date = new moment(active_date.value).format('YYYY-MM-DD');
                        payload.age_weeks = -800;
                        payload.age_months = 0;
                        break;
                    case 'pregnancy':
                        payload.active_date = new moment(active_date.value).format('YYYY-MM-DD');
                        payload.age_weeks = -700;
                        payload.age_months = 0;
                        payload.pregnancy_weeks = pregnancy_weeks.value;
                        payload.date_required = date_required.value ? 1 : 0;
                        payload.calendar_date = new moment(calendar_date.value).format('YYYY-MM-DD');
                        break;
                    default:
                        break;
                }
                const formData = new FormData();
                Object.keys(payload).forEach(data => {
                    if (payload[data] !== undefined) {
                        formData.append(data, payload[data]);
                    }
                });
                this.props.createMilestone(formData);
            }
        } else {
            const payload = {
                title: title.value,
                description: description.value,
                image: image.file != null ? image.file : undefined,
                message: message.value === 'None' ? null : message.value,
                background_id: frame.value
            };
            if (payload.background_id === null) {
                delete payload.background_id;
            }
            const { currentMilestone } = this.props;
            if (SPECIAL_MILESTONES_AGE_WEEKS.indexOf(currentMilestone.age_weeks) === -1) {
                switch (type.value) {
                    case 'holiday':
                        payload.active_date = new moment(active_date.value).format('YYYY-MM-DD');
                        payload.age_weeks = -999;
                        payload.age_months = 0;
                        break;
                    case 'weeks':
                        payload.age_weeks = age_weeks.value;
                        payload.age_months = 0;
                        break;
                    case 'months':
                        payload.age_months = age_months.value;
                        payload.age_weeks = age_months.value * 4;
                        break;
                    case 'years':
                        payload.age_months = age_months.value * 12;
                        payload.age_weeks = age_months.value * 12 * 4;
                        break;
                    case 'first-event':
                        payload.active_date = new moment(active_date.value).format('YYYY-MM-DD');
                        payload.age_weeks = -800;
                        payload.age_months = 0;
                        break;
                    case 'pregnancy':
                        payload.active_date = new moment(active_date.value).format('YYYY-MM-DD');
                        payload.age_weeks = -700;
                        payload.age_months = 0;
                        payload.pregnancy_weeks = pregnancy_weeks.value;
                        payload.date_required = date_required.value ? 1 : 0;
                        payload.calendar_date = new moment(calendar_date.value).format('YYYY-MM-DD');
                        break;
                    default:
                        break;
                }
            } else {
                payload.active_date = new moment(active_date.value).format('YYYY-MM-DD');
                payload.age_weeks = currentMilestone.age_weeks;
                payload.age_months = 0;
            }

            const formData = new FormData();
            Object.keys(payload).forEach(data => {
                if (payload[data] !== undefined) {
                    formData.append(data, payload[data]);
                }
            });
            this.props.updateMilestone(id, formData);
        }
    }

    handleImage(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            this.setState({
                image: {
                    ...this.state.image,
                    file,
                    error: false,
                    value: reader.result
                }
            }
            );
        }.bind(this);
    }

    handleTabChange(event, frameTab) {
        this.setState({ frameTab });
    }

    handleFrameChange(frameId) {
        this.setState({
            frame: {
                ...this.state.frame,
                value: frameId
            }
        });
    }

    handleChangeSwitch(event) {
        const property = event.target.name;
        const checked = event.target.checked;
        this.setState({
            [property]: { ...this.state[property], value: checked }
        });
    }

    renderMilestoneCard() {
        const { classes, messagesNotificationsList, frames } = this.props;
        const { title, description, image, type, message, frame } = this.state;
        let { frameTab } = this.state;
        const { id } = this.props.match.params;
        const messageOptions = [
            { value: 'None', displayName: 'None' },
            ...messagesNotificationsList.map(
                item => ({ value: item.id, displayName: item.description }))
        ];
        const groupedFrames = _.groupBy(frames, (frameObject) => frameObject.folder);
        const frameKeys = Object.keys(groupedFrames);
        if (_.size(frameKeys) > 0 && frameTab === '') {
            frameTab = _.head(frameKeys);
        }
        return (
            <Card className={classes.card}>
                <form onSubmit={this.handleSubmit}>
                    <CardMedia
                        className={classes.media}
                        image={image.value}
                        title={title.value}
                        style={{ backgroundSize: 'contain' }}
                    />
                    <CardContent>
                        <div className="row justify-content-center align-items-center" style={{ width: '100%', 'text-align': 'center', margin: 0 }}>
                            <input
                                accept="image/*"
                                className={classes.input}
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={this.handleImage}
                            />
                            <label htmlFor="raised-button-file">
                                {
                                    image.error &&
                                    <FormHelperText
                                        id="name-error-text"
                                        style={{
                                            textAlign: 'center',
                                            padding: 5,
                                            color: 'red',
                                        }}
                                    >
                                        Image required
                                        </FormHelperText>
                                }
                                <Button
                                    color="primary"
                                    variant="raised"
                                    component="span"
                                >
                                    Upload image
                                    </Button>
                            </label>
                        </div>
                        <TextField
                            id="title"
                            name="title"
                            label="Title"
                            value={title.value}
                            placeholder="Title"
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: true }}
                            error={title.value === ''}
                            fullWidth
                        />
                        <TextField
                            id="description"
                            name="description"
                            label="Description"
                            placeholder="Description"
                            value={description.value}
                            error={description.value === ''}
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: true }}
                            fullWidth
                        />
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel htmlFor="type">Type of Milestone</InputLabel>
                            <Select
                                native
                                value={type.value}
                                onChange={this.handleChange}
                                inputProps={{
                                    name: 'type',
                                    id: 'type',
                                }}
                            >
                                {
                                    type.options.map((option, index) =>
                                    (
                                        <option key={index} value={option.value}>
                                            {option.displayName}
                                        </option>
                                    )
                                    )
                                }
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel htmlFor="type">Message Notification</InputLabel>
                            <Select
                                native
                                value={message.value}
                                onChange={this.handleChange}
                                inputProps={{
                                    name: 'message',
                                    id: 'message',
                                }}
                            >
                                {
                                    messageOptions.map((option, index) =>
                                    (
                                        <option key={index} value={option.value}>
                                            {option.displayName}
                                        </option>
                                    )
                                    )
                                }
                            </Select>
                        </FormControl>
                        {
                            this.renderTypeInput()
                        }
                    </CardContent>
                    <CardActions>
                        <div className="row around-xs" style={{ width: '100%' }}>
                            <Button
                                size="medium"
                                color="primary"
                                type="submit"
                            >
                                {id !== 'new' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="medium"
                                onClick={() => this.props.history.push('/app/milestones')}
                            >
                                Cancel
                            </Button>
                            {
                                id !== 'new' &&
                                <Button
                                    size="medium"
                                    color="secondary"
                                    onClick={() => {
                                        if (window.confirm('Confirm required to delete this milestone')) {
                                            this.props.deleteMilestone(id);
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            }
                        </div>
                    </CardActions>
                </form>
            </Card>
        );
    }

    renderTypeInput() {
        const {
            type,
            age_weeks,
            age_months,
            active_date,
            pregnancy_weeks,
            date_required,
            calendar_date,
        } = this.state;
        let inputProps = {};
        if (type.value === 'holiday') {
            return (
                <TextField
                    label="Active Date"
                    placeholder="Active Date"
                    type="date"
                    inputProps={inputProps}
                    onChange={this.handleChangeDate}
                    error={!active_date.value}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    defaultValue={active_date.value}
                    name="active_date"
                />
            );
        } else if (type.value === 'weeks') {
            inputProps = {
                min: 1,
                max: 4,
                required: true,
            };
            return (
                <TextField
                    id="age_weeks"
                    name="age_weeks"
                    label="Weeks"
                    value={age_weeks.value}
                    placeholder="Weeks"
                    type="number"
                    inputProps={inputProps}
                    onChange={this.handleChange}
                    margin="normal"
                    error={age_weeks.value < 0}
                    fullWidth
                />
            );
        } else if (['months', 'years'].indexOf(type.value) !== -1) {
            let isValid = true;
            let label = 'Months';
            inputProps = {
                min: 1,
                max: 200,
                required: true,
            };
            if (type.value === 'years') {
                inputProps = {
                    min: 1,
                    max: 15,
                    required: true,
                };
                isValid = age_months.value >= 0;
                label = 'Years';
            } else {
                isValid = age_months.value >= 0;
            }
            return (
                <TextField
                    id="age_months"
                    name="age_months"
                    label={label}
                    value={age_months.value}
                    placeholder="Months"
                    type="number"
                    inputProps={inputProps}
                    onChange={this.handleChange}
                    margin="normal"
                    error={!isValid}
                    fullWidth
                />
            );
        } else if (type.value === 'pregnancy') {
            inputProps = {
                min: 1,
                max: 45,
                required: false,
            };
            return (
                <div>
                    <TextField
                        id="pregnancy_weeks"
                        name="pregnancy_weeks"
                        label="Pregnancy Weeks (optional)"
                        value={pregnancy_weeks.value}
                        placeholder="Pregnancy Weeks (optional)"
                        type="number"
                        inputProps={inputProps}
                        onChange={this.handleChange}
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        error={pregnancy_weeks.value < 0}
                        fullWidth
                    />
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={date_required.value}
                                    onChange={this.handleChangeSwitch}
                                    value="date_required"
                                    name='date_required'
                                />
                            }
                            label="Date Required?"
                        />
                    </FormGroup>
                    <TextField
                        label="Calendar Date"
                        placeholder="Calendar Date"
                        type="date"
                        name="calendar_date"
                        inputProps={inputProps}
                        onChange={this.handleChangeDate}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        defaultValue={(this.props.currentMilestone.active_date == '0000-00-00' || this.props.currentMilestone.active_date == undefined) ? '0000-00-00' : calendar_date.value}
                    />
                </div>
            );
        }
        return null;
    }

    render() {
        const { currentMilestone, loading } = this.props;
        const { id } = this.props.match.params;
        return (
            <AuxHoc>
                 <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                {
                    loading ?
                        <LoaderModal loading={loading} /> :
                        <div className="row around-xs" style={{justifyContent: 'space-around' }}>
                            <div className="col-12 col-md-6">
                                <div className="box">
                                    {
                                        currentMilestone &&
                                        this.renderMilestoneCard()
                                    }
                                </div>
                            </div>
                            {
                                id !== 'new' &&
                                <div className="col-12 col-md-6">
                                    <div className="box">
                                        <Questions id={id} />
                                    </div>
                                </div>
                            }
                        </div>
                }
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ milestones, messagesNotifications }) => {
    const { error, loading, currentMilestone, frames } = milestones;
    const { messagesNotificationsList } = messagesNotifications;

    return { error, loading, currentMilestone, frames, messagesNotificationsList };
};

export default connect(mapStateToProps,
    {
        retrieveMilestone,
        retrieveMessagesNotifications,
        createMilestone,
        updateMilestone,
        deleteMilestone,
        retrieveFrames
    }
)(withStyles(styles)(Milestone));
