// Checked
import axios from "../axios-admin";
import env from "../js/env";
import { handleError } from "../js/helpers";
import history from "./../history";
import {
  SET_LOADING,
  SET_ERROR,
  RETRIEVE_USERS_SUCCESS,
  RETRIEVE_USER_SUCCESS
} from "./types";

const ROOT_URL = "/api";

export function retrieveUsers(
  page,
  search,
  plusUsers,
  order = null,
  orderBy = null
) {
  const currentPage = page || 1;
  const searchWord = search || null;
  const encodedSearchWord = encodeURIComponent(searchWord)
  const plusStatus = plusUsers || false;
  let url = "";
  if (searchWord === null) {
    url = `${ROOT_URL}/admin/users?limit=${env.GENERAL.paginator_limit}&lang=${env.GENERAL.lang}&page=${currentPage}&plus=${plusStatus}`;
  } else {
    url = `${ROOT_URL}/admin/users?limit=${env.GENERAL.paginator_limit}&lang=${env.GENERAL.lang}&page=${currentPage}&plus=${plusStatus}&search=${encodedSearchWord}`;
  }

  url = `${url}&orderBy=${orderBy || "created_at"}&order=${order || "desc"}`;

  return function(dispatch) {
    dispatch({ type: SET_LOADING });
    axios({
      method: "get",
      url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/x.babypage.v1.0.0+json",
        Authorization: localStorage.getItem("token")
      }
    })
      .then(response => {
        const paginatorResponse = response.data.data;
        dispatch(retrieveSuccess(paginatorResponse, RETRIEVE_USERS_SUCCESS));
      })
      .catch(error => {
        handleError(error);
        if (error !== undefined) {
          if (error.response !== undefined) {
            dispatch(retrieveFail(error.response.data.message));
          }
        }
      });
  };
}

export function retrieveUserById(id) {
  return function(dispatch) {
    dispatch({ type: SET_LOADING });
    axios({
      method: "get",
      url: `${ROOT_URL}/get-user/${id}?lang=${env.GENERAL.lang}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/x.babypage.v1.0.0+json",
        Authorization: localStorage.getItem("token")
      }
    })
      .then(response => {
        const userResponse = response.data.data;
        dispatch(retrieveSuccess(userResponse, RETRIEVE_USER_SUCCESS));
        history.push(`/app/users/${id}`);
      })
      .catch(error => {
        handleError(error);
        if (error !== undefined) {
          if (error.response !== undefined) {
            dispatch(retrieveFail(error.response.data.message));
          }
        }
      });
  };
}

function retrieveSuccess(object, action) {
  return {
    type: action,
    payload: object
  };
}

function retrieveFail(error) {
  return {
    type: SET_ERROR,
    payload: error
  };
}
