// Checked
import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from 'material-ui/Switch';
import { connect } from 'react-redux';
// eslint-disable-next-line max-len
import {
    deleteCustomNotification,
    retrieveCustomNotification,
    sendCustomNotification,
    updateCustomNotification
} from '../actions';
import Card, { CardActions, CardContent, CardMedia } from 'material-ui/Card';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let container;

const styles = theme => ({
    fab: {
        margin: theme.spacing.unit * 2,
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    formControlSelect: {
        marginTop: '16px',
        marginBottom: '8px',
    },
    chip: {
        margin: theme.spacing.unit,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
    card: {
        maxWidth: '100%',
    },
    media: {
        height: 0,
        paddingTop: '50%',
        margin: '1rem auto',
    }
});

class NotificationsEdit extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.handleToggle = this.handleToggle.bind(this);

        this.state = {
            title: {
                value: '',
                required: true,
                error: false,
            },
            notificationMessage: {
                value: '',
                required: true,
                error: false,
            },
            usePush: {
                value: true,
                required: true,
                error: false,
            },
            subjectEmail: {
                value: '',
                required: true,
                error: false,
            },
            emailNotificationMessage: {
                value: '',
                required: true,
                error: false,
            },
            useEmail: {
                value: true,
                required: true,
                error: false,
            },
            gender: {
                value: 'all',
                required: true,
                error: false,
            },
            emails: [],
            email: {
                value: '',
                required: false,
                error: false,
            },
            member: {
                value: 'all',
                required: true,
                error: false,
            },
            hasPurchasedBooks: {
                value: 'all',
                required: true,
                error: false,
            },
            dateSchedule: {
                value: new moment().format('YYYY-MM-DDTHH:mm'),
                required: true,
                error: false,
            },
            numberOfBabyPagesFrom: {
                value: 0,
                required: false,
                error: false,
            },
            numberOfBabyPagesTo: {
                value: 0,
                required: false,
                error: false,
            },
            hasBooks: {
                value: 'all',
                required: true,
                error: false,
            },
            hasSomethingInTheCart: {
                value: 'all',
                required: true,
                error: false,
            },
            hasMultipleChildren: {
                value: 'all',
                required: true,
                error: false,
            },
            fromAge: {
                value: '',
                required: false,
                error: false,
            },
            fromAgeType: {
                value: 'Years',
                required: false,
                error: false,
            },
            toAge: {
                value: '',
                required: false,
                error: false,
            },
            toAgeType: {
                value: 'Years',
                required: false,
                error: false,
            },
            loginFrom: {
                value: '',
                required: false,
                error: false,
            },
            loginTo: {
                value: '',
                required: false,
                error: false,
            },
            signupFrom: {
                value: '',
                required: false,
                error: false,
            },
            signupTo: {
                value: '',
                required: false,
                error: false,
            },
            sendNotifications: false,
            image: {
                value: '/images/no-image-icon.png',
                file: null,
                required: true,
                error: false,
                wasUpdated: false
            },
            DST: false,
        };
    }

    handleToggle() {
        this.setState(state => ({...state, DST: !state.DST}))
    }

    componentDidMount() {
        const { currentCustomNotification } = this.props;
        const { id } = this.props.match.params;

        if (id !== 'new' && Object.keys(currentCustomNotification)?.length === 0) {
            this.props.retrieveCustomNotification(id);
        } else {
            this.setState({
                title: {
                    value: currentCustomNotification.title,
                    required: true,
                    error: false,
                },
                notificationMessage: {
                    value: currentCustomNotification.push_message,
                    required: true,
                    error: false,
                },
                usePush: {
                    value: currentCustomNotification.push_message === null ? false : currentCustomNotification.push_message?.length > 0,
                    required: true,
                    error: false,
                },
                subjectEmail: {
                    value: currentCustomNotification.subject_email,
                    required: true,
                    error: false,
                },
                emailNotificationMessage: {
                    value: currentCustomNotification.email_message,
                    required: true,
                    error: false,
                },
                useEmail: {
                    value: currentCustomNotification.email_message === null ? false : currentCustomNotification.email_message?.length > 0,
                    required: true,
                    error: false,
                },
                gender: {
                    value: currentCustomNotification.filters.gender,
                    required: true,
                    error: false,
                },
                emails: currentCustomNotification.emails.filter((key) => key?.length) || [],
                member: {
                    value: currentCustomNotification.filters.member,
                    required: true,
                    error: false,
                },
                hasPurchasedBooks: {
                    value: currentCustomNotification.filters.hasPurchasedBooks,
                    required: true,
                    error: false,
                },
                dateSchedule: {
                    value: this.state.DST ? moment(currentCustomNotification.filters.dateSchedule).subtract(7,"hours").format('YYYY-MM-DDTHH:mm') : moment(currentCustomNotification.filters.dateSchedule).subtract(6,"hours").format('YYYY-MM-DDTHH:mm'),
                    required: true,
                    error: false,
                },
                numberOfBabyPagesFrom: {
                    value: currentCustomNotification.filters.numberOfBabyPagesFrom || 0,
                    required: false,
                    error: false,
                },
                numberOfBabyPagesTo: {
                    value: currentCustomNotification.filters.numberOfBabyPagesTo || 0,
                    required: false,
                    error: false,
                },
                hasBooks: {
                    value: currentCustomNotification.filters.hasBooks,
                    required: true,
                    error: false,
                },
                hasSomethingInTheCart: {
                    value: currentCustomNotification.filters.hasSomethingInTheCart,
                    required: true,
                    error: false,
                },
                hasMultipleChildren: {
                    value: currentCustomNotification.filters.hasMultipleChildren,
                    required: true,
                    error: false,
                },
                fromAge: {
                    value: currentCustomNotification.filters.fromAge === null ? '' : currentCustomNotification.filters.fromAge,
                    required: false,
                    error: false,
                },
                fromAgeType: {
                    value: currentCustomNotification.filters.fromAgeType,
                    required: false,
                    error: false,
                },
                toAge: {
                    value: currentCustomNotification.filters.toAge === null ? '' : currentCustomNotification.filters.toAge,
                    required: false,
                    error: false,
                },
                toAgeType: {
                    value: currentCustomNotification.filters.toAgeType,
                    required: false,
                    error: false,
                },
                loginFrom: {
                    value: currentCustomNotification.filters.loginFrom === null || currentCustomNotification.filters.loginFrom === 'null'
                        ? '' : currentCustomNotification.filters.loginFrom,
                    required: false,
                    error: false,
                },
                loginTo: {
                    value: currentCustomNotification.filters.loginTo === null || currentCustomNotification.filters.loginTo === 'null'
                        ? '' : currentCustomNotification.filters.loginTo,
                    required: false,
                    error: false,
                },
                signupFrom: {
                    value: currentCustomNotification.filters.signupFrom === null || currentCustomNotification.filters.signupFrom === 'null'
                        ? '' : currentCustomNotification.filters.signupFrom,
                    required: false,
                    error: false,
                },
                signupTo: {
                    value: currentCustomNotification.filters.signupTo === null || currentCustomNotification.filters.signupTo === 'null'
                        ? '' : currentCustomNotification.filters.signupTo,
                    required: false,
                    error: false,
                },
                sendNotifications: false,
                image: {
                    value: this.props.currentCustomNotification.url_image ? this.props.currentCustomNotification.url_image : '/images/no-image-icon.png',
                    file: null,
                    required: true,
                    error: false,
                    wasUpdated: false
                },
                DST: currentCustomNotification.filters.dst === "true" ? true : false
            });

            // let imgUrl = '';
            // if (id === 'new') {
            //     imgUrl = '/images/no-image-icon.png';
            // } else {
            //     imgUrl = currentCustomNotification.email_image_url == null ?
            //         '/images/no-image-icon.png'
            //         : currentCustomNotification.email_image_url;
            // }
            // this.setState({
            //     image: {
            //         ...this.state.image,
            //         value: imgUrl
            //     }
            // });
        }
    }

    componentDidUpdate(prevProps) {
        const { currentCustomNotification } = this.props;
        // const { id } = this.props.match.params;
        if (currentCustomNotification !== prevProps.currentCustomNotification) {
            const isDST = currentCustomNotification.filters.dst === "true" ? true : false;
            this.setState({
                title: {
                    value: currentCustomNotification.title,
                    required: true,
                    error: false,
                },
                notificationMessage: {
                    value: currentCustomNotification.push_message,
                    required: true,
                    error: false,
                },
                usePush: {
                    value: currentCustomNotification.push_message === null ? false : currentCustomNotification.push_message?.length > 0,
                    required: true,
                    error: false,
                },
                subjectEmail: {
                    value: currentCustomNotification.subject_email,
                    required: true,
                    error: false,
                },
                emailNotificationMessage: {
                    value: currentCustomNotification.email_message,
                    required: true,
                    error: false,
                },
                useEmail: {
                    value: currentCustomNotification.email_message?.length > 0,
                    required: true,
                    error: false,
                },
                gender: {
                    value: currentCustomNotification.filters.gender,
                    required: true,
                    error: false,
                },
                emails: currentCustomNotification.emails.filter((key) => key?.length) || [],
                member: {
                    value: currentCustomNotification.filters.member,
                    required: true,
                    error: false,
                },
                hasPurchasedBooks: {
                    value: currentCustomNotification.filters.hasPurchasedBooks,
                    required: true,
                    error: false,
                },
                dateSchedule: {
                    value: isDST ? moment(currentCustomNotification.filters.dateSchedule).subtract(7,"hours").format('YYYY-MM-DDTHH:mm') : moment(currentCustomNotification.filters.dateSchedule).subtract(6,"hours").format('YYYY-MM-DDTHH:mm'),
                    required: true,
                    error: false,
                },
                numberOfBabyPagesFrom: {
                    value: currentCustomNotification.filters.numberOfBabyPagesFrom || 0,
                    required: false,
                    error: false,
                },
                numberOfBabyPagesTo: {
                    value: currentCustomNotification.filters.numberOfBabyPagesTo || 0,
                    required: false,
                    error: false,
                },
                hasBooks: {
                    value: currentCustomNotification.filters.hasBooks,
                    required: true,
                    error: false,
                },
                hasSomethingInTheCart: {
                    value: currentCustomNotification.filters.hasSomethingInTheCart,
                    required: true,
                    error: false,
                },
                hasMultipleChildren: {
                    value: currentCustomNotification.filters.hasMultipleChildren,
                    required: true,
                    error: false,
                },
                fromAge: {
                    value: currentCustomNotification.filters.fromAge === null ? '' : currentCustomNotification.filters.fromAge,
                    required: false,
                    error: false,
                },
                fromAgeType: {
                    value: currentCustomNotification.filters.fromAgeType,
                    required: false,
                    error: false,
                },
                toAge: {
                    value: currentCustomNotification.filters.toAge === null ? '' : currentCustomNotification.filters.toAge,
                    required: false,
                    error: false,
                },
                toAgeType: {
                    value: currentCustomNotification.filters.toAgeType,
                    required: false,
                    error: false,
                },
                loginFrom: {
                    value: currentCustomNotification.filters.loginFrom === null || currentCustomNotification.filters.loginFrom === 'null'
                        ? '' : currentCustomNotification.filters.loginFrom,
                    required: false,
                    error: false,
                },
                loginTo: {
                    value: currentCustomNotification.filters.loginTo === null || currentCustomNotification.filters.loginTo === 'null'
                        ? '' : currentCustomNotification.filters.loginTo,
                    required: false,
                    error: false,
                },
                signupFrom: {
                    value: currentCustomNotification.filters.signupFrom === null || currentCustomNotification.filters.signupFrom === 'null'
                        ? '' : currentCustomNotification.filters.signupFrom,
                    required: false,
                    error: false,
                },
                signupTo: {
                    value: currentCustomNotification.filters.signupTo === null || currentCustomNotification.filters.signupTo === 'null'
                        ? '' : currentCustomNotification.filters.signupTo,
                    required: false,
                    error: false,
                },
                sendNotifications: false,
                image: {
                    value: this.props.currentCustomNotification.url_image ? this.props.currentCustomNotification.url_image : '/images/no-image-icon.png',
                    file: null,
                    required: true,
                    error: false,
                    wasUpdated: false
                },
                DST: currentCustomNotification.filters.dst === "true" ? true : false
            });

            // let imgUrl = '';
            // if (id === 'new') {
            //     imgUrl = '/images/no-image-icon.png';
            // } else {
            //     imgUrl = currentCustomNotification.email_image_url == null ?
            //         `/owls/${currentCustomNotification.image}`
            //         : currentCustomNotification.email_image_url;
            // }

            // this.setState({
            //     image: {
            //         ...this.state.image,
            //         value: imgUrl
            //     }
            // });
        }
    }

    handleChange(event) {
        const property = event.target.name;
        let value = event.target.value;

        if (property === 'email') {
            value = value.trim();
        }

        if (property === 'usePush' || property === 'useEmail') {
            if (property === 'usePush' && !this.state.useEmail.value) {
                this.setState({
                    useEmail: { ...this.state.useEmail, value: true },
                });
            }

            if (property === 'useEmail' && !this.state.usePush.value) {
                this.setState({
                    usePush: { ...this.state.usePush, value: true },
                });
            }

            value = event.target.checked;
        }
        this.setState({
            [property]: { ...this.state[property], value },
        });
    }

    handleChangeDate(date) {
        const { dateSchedule } = this.state;
        this.setState({
            dateSchedule: { ...dateSchedule, value: moment(date.target.value).format('YYYY-MM-DDTHH:mm') },
        });
    }

    handleImage(event) {
        const file = event.target.files[0];
        const isValidImage = file.type.split("/")[0] === "image";
        
        if (isValidImage) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                this.setState({
                    image: {
                        ...this.state.image,
                        file,
                        error: false,
                        value: reader.result,
                        wasUpdated: true
                    }
                });
            }.bind(this);
          } else {
            toast.error ('Please select a valid image.');
          }
        
    }

    handleSubmit(event) {
        event.preventDefault();
        const {
            title,
            notificationMessage,
            usePush,
            subjectEmail,
            emailNotificationMessage,
            useEmail,
            gender,
            emails,
            member,
            hasPurchasedBooks,
            dateSchedule,
            numberOfBabyPagesFrom,
            numberOfBabyPagesTo,
            hasBooks,
            hasSomethingInTheCart,
            hasMultipleChildren,
            fromAge,
            fromAgeType,
            toAge,
            toAgeType,
            loginFrom,
            loginTo,
            signupFrom,
            signupTo,
            sendNotifications,
            image,
            DST
        } = this.state;
        const { id } = this.props.match.params;

        if (!emailNotificationMessage && !notificationMessage) {
            alert('You can not send a custom message with no content');

            return false;
        }

        const payload = {
            title: title.value,
            message: usePush.value ? notificationMessage.value : '',
            subjectEmail: useEmail.value ? subjectEmail.value : '',
            emailMessage: useEmail.value ? emailNotificationMessage.value : '',
            gender: gender.value,
            emails,
            member: member.value,
            hasPurchasedBooks: hasPurchasedBooks.value,
            dateSchedule: DST ? moment(dateSchedule.value).add(7,"hours").format('YYYY-MM-DD HH:mm:ss') : moment(dateSchedule.value).add(6,"hours").format('YYYY-MM-DD HH:mm:ss'),
            numberOfBabyPagesFrom: numberOfBabyPagesFrom.value,
            numberOfBabyPagesTo: numberOfBabyPagesTo.value,
            hasBooks: hasBooks.value,
            hasSomethingInTheCart: hasSomethingInTheCart.value,
            hasMultipleChildren: hasMultipleChildren.value,
            fromAge: fromAge.value,
            fromAgeType: fromAgeType.value,
            toAge: toAge.value,
            toAgeType: toAgeType.value,
            loginFrom: loginFrom.value !== '' ? moment(loginFrom.value).format('YYYY-MM-DD') : '2016-12-31',
            loginTo: loginTo.value !== '' ? moment(loginTo.value).format('YYYY-MM-DD') : '2016-12-31',
            signupFrom: signupFrom.value !== '' ? moment(signupFrom.value).format('YYYY-MM-DD') : '2016-12-31',
            signupTo: signupTo.value !== '' ? moment(signupTo.value).format('YYYY-MM-DD') : '2016-12-31',
            image: image.file,
            dst: DST,
        };

        const formData = new FormData();
        Object.keys(payload).forEach(data => {
            if (payload[data] !== undefined) {
                formData.append(data, payload[data]);
            }
        });

        if (sendNotifications) {
            // Avoiding missing img in email msg issue
            if (this.state.image.wasUpdated) {
                this.setState({
                    sendNotifications: false
                });
                alert('Since you changed the notification image you need to click update button before sending it');
                return;
            }

            const result = window.confirm('Confirm required to send this notification');
            if (!result) {
                return false;
            }

            formData.append('image_url', image.value);
            this.props.sendCustomNotification(formData)
                .then(() => {
                    toast.success ('The notifications were queued successfully');
                    this.setState({
                        sendNotifications: false
                    });
                })
                .catch((error) => {
                    toast.error (error.response.data.message);
                });
        } else {
            this.props.updateCustomNotification(id, formData)
                .then(() => {
                    
                    toast.success ('The notifications was updated successfully');
                })
                .catch((error) => {
                    toast.error (error.response.data.message);
                });
        }
    }

    render() {
        const { classes } = this.props;
        const { dateSchedule, emails, image, DST } = this.state;
        const todayDate = new moment().format('YYYY-MM-DD');
        const todayDateTime = new moment().subtract(6,"hours").format('YYYY-MM-DD hh:mm');
        const { id } = this.props.match.params;
        return (
            <AuxHoc>
                <ToastContainer
                    position='top-right'
                    autoClose={3000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-8">
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography gutterBottom variant="headline" component="h2">
                                        Custom Notification
                                    </Typography>

                                    <CardMedia
                                        className={classes.media}
                                        image={image.value}
                                        style={{ backgroundSize: 'contain' }}
                                    />
                                    <div className="row justify-content-center align-items-center">
                                        <div className="row justify-content-center align-items-center" style={{ width: '100%' }}>
                                            <input
                                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                                className={classes.input}
                                                style={{ display: 'none' }}
                                                id="raised-button-file"
                                                type="file"
                                                onChange={this.handleImage}
                                            />
                                            <label htmlFor="raised-button-file">
                                                <Button
                                                    color="primary"
                                                    variant="raised"
                                                    component="span"
                                                >
                                                    Upload image
                                                </Button>
                                            </label>
                                        </div>

                                        <div className="col-12">
                                            <div className="box">
                                                <TextField
                                                    id="title"
                                                    name="title"
                                                    label="Title"
                                                    value={this.state.title.value}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.title.required,
                                                        maxLength: 100
                                                    }}
                                                    autoFocus
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.usePush.value}
                                                        onChange={this.handleChange}
                                                        value='usePush'
                                                        name='usePush'
                                                    />
                                                }
                                                label="Use Push Notification"
                                            />
                                        </div>
                                        <div className="col-9">
                                            <div className="box">
                                                <TextField
                                                    id="notificationMessage"
                                                    name="notificationMessage"
                                                    label="Push Notification Message"
                                                    value={this.state.notificationMessage.value}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.notificationMessage.required,
                                                        maxLength: 150
                                                    }}
                                                    disabled={!this.state.usePush.value}
                                                    fullWidth
                                                    multiline
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.useEmail.value}
                                                        onChange={this.handleChange}
                                                        value='useEmail'
                                                        name='useEmail'
                                                    />
                                                }
                                                label="Use Email Notification"
                                            />
                                        </div>
                                        <div className="col-9">
                                            <div className="box">
                                                <TextField
                                                    id="subjectEmail"
                                                    name="subjectEmail"
                                                    label="Email Subject"
                                                    value={this.state.subjectEmail.value}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.subjectEmail.required,
                                                        maxLength: 100
                                                    }}
                                                    disabled={!this.state.useEmail.value}
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="box">
                                                <TextField
                                                    id="emailNotificationMessage"
                                                    name="emailNotificationMessage"
                                                    label="Email Notification Message"
                                                    value={this.state.emailNotificationMessage.value}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.emailNotificationMessage.required,
                                                        maxLength: 5000
                                                    }}
                                                    disabled={!this.state.useEmail.value}
                                                    fullWidth
                                                    multiline
                                                />
                                            </div>
                                        </div>
                                        <div className="col-9">
                                            <div className="box">
                                                <TextField
                                                    id="notification-time"
                                                    name="notification-time"
                                                    label={`Schedule (Mountain Time) ${this.state.DST ? "Daylight Savings" : "Regular schedule"}`}
                                                    value={dateSchedule.value}
                                                    onChange={this.handleChangeDate}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: dateSchedule.required,
                                                        min: todayDateTime,
                                                    }}
                                                    fullWidth
                                                    type="datetime-local"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.DST}
                                                    onChange={this.handleToggle}
                                                    value='Use daylight savings time.'
                                                    name='Use daylight savings time.'
                                                />
                                                }
                                                label="Use daylight savings time."
                                            />
                                       </div>
                                        <div className="col-6">
                                            <div className="box">
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormLabel component="legend">Members</FormLabel>
                                                    <RadioGroup
                                                        aria-label="member"
                                                        name="member"
                                                        value={this.state.member.value}
                                                        onChange={this.handleChange}
                                                    >
                                                        <FormControlLabel value="all" control={<Radio />} label="All" />
                                                        <FormControlLabel
                                                            value="free" control={<Radio />}
                                                            label="Free Members"
                                                        />
                                                        <FormControlLabel
                                                            value="plus" control={<Radio />}
                                                            label="Plus Members"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="box">
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormLabel component="legend">Has Purchased a Book</FormLabel>
                                                    <RadioGroup
                                                        aria-label="hasPurchasedBooks"
                                                        name="hasPurchasedBooks"
                                                        value={this.state.hasPurchasedBooks.value}
                                                        onChange={this.handleChange}
                                                    >
                                                        <FormControlLabel value="all" control={<Radio />} label="n/a" />
                                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ marginTop: '16px' }}>
                                            <div className="box">
                                                <FormLabel component="legend">Number of BabyPages</FormLabel>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="box">
                                                <TextField
                                                    id="numberOfBabyPagesFrom"
                                                    name="numberOfBabyPagesFrom"
                                                    label="From"
                                                    value={this.state.numberOfBabyPagesFrom.value}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.numberOfBabyPagesFrom.required,
                                                        min: 0
                                                    }}
                                                    fullWidth
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="box">
                                                <TextField
                                                    id="numberOfBabyPagesTo"
                                                    name="numberOfBabyPagesTo"
                                                    label="To"
                                                    value={this.state.numberOfBabyPagesTo.value}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.numberOfBabyPagesTo.required,
                                                        min: 0
                                                    }}
                                                    fullWidth
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="box">
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormLabel component="legend">Has Books</FormLabel>
                                                    <RadioGroup
                                                        aria-label="hasBooks"
                                                        name="hasBooks"
                                                        value={this.state.hasBooks.value}
                                                        onChange={this.handleChange}
                                                    >
                                                        <FormControlLabel value="all" control={<Radio />} label="n/a" />
                                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="box">
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormLabel component="legend">Has Something In The Cart</FormLabel>
                                                    <RadioGroup
                                                        aria-label="hasSomethingInTheCart"
                                                        name="hasSomethingInTheCart"
                                                        value={this.state.hasSomethingInTheCart.value}
                                                        onChange={this.handleChange}
                                                    >
                                                        <FormControlLabel value="all" control={<Radio />} label="n/a" />
                                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="box">
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormLabel component="legend">Has Multiple Children</FormLabel>
                                                    <RadioGroup
                                                        aria-label="hasMultipleChildren"
                                                        name="hasMultipleChildren"
                                                        value={this.state.hasMultipleChildren.value}
                                                        onChange={this.handleChange}
                                                        style={{ display: 'inline' }}
                                                    >
                                                        <FormControlLabel value="all" control={<Radio />} label="n/a" />
                                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ marginTop: '16px' }}>
                                            <div className="box">
                                                <FormLabel component="legend">Age of children</FormLabel>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="box">
                                                <FormControl className={classes.formControlSelect}>
                                                    <InputLabel htmlFor="fromAgeType">Unit</InputLabel>
                                                    <Select
                                                        native
                                                        value={this.state.fromAgeType.value}
                                                        onChange={this.handleChange}
                                                        inputProps={{
                                                            name: 'fromAgeType',
                                                            id: 'fromAgeType',
                                                        }}
                                                        
                                                    >
                                                        <option key="days" value="Days">
                                                            Days
                                                        </option>
                                                        <option key="years" value="Years">
                                                            Years
                                                        </option>
                                                        <option key="months" value="Months">
                                                            Months
                                                        </option>
                                                        <option key="weeks" value="Weeks">
                                                            Weeks
                                                        </option>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="box">
                                                <TextField
                                                    id="fromAge"
                                                    name="fromAge"
                                                    label="From"
                                                    value={this.state.fromAge.value}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        let max = this.state.fromAgeType.value === "Weeks" ? 3 : 120
                                                        const min = 1;

                                                        if(value > max) {
                                                            this.setState({fromAge: {
                                                                ...this.state.fromAge,
                                                                value: max
                                                            }})
                                                            if(this.state.fromAgeType.value === "Weeks") toast.warning("The maximum value for weeks is 3. If you want to use a value higher than 3 use months or years.")
                                                        }else if(value < min && value !== ''){
                                                            this.setState({fromAge: {
                                                                ...this.state.fromAge,
                                                                value: min
                                                            }})
                                                            toast.warning(`The minimum number of ${this.state.fromAgeType.value} is 1`);
                                                        } else {
                                                            this.handleChange(e)
                                                        } 
                                                    }}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.fromAge.required,
                                                        min: 0,
                                                        max: this.state.fromAgeType.value === "Weeks" ? 3 : 120
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type="number"
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="box">
                                                <FormControl className={classes.formControlSelect}>
                                                    <InputLabel htmlFor="toAgeType">Unit</InputLabel>
                                                    <Select
                                                        native
                                                        value={this.state.toAgeType.value}
                                                        onChange={this.handleChange}
                                                        inputProps={{
                                                            name: 'toAgeType',
                                                            id: 'toAgeType',
                                                        }}
                                                    >
                                                        <option key="days" value="Days">
                                                            Days
                                                        </option>
                                                        <option key="years" value="Years">
                                                            Years
                                                        </option>
                                                        <option key="months" value="Months">
                                                            Months
                                                        </option>
                                                        <option key="weeks" value="Weeks">
                                                            Weeks
                                                        </option>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="box">
                                                <TextField
                                                    id="toAge"
                                                    name="toAge"
                                                    label="To"
                                                    value={this.state.toAge.value}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        let max = this.state.toAgeType.value === "Weeks" ? 3 :
                                                                  this.state.toAgeType.value === "Days" ? 6 : 120
                                                        const min = 1;
                                                        if(value > max) {
                                                            this.setState({toAge: {
                                                                ...this.state.toAge,
                                                                value: max
                                                            }})
                                                            if (this.state.toAgeType.value === "Weeks") {
                                                                toast.warning("The maximum value for weeks is 3. If you want to use a value higher than 3 use months or years.")
                                                            } else if ( this.state.toAgeType.value === "Days") {
                                                                toast.warning("The maximum value for days is 6. If you want to use a value higher than 6 use weeks or months.")
                                                            }
                                                        } else if(value < min && value !== ''){
                                                            this.setState({toAge: {
                                                                ...this.state.toAge,
                                                                value: min
                                                            }})
                                                            toast.warning(`The minimum number of ${this.state.toAgeType.value} is 1`);
                                                        } else {
                                                            this.handleChange(e)
                                                        } 
                                                    }}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.toAge.required,
                                                        min: 0,
                                                        max: this.state.toAgeType.value === "Weeks" ? 3 : 120
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type="number"
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ marginTop: '16px' }}>
                                            <div className="box">
                                                <FormLabel component="legend">Users Who Have Been On The App in this
                                                    range</FormLabel>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="box">
                                                <TextField
                                                    id="loginFrom"
                                                    name="loginFrom"
                                                    label="From"
                                                    value={this.state.loginFrom.value}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.loginFrom.required,
                                                        min: '2017-01-01',
                                                        max: todayDate,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type="date"
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="box">
                                                <TextField
                                                    id="loginTo"
                                                    name="loginTo"
                                                    label="To"
                                                    value={this.state.loginTo.value}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.loginTo.required,
                                                        min: '2017-01-01',
                                                        max: todayDate,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    type="date"
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ marginTop: '16px' }}>
                                            <div className="box">
                                                <FormLabel component="legend">Users Who Signed Up in this
                                                    range</FormLabel>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="box">
                                                <TextField
                                                    id="signupFrom"
                                                    name="signupFrom"
                                                    label="From"
                                                    value={this.state.signupFrom.value}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.signupFrom.required,
                                                        min: '2017-01-01',
                                                        max: todayDate,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type="date"
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="box">
                                                <TextField
                                                    id="signupTo"
                                                    name="signupTo"
                                                    label="To"
                                                    value={this.state.signupTo.value}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.signupTo.required,
                                                        min: '2017-01-01',
                                                        max: todayDate,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type="date"
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="box">
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormLabel component="legend">Children's Gender</FormLabel>
                                                    <RadioGroup
                                                        aria-label="gender"
                                                        name="gender"
                                                        value={this.state.gender.value}
                                                        onChange={this.handleChange}
                                                        style={{ display: 'inline' }}
                                                    >
                                                        <FormControlLabel value="all" control={<Radio />} label="n/a" />
                                                        <FormControlLabel
                                                            value="female" control={<Radio />}
                                                            label="Female"
                                                        />
                                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-12 row start-xs">
                                            <div className="col-12">
                                                <div className="box">
                                                    {emails.map((element, index) => (
                                                        <Chip
                                                            key={index}
                                                            avatar={
                                                                <Avatar>
                                                                    <FaceIcon />
                                                                </Avatar>
                                                            }
                                                            label={element}
                                                            onDelete={() => {
                                                                const filteredEmails = emails.filter(item => item !== element);
                                                                this.setState({ emails: filteredEmails });
                                                            }}
                                                            className={classes.chip}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 row around-xs">
                                            <div className="col-8">
                                                <div className="box">
                                                    <TextField
                                                        id="email"
                                                        name="email"
                                                        label="Add an email"
                                                        type="email"
                                                        value={this.state.email.value}
                                                        onChange={this.handleChange}
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4 start-xs">
                                                <div className="box">
                                                    <Button
                                                        style={{ marginTop: 16, marginBottom: 8 }}
                                                        type="button"
                                                        // variant="raised"
                                                        onClick={() => {
                                                            if (this.state.email.value !== '') {
                                                                const { email } = this.state;
                                                                if (!emails.includes(email.value)) {
                                                                    if(email.value.includes(',')) {
                                                                        const splittedEmails = email.value.split(',')
                                                                        const trimmedEmails = splittedEmails.map((email) => {
                                                                            return email.trim();
                                                                        })
                                                                        emails.push(...trimmedEmails);
                                                                    } else {
                                                                        emails.push(email.value);
                                                                    }
                                                                }
                                                                this.setState({
                                                                    emails,
                                                                    email: {
                                                                        ...email,
                                                                        value: ''
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        Add
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <div className="row around-xs" style={{ width: '100%' }}>
                                        <Button
                                            size="medium"
                                            color="primary"
                                            type="submit"
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            size="medium"
                                            type="submit"
                                            onClick={() => {
                                                this.setState({
                                                    sendNotifications: true
                                                });
                                            }}
                                        >
                                            Send
                                        </Button>
                                        <Button
                                            size="medium"
                                            color="secondary"
                                            onClick={() => {
                                                if (window.confirm('Confirm required to delete this Custom Notification')) {
                                                    this.props.deleteCustomNotification(id)
                                                        .catch((error) => {                                                            
                                                            toast.error (error.response.data.message);
                                                        });
                                                }
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </CardActions>
                            </Card>

                        </div>
                    </div>
                </form>
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ customNotifications }) => {
    const { error, loading, currentCustomNotification, DST } = customNotifications;

    return { error, loading, currentCustomNotification, DST };
};

export default connect(mapStateToProps,
    {
        retrieveCustomNotification,
        updateCustomNotification,
        deleteCustomNotification,
        sendCustomNotification
    }
)(withStyles(styles)(NotificationsEdit));
