// Checked
import {
    SET_LOADING,
    SET_ERROR,
    RETRIEVE_MILESTONES_SUCCESS,
    RETRIEVE_MILESTONE_SUCCESS,
    RETRIEVE_FILTERED_MILESTONES_SUCCESS,
    CREATE_MILESTONE_SUCCESS,
    UPDATE_MILESTONE_SUCCESS,
    DELETE_MILESTONE_SUCCESS,
    DUPLICATE_MILESTONE_SUCCESS,
    RETRIEVE_FRAMES_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    milestonesList: [],
    milestonesFilteredList: [],
    currentMilestone: {},
    frames: [],
    error: null,
    loading: false,
};

const milestonesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: true, error: null };
        case SET_ERROR:
            return { ...state, loading: false, error: action.payload };
        case RETRIEVE_MILESTONES_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                milestonesList: action.payload.data
            };
        case RETRIEVE_MILESTONE_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE,
                currentMilestone: action.payload.data
            };
        case RETRIEVE_FILTERED_MILESTONES_SUCCESS:
            return { 
                ...state,
                milestonesFilteredList: action.payload
            };
        case CREATE_MILESTONE_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                currentMilestone: action.payload.data
            };
        case UPDATE_MILESTONE_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                currentMilestone: action.payload.data
            };
        case DELETE_MILESTONE_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE
            };
        case DUPLICATE_MILESTONE_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE, 
                currentMilestone: action.payload.data
            };
        case RETRIEVE_FRAMES_SUCCESS:
            return { 
                ...state,
                frames: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

export default milestonesReducer;
