// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'material-ui/styles';
import Card, { CardHeader, CardContent, CardActions } from 'material-ui/Card';
import { FormControl } from 'material-ui/Form';
import { InputAdornment } from 'material-ui/Input';
import TextField from 'material-ui/TextField';
import Search from '@material-ui/icons/Search';
import Avatar from 'material-ui/Avatar';
import Typography from 'material-ui/Typography';
import { ToastContainer } from 'react-toastr';
import Button from 'material-ui/Button';
import Tooltip from 'material-ui/Tooltip';
import SaveIcon from 'material-ui-icons/Save';
import FileIcon from 'material-ui-icons/FileDownload';
import _ from 'lodash';
import moment from 'moment';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import { LoaderModal } from '../components/common';
import {
  retrieveMetrics,
  retrieveMetric,
  storeMetric,
  retrieveMetricReport
} from '../actions';
import owl from "../images/owls/owl.png"

let container;

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit * 2
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 3
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  formControl: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  searchButton: {
    cursor: 'pointer'
  },
  buttonsContainer: {
    display: 'flex',
    marginTop: 16
  }
});

export const mapKeysToTitle = {
  total_users: 'Total Users',
  total_plus_free_users: 'Total Plus Users',
  total_users_upgraded: 'Users that have been charged first time',
  total_users_logged_today: 'Logged Users Today',
  total_plus_logged_today: 'Logged Plus Users Today',
  total_users_logged_today_with_cart: 'Users with items in cart',
  total_plus_logged_today_with_cart: 'Plus users with items in cart',
  total_users_signed_today: 'Users who signed up Today',
  total_plus_signed_today: 'Plus Users who signed up Today',
  total_plus_signed: 'Plus Users who signed up',
  total_users_signed_today_with_cart: 'Signed Users With Cart Today',
  total_plus_signed_today_with_cart: 'Signed Plus Users With Cart Today',
  total_monthly_users: 'Total Monthly Users',
  total_three_month_users: 'Total 3 Months Users',
  total_six_month_users: 'Total 6 Months Users',
  total_one_year_users: 'Total Yearly Users',
  daily_stripe_revenue: 'Today Stripe Revenue',
  daily_paypal_revenue: 'Today Paypal Revenue',
  daily_canceled_subscriptions: 'Web Subscriptions Canceled Today',
  total_in_app_monthly_users: 'Total In App Monthly Users',
  total_in_app_three_month_users: 'Total In App 3 Months Users',
  total_in_app_one_year_users: 'Total In App 1 Year Users',
  total_subscriptions_on_grace_period: 'Total Subscriptions in Grace Period',
  total_one_month_subscriptions_on_grace_period:
    'Total One Month Subscriptions in Grace Period',
  total_three_months_subscriptions_on_grace_period:
    'Total Three Months Subscriptions in Grace Period',
  total_one_year_subscriptions_on_grace_period:
    'Total One Year Subscriptions in Grace Period',
  total_stripe_subscriptions: 'Total Stripe Subscriptions',
  total_one_month_stripe_subscriptions: 'Total One Month Stripe Subscriptions',
  total_three_months_stripe_subscriptions:
    'Total Three Months Stripe Subscriptions',
  total_one_year_stripe_subscriptions: 'Total One Year Stripe Subscriptions',
  total_paypal_subscriptions: 'Total Paypal Subscriptions',
  total_one_month_paypal_subscriptions: 'Total One Month Paypal Subscriptions',
  total_three_months_paypal_subscriptions:
    'Total Three Months Paypal Subscriptions',
  total_one_year_paypal_subscriptions: 'Total One Year Paypal Subscriptions',
  daily_in_app_revenue: 'Today In App Revenue',
  daily_in_app_canceled: 'In App Subscriptions Canceled Today',
  total_plus_signed_up_today_with_paypal:
    'Total Plus Signed Up Today With PayPal',
  total_plus_signed_up_today_with_app: 'Total Plus Signed Up Today With App',
  total_users_signed_up_today_with_app: 'Total Users Signed Up Today With App',
  total_break_up_signed_up_today: 'Break Up of Plus Users Signed Up Today',
  total_break_up_signed_up_today_upgrade:
    'Break Up of Plus Users Signed Up Today - Upgrade',
  total_plus_users: 'Non-Paying Plus Users'
  // date_of_metrics: 'Date of metrics'
};

class Metrics extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.renderMetrics = this.renderMetrics.bind(this);
    this.renderSearchTextField = this.renderSearchTextField.bind(this);
    this.retrievePdfMetricReport = this.retrievePdfMetricReport.bind(this);

    this.state = {
      dateOfMetrics: new moment().format('YYYY-MM-DD')
    };
  }

  componentDidMount() {
    this.props.retrieveMetrics();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== null) {
      container.error(nextProps.error, 'Error', {
        closeButton: true
      });
    }
    if (nextProps.message !== '') {
      container.success(nextProps.message, 'Success', {
        closeButton: true
      });
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSearch() {
    let { dateOfMetrics } = this.state;
    const now = new moment().format('YYYY-MM-DD');
    if (dateOfMetrics !== '') {
      dateOfMetrics = moment(dateOfMetrics).format('YYYY-MM-DD');
      if (now !== dateOfMetrics) {
        this.props.retrieveMetric(dateOfMetrics);
      } else {
        this.props.retrieveMetrics();
      }
    }
  }

  retrievePdfMetricReport() {
    const { dateOfMetrics } = this.state;
    if (dateOfMetrics !== '') {
      this.props.retrieveMetricReport(dateOfMetrics);
    }
  }

  renderSearchTextField() {
    const { classes } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <TextField
          id="dateOfMetrics"
          name="dateOfMetrics"
          label="Date Of Metrics"
          type="date"
          className={classes.textField}
          margin="normal"
          onChange={this.handleChange}
          onBlur={this.handleSearch}
          value={this.state.dateOfMetrics}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search
                  className={classes.searchButton}
                  onClick={this.handleSearch}
                />
              </InputAdornment>
            )
          }}
        />
      </FormControl>
    );
  }

  renderMetrics() {
    const { metricsReport } = this.props;
    let nonPayingPlusUsers = 0;
    if (_.size(metricsReport) > 0) {
      nonPayingPlusUsers =
        parseInt(metricsReport.total_plus_free_users, 10) -
        parseInt(metricsReport.total_plus_users, 10);
    }
    return (
      _.size(metricsReport) > 0 &&
      Object.keys(metricsReport).map((key, index) => {
        const title = mapKeysToTitle[key];
        const value = metricsReport[key];
        return title ? (
          <div
            key={index}
            className="col-12 col-md-6 col-lg-4 milestone-card-header"
          >
            <div className="box">
              <Card key={index}>
                <CardHeader
                  avatar={
                    <Avatar alt={'Milestone Image'} src={owl} />
                  }
                  title={title}
                />
                <CardContent>
                  <Typography variant="display2" align="center">
                    {key === 'total_plus_users' && nonPayingPlusUsers
                      ? nonPayingPlusUsers
                      : value}
                  </Typography>
                </CardContent>
                <CardActions style={{ display: 'flex' }} disableActionSpacing />
              </Card>
            </div>
          </div>
        ) : null;
      })
    );
  }

  render() {
    const { loading, classes } = this.props;
    if (loading) {
      return (
        <AuxHoc>
          <ToastContainer
            ref={ref => (container = ref)}
            className="toast-top-right"
            preventDuplicates
            onClick={() => container.clear()}
          />
          <LoaderModal loading={loading} />
        </AuxHoc>
      );
    }
    return (
      <AuxHoc>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-top-right"
          preventDuplicates
          onClick={() => container.clear()}
        />
        <div className="row justify-content-center align-items-center search-bar">
          <div className="col-6">
            <div className="box">{this.renderSearchTextField()}</div>
          </div>
          <div className={`${classes.buttonsContainer} col-6`}>
            <div className="box">
              <Tooltip title="Take a snapshot">
                <Button variant="fab" onClick={this.props.storeMetric}>
                  <SaveIcon />
                </Button>
              </Tooltip>
            </div>
            <div className="box">
              <Tooltip title="Get PDF Report">
                <Button variant="fab" onClick={this.retrievePdfMetricReport}>
                  <FileIcon />
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="row milestone-cards-container">
          {this.renderMetrics()}
        </div>
      </AuxHoc>
    );
  }
}

const mapStateToProps = ({ metrics }) => {
  const { error, loading, metricsReport, message } = metrics;

  return { error, loading, metricsReport, message };
};

const mapDispatchToProps = {
  retrieveMetrics,
  retrieveMetric,
  storeMetric,
  retrieveMetricReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Metrics));
