// Checked
import React from 'react';
import {NavLink} from 'react-router-dom';
import {ListItem, ListItemIcon, ListItemText} from 'material-ui/List';
import Book from 'material-ui-icons/Book';
import PersonIcon from 'material-ui-icons/Person';
import FontDownloadIcon from 'material-ui-icons/FontDownload';
import ExitToAppIcon from 'material-ui-icons/ExitToApp';
import DateRangeIcon from 'material-ui-icons/DateRange';
import AddAlertIcon from 'material-ui-icons/AddAlert';
import EmailIcon from 'material-ui-icons/Email';
import ListIcon from 'material-ui-icons/List';
import ShowChart from 'material-ui-icons/ShowChart';
import InsertChart from 'material-ui-icons/InsertChart';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';

export const menuListItems = (props) => (
    <div>
        <NavLink to={'/app/books'} activeClassName={'menuListItem'}>
            <ListItem button>
                <ListItemIcon>
                    <Book/>
                </ListItemIcon>
                <ListItemText primary="Books"/>
            </ListItem>
        </NavLink>
        <NavLink
            to={'/app/users'}
            activeClassName={'menuListItem'}
            onClick={() => props.retrieveUsers()}
        >
            <ListItem button>
                <ListItemIcon>
                    <PersonIcon/>
                </ListItemIcon>
                <ListItemText primary="Users"/>
            </ListItem>
        </NavLink>
        <NavLink
            to={'/app/alexanders-orders'}
            activeClassName={'menuListItem'}
            onClick={() => props.getAlexandersOrders()}
        >
            <ListItem button>
                <ListItemIcon>
                    <LocalPrintshopIcon/>
                </ListItemIcon>
                <ListItemText primary="Alexander's orders"/>
            </ListItem>
        </NavLink>
        <NavLink to={'/app/fonts'} activeClassName={'menuListItem'}>
            <ListItem button>
                <ListItemIcon>
                    <FontDownloadIcon/>
                </ListItemIcon>
                <ListItemText primary="Fonts"/>
            </ListItem>
        </NavLink>
        <NavLink to={'/app/metrics'} activeClassName={'menuListItem'}>
            <ListItem button>
                <ListItemIcon>
                    <ShowChart/>
                </ListItemIcon>
                <ListItemText primary="Metrics"/>
            </ListItem>
        </NavLink>
        <NavLink to={'/app/historical-metrics'} activeClassName={'menuListItem'}>
            <ListItem button>
                <ListItemIcon>
                    <InsertChart/>
                </ListItemIcon>
                <ListItemText primary="Historical Metrics"/>
            </ListItem>
        </NavLink>
        {/* <NavLink to={'/app/kids'} activeClassName={'menuListItem'}>
    <ListItem button>
      <ListItemIcon>
        <ChildCareIcon />
      </ListItemIcon>
      <ListItemText primary="Kids" />
    </ListItem>
    </NavLink> */}
        <NavLink to={'/app/milestones'} activeClassName={'menuListItem'}>
            <ListItem button>
                <ListItemIcon>
                    <DateRangeIcon/>
                </ListItemIcon>
                <ListItemText primary="Milestones"/>
            </ListItem>
        </NavLink>
        <NavLink to={'/app/messages-notifications'} activeClassName={'menuListItem'}>
            <ListItem button>
                <ListItemIcon>
                    <EmailIcon/>
                </ListItemIcon>
                <ListItemText primary="Message Notifications"/>
            </ListItem>
        </NavLink>
        <NavLink to={'/app/custom-notifications'} activeClassName={'menuListItem'}>
            <ListItem button>
                <ListItemIcon>
                    <AddAlertIcon/>
                </ListItemIcon>
                <ListItemText primary="Custom Notifications"/>
            </ListItem>
        </NavLink>
        <NavLink to={'/app/sticker-categories'} activeClassName={'menuListItem'}>
            <ListItem button>
                <ListItemIcon>
                    <ListIcon/>
                </ListItemIcon>
                <ListItemText primary="Sticker Categories"/>
            </ListItem>
        </NavLink>
        <NavLink to={'/app/stickers'} activeClassName={'menuListItem'}>
            <ListItem button>
                <ListItemIcon>
                    <ListIcon/>
                </ListItemIcon>
                <ListItemText primary="Stickers"/>
            </ListItem>
        </NavLink>
        <NavLink to={'/app/change-maintenance'} activeClassName={'menuListItem'}>
            <ListItem button>
                <ListItemIcon>
                    <DateRangeIcon/>
                </ListItemIcon>
                <ListItemText primary="Maintenance Message"/>
            </ListItem>
        </NavLink>
        <NavLink
            to={'/login'}
            activeClassName={'menuListItem'}
            onClick={() => props.signOutUser()}
        >
            <ListItem button>
                <ListItemIcon>
                    <ExitToAppIcon/>
                </ListItemIcon>
                <ListItemText primary="Exit"/>
            </ListItem>
        </NavLink>
    </div>
);
