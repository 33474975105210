// Checked
import axios from '../axios-admin';
import {handleError} from '../js/helpers';
import history from './../history';
import {
    RETRIEVE_CUSTOM_NOTIFICATION_LIST_SUCCESS,
    RETRIEVE_CUSTOM_NOTIFICATION_SUCCESS,
    RETRIEVE_FILTERED_CUSTOM_NOTIFICATIONS_SUCCESS,
    SEND_CUSTOM_NOTIFICATION_SUCCESS,
    SET_ERROR,
    SET_LOADING
} from './types';
import env from '../js/env';

const ROOT_URL = '/api';

export function retrieveCustomNotificationsList() {
    const url = `${ROOT_URL}/admin/custom-notifications?lang=${env.GENERAL.lang}`;
    return function (dispatch) {
        dispatch({type: SET_LOADING});
        axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const result = response.data;
            dispatch(retrieveSuccess(result, RETRIEVE_CUSTOM_NOTIFICATION_LIST_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function filterCustomNotifications(customNotificationListFiltered) {
    return function (dispatch) {
        dispatch({type: SET_LOADING});
        dispatch(retrieveSuccess(customNotificationListFiltered, RETRIEVE_FILTERED_CUSTOM_NOTIFICATIONS_SUCCESS));
    };
}

export function retrieveCustomNotification(id, customNotification = null) {
    return function (dispatch) {
        dispatch({type: SET_LOADING});
        if (!customNotification) {
            axios({
                method: 'get',
                url: `${ROOT_URL}/admin/custom-notifications/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/x.babypage.v1.0.0+json',
                    Authorization: localStorage.getItem('token')
                }
            }).then((response) => {
                const dataResponse = response.data;
                dispatch(retrieveSuccess(dataResponse, RETRIEVE_CUSTOM_NOTIFICATION_SUCCESS));
            }).catch((error) => {
                handleError(error);
                if (error !== undefined) {
                    if (error.response !== undefined) {
                        dispatch(retrieveFail(error.response.data.message));
                    }
                }
            });
        } else {
            dispatch(retrieveSuccess({data: {...customNotification}}, RETRIEVE_CUSTOM_NOTIFICATION_SUCCESS));
            if (id) {
                history.push(`/app/custom-notifications/${id}`);
            }
        }
    };
}

export function createCustomNotification(data) {
    return function (dispatch) {
        dispatch({type: SET_LOADING});

        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/custom-notifications`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then(() => {
            history.push('/app/custom-notifications');
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function updateCustomNotification(id, data) {
    return function (dispatch) {
        dispatch({type: SET_LOADING});
        return axios({
            method: 'post',
            url: `${ROOT_URL}/admin/custom-notifications/${id}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }

            throw error;
        });
    };
}

export function deleteCustomNotification(id) {
    return function (dispatch) {
        dispatch({type: SET_LOADING});
        return axios({
            method: 'delete',
            url: `${ROOT_URL}/admin/custom-notifications/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then(() => {
            history.push('/app/custom-notifications');
        });
    };
}

export function duplicateCustomNotification(id) {
    return function (dispatch) {
        dispatch({type: SET_LOADING});
        return axios({
            method: 'post',
            url: `${ROOT_URL}/admin/custom-notifications/${id}/duplicate?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            history.push(`/app/custom-notifications/${response.data.data.id}`);
        });
    };
}

export function sendCustomNotification(data) {
    return function (dispatch) {
        dispatch({type: SET_LOADING});

        return axios({
            method: 'post',
            url: `${ROOT_URL}/admin/custom-notifications/send`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            const milestoneResponse = response.data;
            dispatch(retrieveSuccess(milestoneResponse, SEND_CUSTOM_NOTIFICATION_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }

            throw error;
        });
    };
}

function retrieveSuccess(object, action) {
    return {
        type: action,
        payload: object
    };
}

function retrieveFail(error) {
    return {
        type: SET_ERROR,
        payload: error
    };
}

