// Checked
import {
    RETRIEVE_PURCHASE,
    RETRIEVE_PURCHASE_ERROR,
    RETRIEVE_PURCHASE_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    purchases: null,
    error: null,
    loading: false,
};

const userPurchasesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETRIEVE_PURCHASE:
            return { ...state, loading: true, error: null };
        case RETRIEVE_PURCHASE_ERROR:
            return { ...state, loading: false, error: action.payload };
        case RETRIEVE_PURCHASE_SUCCESS:
            return { 
                ...state,
                ...INITIAL_STATE,
                purchases: action.payload,
            };
        default:
            return state;
    }
};

export default userPurchasesReducer;
