// Checked
import {
    RETRIEVE_ALEXANDERS_ORDER_SUCCESS,
    RETRIEVE_ALEXANDERS_ORDERS_SUCCESS,
    SET_ERROR,
    SET_LOADING,
    UNSET_LOADING_ALEXANDERS
} from '../actions/types';

const INITIAL_STATE = {
    alexandersOrder: null,
    alexandersOrderList: null,
    paginator: null,
    error: null,
    loading: false,
};

const alexandersOrdersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {...state, loading: true, error: null};
        case SET_ERROR:
            return {...state, loading: false, error: action.payload};
        case RETRIEVE_ALEXANDERS_ORDERS_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
                paginator: action.payload,
                alexandersOrderList: action.payload.data
            };
        case RETRIEVE_ALEXANDERS_ORDER_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
                alexandersOrder: action.payload
            };
        case UNSET_LOADING_ALEXANDERS:
            return {...state, loading: false};
        default:
            return state;
    }
};

export default alexandersOrdersReducer;
