// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from 'material-ui/Typography';

import { withStyles } from 'material-ui/styles';
import Card, { CardActions, CardContent } from 'material-ui/Card';
import Button from 'material-ui/Button';
import TextField from 'material-ui/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from 'material-ui/Select';
import InputLabel from '@material-ui/core/InputLabel';

import {
    retrieveQuestions,
    updateQuestions,
    retrieveQuestion,
    createQuestion,
    updateQuestion,
    deleteQuestion,
} from '../actions';

const styles = {
  card: {
    maxWidth: '100%',
  },
};

class Question extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            title: {
                value: this.props.currentQuestion.title,
                required: true,
                error: false,
            },
            question: {
                value: this.props.currentQuestion.question,
                required: true,
                error: false,
            },
            questionExport: {
                value: this.props.currentQuestion.export,
                required: true,
                error: false,
            },
            answers: {
                value: this.props.currentQuestion.answers,
                required: true,
                error: false,
            },
            multiple_choice: {
                value: this.props.currentQuestion.multiple_choice,
                required: true,
                error: false,
            },
        };
    }

    handleChange(event) {
        const property = event.target.name;
        const value = event.target.value;
        this.setState({
            [property]: { ...this.state[property], value },
        });
    }
    
    handleSubmit(event) {
        event.preventDefault();
        const id = this.props.currentQuestion.id;
        const { title, question, questionExport, answers, multiple_choice } = this.state;
        const payload = {
            id,
            title: title.value,
            question: question.value,
            export: questionExport.value,
            answers: answers.value,
            multiple_choice: multiple_choice.value,
            milestone_id: this.props.id,
            order: this.props.currentQuestion.order ? this.props.currentQuestion.order : this.props.questionsList.length,
        };
        if (id != null) {
            this.props.updateQuestion(id, payload);
        } else {
            payload.order++;
            this.props.createQuestion(payload);
        }
    }

    handleClose(event) {
        
    }

    render() {
        const { title, question, questionExport, answers, multiple_choice } = this.state;
        const { id } = this.props.currentQuestion;
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <CardContent>
                        <TextField
                            id="title"
                            name="title"
                            label="Title"
                            value={title.value}
                            placeholder="Title"
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: true }}
                            fullWidth
                        />
                        <TextField
                            id="question"
                            name="question"
                            label="Question"
                            value={question.value}
                            placeholder="Question"
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: true }}
                            fullWidth
                            multiline
                        />
                        <TextField
                            id="questionExport"
                            name="questionExport"
                            label="Export"
                            value={questionExport.value}
                            placeholder="Export"
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: true }}
                            fullWidth
                            multiline
                        />
                        <TextField
                            id="answers"
                            name="answers"
                            label="Answers"
                            value={answers.value}
                            placeholder="Answers"
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: true }}
                            fullWidth
                            multiline
                        />
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel htmlFor="multiple_choice">Type of Answers</InputLabel>
                            <Select
                                native
                                value={multiple_choice.value}
                                onChange={this.handleChange}
                                inputProps={{
                                name: 'multiple_choice',
                                id: 'multiple_choice',
                                }}
                            >
                                <option value={0}>
                                    Simple
                                </option>
                                <option value={1}>
                                    Multiple
                                </option>
                            </Select>
                        </FormControl>
                    </CardContent>
                    <CardActions>
                        <div className="row around-xs" style={{ width: '100%' }}>
                            <Button
                                size="medium"
                                color="primary"
                                type="submit"
                            >
                            {id != null ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="medium"
                                onClick={() => this.props.retrieveQuestion({})}
                            >
                                Cancel
                            </Button>
                            {
                                id != null && 
                                <Button
                                    size="medium"
                                    color="secondary"
                                    onClick={() => {
                                        if (window.confirm('Confirm required to delete this question')) {
                                            this.props.deleteQuestion(id);
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            }
                        </div>
                    </CardActions>
                </form>
            </div>
        );
    }
}

const mapStateToProps = ({ questions }) => {
    const { error, loading, currentQuestion } = questions;

    return { error, loading, currentQuestion };
};

export default connect(mapStateToProps, 
    {
        retrieveQuestions,
        retrieveQuestion,
        updateQuestions,
        createQuestion,
        updateQuestion,
        deleteQuestion,
    }
)(withStyles(styles)(Question));
