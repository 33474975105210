import {
 GET_MAINTENANCE,
 SET_MAINTENANCE_LOADING,
 SET_ERROR,
 TOGGLE_MAINTENANCE,
 SET_MAINTENANCE_MESSAGE
} from '../actions/types';

const INITIAL_STATE = {
    in_maintenance: false,
    body_message: "",
    loading: false,
};

const maintenanceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MAINTENANCE_LOADING:
            return { ...state, loading: true, error: null };
        case SET_ERROR:
            return { ...state, loading: false, error: action.payload };
        case GET_MAINTENANCE:
            return { 
                ...state,
               in_maintenance: action.payload.in_maintenance,
               body_message: action.payload.body_message,
               loading:false
            };
        case TOGGLE_MAINTENANCE:
            return {
                ...state,
                in_maintenance: !state.in_maintenance
            };
        case SET_MAINTENANCE_MESSAGE:
            return {
                ...state,
                body_message: action.payload.body_message,
            }  
        default:
            return state;
    }
};

export default maintenanceReducer;
