// Checked
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import classNames from 'classnames';
import {
    Button,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from 'material-ui';
import Avatar from 'material-ui/Avatar';
import Chip from 'material-ui/Chip';
import Grid from 'material-ui/Grid';
import { CircularProgress } from 'material-ui/Progress';
import { withStyles } from 'material-ui/styles';
import Typography from 'material-ui/Typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAlexandersOrderById, regenerateBooks, resendOrder } from '../actions';
import history from '../history';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import owl from "../images/owls/owl.png"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


let container;

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    rootGridList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        width: 100,
        height: 100,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    chip: {
        margin: theme.spacing.unit,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    card: {
        minWidth: 275,
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AlexandersOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            settings: {
                className: 'bp-carousel',
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                lazyLoad: true,
                arrows: false,
            },
            showDialog: false,
        };

        this.handleDialogToggle = this.handleDialogToggle.bind(this);
        this.handleRegenerateBooks = this.handleRegenerateBooks.bind(this);
        this.handleResendOrder = this.handleResendOrder.bind(this);
        this.userIsPlusWithYearSubscription = this.userIsPlusWithYearSubscription.bind(this);
    }

    componentWillMount() {
        const { id } = this.props.match.params;
        this.props.getAlexandersOrderById(id);
    }

    componentDidUpdate(prevProps) {
        const { id } = this.props.match.params;
        if (prevProps.alexandersOrder !== null && +id !== prevProps.alexandersOrder.id) {
            this.props.getAlexandersOrderById(id);
        }
    }

    userIsPlusWithYearSubscription() {
        const { alexandersOrder } = this.props;
        const user = alexandersOrder.user;

        return !!user.user_level && user.user_level === 1 &&
            ((!!user.in_app_purchase &&
                user.in_app_purchase.title === 'One Year Subscription') ||
                (!!user.stripe_subscriptions &&
                    user.stripe_subscriptions.name === 'one year'));
    }

    handleDialogToggle() {
        this.setState(state => ({ showDialog: !state.showDialog }));
    }

    handleRegenerateBooks() {
        this.props.regenerateBooks(this.props.alexandersOrder.id)
            .then(response => {
                const { id } = response.data;
                history.push(`/app/alexanders-orders/${id}`);
            })
            .catch(error => {
                container.error(error.message, 'Error', {
                    closeButton: false
                });
            })
            .finally(this.handleDialogToggle);
    }

    handleResendOrder() {
        this.props.resendOrder(this.props.alexandersOrder.id)
            .then(() => {
                const { id } = this.props.match.params;
                this.props.getAlexandersOrderById(id);
                toast.success ("You have sent successfully the Alexander's order");
            })
            .catch(error => {
                toast.error (error.message);
            });
    }

    renderContent() {
        const { classes, alexandersOrder, loading } = this.props;
        if (alexandersOrder == null || loading) {
            return <CircularProgress size={24} className={classes.buttonProgress} />;
        }
        return (
            <div className={classes.root}>
                <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={2}>
                        <div className={classes.row}>
                            <Avatar
                                alt="Profile Picture"
                                src={owl}
                                className={classNames(classes.avatar, classes.bigAvatar)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="title" color="inherit">
                            {`${alexandersOrder.user.first_name} ${alexandersOrder.user.last_name}`}
                            {(alexandersOrder.user.user_level === 1) &&
                                <Chip label="Plus Member" className={classes.chip} />
                            }
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            <b>Email:</b> {alexandersOrder.user.email}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            <b>Was the user a plus member?:</b> {alexandersOrder.is_plus === 1 ? 'YES' : 'NO'}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            <b>Alexander's order ID:</b> {alexandersOrder.order_id}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            <b>Current
                                Status:</b> {alexandersOrder.current_status ? alexandersOrder.current_status.status : ''}
                            {alexandersOrder.current_status ? ` at ${alexandersOrder.current_status.date}` : ''}
                        </Typography>
                        {alexandersOrder.billing_address && (
                            <div>
                                <Typography variant="caption" color="inherit">
                                    <b>billing country:</b> {alexandersOrder.billing_address.billing_country === 'US' ? 'US' : 'Canada'}
                                </Typography>
                                <Typography variant="caption" color="inherit">
                                    <b>billing state:</b> {alexandersOrder.billing_address.billing_state}
                                </Typography>
                                <Typography variant="caption" color="inherit">
                                    <b>billing city:</b> {alexandersOrder.billing_address.billing_city}
                                </Typography>
                            </div>
                        )}
                        <Typography variant="caption" color="inherit">
                            <b>Shipping method:</b> {alexandersOrder.ship_method}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            <b>Shipping
                                Address:</b> {`${alexandersOrder.shipping_address.address1 ? `${alexandersOrder.shipping_address.address1}, ${alexandersOrder.shipping_address.address2}
                            \n ${alexandersOrder.shipping_address.city}
                            \n ${alexandersOrder.shipping_address.state}, ${alexandersOrder.shipping_address.zip_code}` : ''}`}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            <b>Tracking Number {alexandersOrder.ship_method.includes('UPS') ? '(UPS)' : '(UPS)'}:</b> {alexandersOrder.tracking_number ||
                                <i>The order has not been shipped yet</i>}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            <b>Cost:</b> ${alexandersOrder.cost.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            size="medium"
                            color="primary"
                            variant="raised"
                            onClick={this.handleDialogToggle}
                            disabled={alexandersOrder.current_status.status === 'REQUESTED' || alexandersOrder.current_status.status === 'REGENERATED' || alexandersOrder.current_status.status === 'REPLACED'}
                        >
                            Regenerate Books
                        </Button>
                        <br />
                        <br />
                        <Button
                            size="medium"
                            color="secondary"
                            variant="raised"
                            onClick={this.handleResendOrder}
                            disabled={alexandersOrder.current_status.status !== 'REGENERATED' || alexandersOrder.books_order.some(book => !book.guts_url)}
                        >
                            Resend Order
                        </Button>
                    </Grid>
                    {
                        alexandersOrder.books_order.map(book => (
                            <Grid item xs={12} sm={12} md={4} lg={4} key={book.id}>
                                <Card>
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                alt='Book thumbnail'
                                                src={book.thumbnail}
                                            />
                                        }
                                        title={book.title}
                                        subheader={`x${book.quantity}`}
                                    />
                                    <CardContent>
                                        <ul>
                                            {book.cover_url !== null && book.cover_url !== book.guts_url && (
                                                <Typography component="p">
                                                    <li>
                                                        <a target="_blank" href={book.cover_url}>Cover URL</a>
                                                    </li>
                                                </Typography>
                                            )}
                                            <Typography component="p">
                                                <li>
                                                    <a target="_blank" href={book.guts_url}>Guts URL</a>
                                                </li>
                                            </Typography>
                                        </ul>
                                        <Typography component="p" className='mb-2'>Number of pages: <strong>{book.baby_pages_counter * 2 }</strong></Typography>
                                        <Typography component="p" className='mb-0'>Number of Babypages: <strong>{book.baby_pages_counter}</strong></Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
                <Dialog
                    open={this.state.showDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        Caution!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            This action will cancel the current Alexander's order and generate a new one
                            so you can re-send the order.
                            <br /><br />
                            You will have to wait a few minutes before re-sending the new order for
                            the books to be generated.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRegenerateBooks} color="primary">
                            Continue
                        </Button>
                        <Button onClick={this.handleDialogToggle} color="secondary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    render() {
        return (
            <AuxHoc>
                {this.renderContent()}
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ alexandersOrders }) => {
    const { error, loading, alexandersOrder } = alexandersOrders;

    return { error, loading, alexandersOrder };
};

export default connect(mapStateToProps,
    { getAlexandersOrderById, regenerateBooks, resendOrder }
)(withStyles(styles)(AlexandersOrder));
