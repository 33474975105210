// Checked
import axios from '../axios-admin';
import { handleError } from '../js/helpers';
import history from './../history';
import {
    SET_LOADING,
    SET_ERROR,
    RETRIEVE_MILESTONES_SUCCESS,
    RETRIEVE_MILESTONE_SUCCESS,
    RETRIEVE_FILTERED_MILESTONES_SUCCESS,
    CREATE_MILESTONE_SUCCESS,
    UPDATE_MILESTONE_SUCCESS,
    DELETE_MILESTONE_SUCCESS,
    DUPLICATE_MILESTONE_SUCCESS,
    RETRIEVE_FRAMES_SUCCESS
} from './types';
import env from '../js/env';
import { toast, ToastContainer } from "react-toastify";

const ROOT_URL = '/api';

export function retrieveMilestones() {
    const url = `${ROOT_URL}/admin/milestones?lang=${env.GENERAL.lang}`;
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const result = response.data;
            dispatch(retrieveSuccess(result, RETRIEVE_MILESTONES_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function filterMilestones(filteredMilestones) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        dispatch(retrieveSuccess(filteredMilestones, RETRIEVE_FILTERED_MILESTONES_SUCCESS));
    };
}

export function retrieveMilestone(id, milestone = null) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        if (!milestone) {
            axios({
                method: 'get',
                url: `${ROOT_URL}/admin/milestone/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/x.babypage.v1.0.0+json',
                    Authorization: localStorage.getItem('token')
                }
            }).then((response) => {
                const milestoneResponse = response.data;
                dispatch(retrieveSuccess(milestoneResponse, RETRIEVE_MILESTONE_SUCCESS));
            }).catch((error) => {
                handleError(error);
                if (error !== undefined) {
                    if (error.response !== undefined) {
                        dispatch(retrieveFail(error.response.data.message));
                    }
                }
            });
        } else {
            dispatch(retrieveSuccess({ data: { ...milestone } }, RETRIEVE_MILESTONE_SUCCESS));
            if (id) {
                history.push(`/app/milestones/${id}`);
            }
        }
    };
}

export function createMilestone(data) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/milestone`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            const milestoneResponse = response.data;
            dispatch(retrieveSuccess(milestoneResponse, CREATE_MILESTONE_SUCCESS));
            history.push(`/app/milestones/${milestoneResponse.data.id}`);
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function updateMilestone(id, data) {
    const response = async (dispatch) => {
        dispatch({ type: SET_LOADING });
        const responseApi =await  axios({
            method: 'post',
            url: `${ROOT_URL}/admin/milestone/${id}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((promise) => {
            const milestoneResponse = promise.data;
            dispatch(retrieveSuccess(milestoneResponse, UPDATE_MILESTONE_SUCCESS));
            return promise.data
        }).catch((error) => {
            handleError(error);
            
        })
        if (responseApi.status === "success") {
            toast.success("Data was update");
        }
        setTimeout(() => {
             history.push('/app/milestones');
        }, 1000);
        return responseApi
    };
    return  response;
}

export function deleteMilestone(id) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'delete',
            url: `${ROOT_URL}/admin/milestone/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const milestoneResponse = response.data;
            dispatch(retrieveSuccess(milestoneResponse, DELETE_MILESTONE_SUCCESS));
            history.push('/app/milestones');
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function duplicateMilestone(id) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/duplicate-milestone/${id}?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const milestoneResponse = response.data;
            dispatch(retrieveSuccess(milestoneResponse, DUPLICATE_MILESTONE_SUCCESS));
            history.push(`/app/milestones/${milestoneResponse.data.id}`);
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function retrieveFrames() {
    const url = `${ROOT_URL}/get-frames?lang=${env.GENERAL.lang}`;
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: env.GENERAL.accept,
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const frames = response.data.data;
            dispatch(retrieveSuccess(frames, RETRIEVE_FRAMES_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

function retrieveSuccess(object, action) {
    return {
        type: action,
        payload: object
    };
}

function retrieveFail(error) {
    return {
        type: SET_ERROR,
        payload: error
    };
}

