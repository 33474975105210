// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import moment from 'moment';
import TextField from 'material-ui/TextField';
import FormControl from '@material-ui/core/FormControl';
import Typography from 'material-ui/Typography';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'material-ui/Select';
import Card, { CardActions, CardContent } from 'material-ui/Card';
import { changeCoverCategory, getCoverCategories } from '../actions';
import LoaderModal from '../js/components/_partials/LoaderModal';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let container;

const styles = theme => ({
    card: {
        maxWidth: '100%',
    }
});

class ChangeCoverCategory extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            category: {
                value: '',
                required: false,
                error: false,
            },
            changeDate: {
                value: '',
                required: false,
                error: false,
            },
        };
    }

    componentDidMount() {
        this.props.getCoverCategories()
            .catch((error) => {
                toast.error (error.response.data.message);
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({ category: { ...this.state.category, value: this.props.coverCategoryList[0] } });
        }
    }

    handleChange(event) {
        const property = event.target.name;
        const value = event.target.value;

        this.setState({
            [property]: { ...this.state[property], value },
        });
    }

    handleChangeDate(date) {
        const { changeDate } = this.state;
        this.setState({
            changeDate: { ...changeDate, value: moment(date.target.value).format('DD-MM-YYYY') },
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const {
            category,
            changeDate,
        } = this.state;

        const result = window.confirm('Confirm required to schedule this task.');
        if (result) {
            const payload = {
                category: category.value,
                change_date: changeDate.value,
            };

            const formData = new FormData();
            Object.keys(payload).forEach(data => {
                if (payload[data] !== undefined) {
                    formData.append(data, payload[data]);
                }
            });

            this.props.changeCoverCategory(formData)
                .then(() => {
                     toast.success ('Successful operation');
                })
                .catch((error) => {
                     toast.error (error.response.data.message);
                });
        }
    }

    render() {
        const { classes } = this.props;
        const todayDate = new moment().format('DD-MM-YYYY');

        return (
            <AuxHoc>
                <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                <LoaderModal loading={this.props.loading} />
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-8">
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography gutterBottom variant="headline" component="h2">
                                        Change Cover Category
                                    </Typography>

                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-8">
                                            <div className="box">
                                                <FormControl
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    <InputLabel htmlFor="category">Cover Category To Change</InputLabel>
                                                    <Select
                                                        native
                                                        value={this.state.category.value}
                                                        onChange={this.handleChange}
                                                        inputProps={{
                                                            name: 'category',
                                                            id: 'category',
                                                        }}
                                                    >
                                                        {this.props.coverCategoryList.map((coverCategory) => (
                                                            <option key={coverCategory} value={coverCategory}>
                                                                {coverCategory}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <div className="box">
                                                <TextField
                                                    id="changeDate"
                                                    name="changeDate"
                                                    label="Change Date"
                                                    value={this.state.changeDate.value}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        required: this.state.changeDate.required,
                                                        min: todayDate,
                                                        max: moment().add('1', 'years').format('DD-MM-YYYY'),
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type="date"
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <div className="row around-xs" style={{ width: '100%' }}>
                                        <Button
                                            size="medium"
                                            color="primary"
                                            type="submit"
                                            disabled={this.props.loading}
                                        >
                                            Notify users and schedule change
                                        </Button>
                                    </div>
                                </CardActions>
                            </Card>

                        </div>
                    </div>
                </form>
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ covers }) => {
    const { error, loading, coverCategoryList } = covers;

    return { error, loading, coverCategoryList };
};

export default connect(mapStateToProps,
    {
        getCoverCategories,
        changeCoverCategory
    }
)(withStyles(styles)(ChangeCoverCategory));

