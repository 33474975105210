// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Grid from 'material-ui/Grid';
import { withStyles } from 'material-ui/styles';
import Avatar from 'material-ui/Avatar';
import { CircularProgress } from 'material-ui/Progress';
import Typography from 'material-ui/Typography';
import Chip from 'material-ui/Chip';
import { PhotoSlider, HeaderCard } from '../components/common';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import { retrieveUserById, retrievePurchaseHistory } from '../actions';
import PurchaseHistory from './PurchaseHistory';
import env from '../js/env';
import owl from "../images/owls/owl.png"

const ROOT_URL = '/api';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    rootGridList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        width: 100,
        height: 100,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    chip: {
        margin: theme.spacing.unit,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    title: {
        //color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    card: {
        minWidth: 275,
    },
    pRight: {
        'padding-right': 25
    }

});

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#a5a5a5", 'padding-top':1, 'border-radius':100, right:-25 }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#a5a5a5", 'padding-top':1, 'border-radius':100, left:-25 }}
      onClick={onClick}
    />
  );
}   

class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            settings: {
                className: 'bp-carousel',
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                lazyLoad: true,
                arrows: true,   
                nextArrow: <SampleNextArrow />,
                prevArrow: <SamplePrevArrow />
            }
        };
    }

    componentWillMount() {
        const { id } = this.props.match.params;
        this.props.retrieveUserById(id);
    }

    componentWillReceiveProps(nextProps) {
        const { user } = nextProps;
        if (this.props.user !== user && user !== null) {
            const url = `${ROOT_URL}/purchase-history/${env.GENERAL.purchase_paginator_limit}?lang=${env.GENERAL.lang}&customer=${user.stripe_id}`;
            this.props.retrievePurchaseHistory(url);
        }
    }

    renderContent() {
        const { classes, user, loading } = this.props;
        const { settings } = this.state;
        if (user == null || loading) {
            return <CircularProgress size={24} className={classes.buttonProgress} />;
        }
        return (
            <div className={classes.root}>
                <Grid container spacing={24}   className={classes.pRight}>
                    <Grid item xs={12} sm={2}>
                        <div className={classes.row}>
                            <Avatar
                                alt="Profile Picture"
                                src={owl}
                                className={classNames(classes.avatar, classes.bigAvatar)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Typography variant="title" color="inherit">
                            {`${user.first_name} ${user.last_name}`}
                            {(user.user_level === 1) &&
                                <Chip label="Plus Member" className={classes.chip} />
                            }
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {`Gender: ${user.gender}`}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {`Email: ${user.email}`}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {`Member Since: ${user.created_at} (${user.account_created_at})`}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {`Last Login: ${user.last_login_at === null && ''} 
                            (${user.account_last_login_at})`}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {`Nº Babies: ${user.total_babies}`}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {`Nº BabyPages: ${user.total_babypages}`}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {`Nº Books: ${user.total_books}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Typography variant="subheading" color="inherit">
                            {
                                user.total_babypages > 0
                                    ? 'Recents babypages' : 'This user has not any babypages yet'
                            }
                        </Typography>
                        {user.total_babypages > 0 &&
                            <PhotoSlider settings={settings} collection={user.babyPages} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={1}>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Typography variant="subheading" color="inherit">
                            {
                                user.total_books > 0
                                    ? 'Recents Books' : 'This user has not any books yet'
                            }
                        </Typography>
                        {user.total_books > 0 &&
                            <PhotoSlider settings={settings} collection={user.books} />
                        }
                    </Grid>
                    {/*                     <Grid item xs={12} sm={12}>
                        {
                            user.stripe_id != null ? 
                            (
                                <div>
                                    <Typography variant="subheading" color="inherit">
                                        {'Purchase History'}
                                    </Typography>
                                    <PurchaseHistory user={user} />
                                </div>
                            ) 
                            : 
                            (
                                <Typography variant="subheading" color="inherit">
                                    {'This user has not any purchase yet'}
                                </Typography>
                            )
                        }   
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                        <Typography variant="subheading" color="inherit">
                            {
                                user.total_babies > 0
                                    ? 'Babies' : 'This user has not any baby yet'
                            }
                        </Typography>
                    </Grid>
                    {
                        user.total_babies > 0 &&
                        user.babies.map(baby => (
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <HeaderCard
                                    id={baby.id}
                                    image={owl}
                                    alt={'Profile Picture'}
                                    title={baby.name}
                                    subtitle={baby.nickname}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        );
    }

    render() {
        return (
            <AuxHoc>
                {this.renderContent()}
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ users }) => {
    const { error, loading, user } = users;

    return { error, loading, user };
};

export default connect(mapStateToProps,
    { retrieveUserById, retrievePurchaseHistory }
)(withStyles(styles)(User));
