// Checked
import axios from '../axios-admin';
import env from '../js/env';
import { handleError } from '../js/helpers';
import history from './../history';
import {
    SET_LOADING,
    UNSET_LOADING,
    SET_ERROR,
    RETRIEVE_STICKER_CATEGORIES_SUCCESS,
    RETRIEVE_ALL_STICKER_CATEGORIES_SUCCESS,
    RETRIEVE_STICKER_CATEGORY_SUCCESS,
    CREATE_STICKER_CATEGORY_SUCCESS,
    UPDATE_STICKER_CATEGORY_SUCCESS,
    DELETE_STICKER_CATEGORY_SUCCESS
} from './types';

const ROOT_URL = '/api';

export function retrieveStickerCategories(page, search) {
    const currentPage = page || 1;
    const searchWord = search || null;
    let url = '';
    if (searchWord === null) {
        url = `${ROOT_URL}/admin/sticker-categories?limit=${env.GENERAL.paginator_limit}&lang=${env.GENERAL.lang}&page=${currentPage}`;
    } else {
        url = `${ROOT_URL}/admin/sticker-categories?limit=${env.GENERAL.paginator_limit}&lang=${env.GENERAL.lang}&page=${currentPage}&search=${searchWord}`;
    }
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const paginatorResponse = response.data.data;
            dispatch(retrieveSuccess(paginatorResponse, RETRIEVE_STICKER_CATEGORIES_SUCCESS));
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function retrieveAllStickerCategories() {
    const url = `${ROOT_URL}/admin/all-sticker-categories?lang=${env.GENERAL.lang}`;
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const allStickerCategoriesResponse = response.data.data;
            dispatch(retrieveSuccess(allStickerCategoriesResponse, RETRIEVE_ALL_STICKER_CATEGORIES_SUCCESS));
            dispatch({ type: UNSET_LOADING });
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function retrieveStickerCategoryById(id) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'get',
            url: `${ROOT_URL}/admin/sticker-categories/${id}?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const stickerCategoryResponse = response.data.data;
            dispatch(retrieveSuccess(stickerCategoryResponse, RETRIEVE_STICKER_CATEGORY_SUCCESS));
            history.push(`/app/sticker-categories/${id}`);
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function createStickerCategory(data) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'post',
            url: `${ROOT_URL}/admin/sticker-categories?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            const stickerCategoryResponse = response.data.data;
            dispatch(retrieveSuccess(stickerCategoryResponse, CREATE_STICKER_CATEGORY_SUCCESS));
            history.push(`/app/sticker-categories/${stickerCategoryResponse.id}`);
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function updateStickerCategory(id, data) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'patch',
            url: `${ROOT_URL}/admin/sticker-categories/${id}?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            },
            data
        }).then((response) => {
            const stickerCategoryResponse = response.data.data;
            dispatch(retrieveSuccess(stickerCategoryResponse, UPDATE_STICKER_CATEGORY_SUCCESS));
            history.push('/app/sticker-categories');
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

export function deleteStickerCategory(id) {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        axios({
            method: 'delete',
            url: `${ROOT_URL}/admin/sticker-categories/${id}?lang=${env.GENERAL.lang}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/x.babypage.v1.0.0+json',
                Authorization: localStorage.getItem('token')
            }
        }).then((response) => {
            const stickerCategoryResponse = response.data.data;
            dispatch(retrieveSuccess(stickerCategoryResponse, DELETE_STICKER_CATEGORY_SUCCESS));
            history.push('/app/sticker-categories');
        }).catch((error) => {
            handleError(error);
            if (error !== undefined) {
                if (error.response !== undefined) {
                    dispatch(retrieveFail(error.response.data.message));
                }
            }
        });
    };
}

function retrieveSuccess(object, action) {
    return {
        type: action,
        payload: object
    };
}

function retrieveFail(error) {
    return {
        type: SET_ERROR,
        payload: error
    };
}

