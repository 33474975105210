// Checked
import {
    SET_LOADING,
    SET_ERROR,
    RETRIEVE_METRICS_SUCCESS,
    RETRIEVE_METRIC_SUCCESS,
    STORE_METRIC_SUCCESS,
    RETRIEVE_METRIC_REPORT_SUCCESS,
    RETRIEVE_ALL_METRICS_SUCCESS,
    SET_LOADING_OFF
} from '../actions/types';

const INITIAL_STATE = {
    metricsReport: {},
    error: null,
    loading: false,
    message: '',
    historicalMetrics: []
};

const metricsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: true, error: null, message: '' };
        case SET_LOADING_OFF:
            return { ...state, loading: false, error: null };
        case SET_ERROR:
            return {
                ...state,
                ...INITIAL_STATE,
                error: action.payload
            };
        case RETRIEVE_METRICS_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
                metricsReport: action.payload
            };
        case RETRIEVE_METRIC_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
                metricsReport: action.payload
            };
        case STORE_METRIC_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: 'Metric stored successfully'
            };
        case RETRIEVE_METRIC_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: ''
            };
        case RETRIEVE_ALL_METRICS_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
                historicalMetrics: action.payload
            };
        default:
            return state;
    }
};

export default metricsReducer;
