// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from 'material-ui/styles';
import Card, { CardActions, CardContent, CardMedia } from 'material-ui/Card';
import Button from 'material-ui/Button';

import TextField from 'material-ui/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import {
    retrieveMessagesNotification,
    createMessagesNotification,
    updateMessagesNotification,
    deleteMessagesNotification,
} from '../actions';
import { LoaderModal } from './common';
import noImageIcon from '../images/no-image-icon.png'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let container;

const styles = {
    card: {
        maxWidth: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
};

class MessagesNotification extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.NotificationMessageCard = this.NotificationMessageCard.bind(this);

        this.state = {
            title: {
                value: this.props.currentMessagesNotification.title,
                required: true,
                error: false,
            },
            subject: {
                value: this.props.currentMessagesNotification.subject,
                required: true,
                error: false,
            },
            description: {
                value: this.props.currentMessagesNotification.description,
                required: true,
                error: false,
            },
            image: {
                value: this.props.currentMessagesNotification.email_image_url,
                file: null,
                required: true,
                error: false,
            },
            emailNotificationMessage: {
                value: this.props.currentMessagesNotification.email_message,
                required: true,
                error: false,
            },
            pushNotificationMessage: {
                value: this.props.currentMessagesNotification.push_message,
                required: true,
                error: false,
            }
        };
    }

    componentDidMount() {
        const { currentMessagesNotification } = this.props;
        const { id } = this.props.match.params;
        if (id !== 'new' && Object.keys(currentMessagesNotification).length === 0) {
            this.props.retrieveMessagesNotification(id);
        } else {
            let imgUrl = '';
            if (id === 'new') {
                imgUrl = noImageIcon;
            } else {
                imgUrl = currentMessagesNotification.email_image_url == null ?
                    noImageIcon
                    : currentMessagesNotification.email_image_url;
            }
            this.setState({
                image: {
                    ...this.state.image,
                    value: imgUrl
                }
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.error != null) {
            toast.error (nextProps.error);
        }
    }

    componentDidUpdate(prevProps) {
        const { currentMessagesNotification } = this.props;
        const { id } = this.props.match.params;
        if (currentMessagesNotification !== prevProps.currentMessagesNotification) {
            const self = this;
            Object.keys(currentMessagesNotification).forEach(
                property => {
                    const value = self.props.currentMessagesNotification[property];
                    if (self.state.hasOwnProperty(property)) {
                        self.setState({
                            [property]: { ...self.state[property], value },
                        });
                    }
                }
            );
            let imgUrl = '';
            if (id === 'new') {
                imgUrl = noImageIcon;
            } else {
                imgUrl = currentMessagesNotification.email_image_url == null ?
                    `/owls/${currentMessagesNotification.image}`
                    : currentMessagesNotification.email_image_url;
            }
            this.setState({
                image: {
                    ...this.state.image,
                    value: imgUrl
                }
            });
        }
    }

    handleChangeDate(date) {
        const { active_date } = this.state;
        this.setState({
            active_date: { ...active_date, value: date },
        });
    }

    handleChange(event) {
        const property = event.target.name;
        const value = event.target.value;
        this.setState({
            [property]: { ...this.state[property], value },
        });
    }

    handleSubmit(event) {
        const { title, description, subject, image, emailNotificationMessage, pushNotificationMessage } = this.state;
        event.preventDefault();
        const { id } = this.props.match.params;
        if (id === 'new') {
            if (image.file == null) {
                this.setState({ image: { ...image, error: true } });
            } else {
                const payload = {
                    title: title.value,
                    description: description.value,
                    subject: subject.value,
                    email_message: emailNotificationMessage.value,
                    push_message: pushNotificationMessage.value,
                    image: image.file,
                };
                const formData = new FormData();
                Object.keys(payload).forEach(data => {
                    formData.append(data, payload[data]);
                });

                this.props.createMessagesNotification(formData);
            }
        } else {
            const payload = {
                title: title.value,
                description: description.value,
                subject: subject.value,
                emailNotificationMessage: emailNotificationMessage.value,
                pushNotificationMessage: pushNotificationMessage.value,
                image: image.file != null ? image.file : undefined,
            };
            const formData = new FormData();
            Object.keys(payload).forEach(data => {
                formData.append(data, payload[data]);
            });
            this.props.updateMessagesNotification(id, formData);
        }
    }

    handleImage(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            this.setState({
                image: {
                    ...this.state.image,
                    file,
                    error: false,
                    value: reader.result
                }
            }
            );
        }.bind(this);
    }

    NotificationMessageCard() {
        const { classes } = this.props;
        const { title, description, subject, image, emailNotificationMessage, pushNotificationMessage } = this.state;
        const { id } = this.props.match.params;

        return (
            <Card className={classes.card}>
                <form onSubmit={this.handleSubmit}>
                    <CardMedia
                        className={classes.media}
                        image={image.value}
                        title={title.value}
                        style={{ backgroundSize: 'contain' }}
                    />
                    <CardContent>
                        <div className="row justify-content-center align-items-center" style={{ width: '100%' }}>
                            <input
                                accept="image/*"
                                className={classes.input}
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={this.handleImage}
                            />
                            <label htmlFor="raised-button-file">
                                {
                                    image.error &&
                                    <FormHelperText
                                        id="name-error-text"
                                        style={{
                                            textAlign: 'center',
                                            padding: 5,
                                            color: 'red',
                                        }}
                                    >
                                        Image required
                                        </FormHelperText>
                                }
                                <Button
                                    color="primary"
                                    variant="raised"
                                    component="span"
                                >
                                    Upload image
                                    </Button>
                            </label>
                        </div>
                        <TextField
                            id="title"
                            name="title"
                            label="Title"
                            value={title.value}
                            placeholder="Title"
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: true }}
                            error={title.value === ''}
                            fullWidth
                        />
                        <TextField
                            id="subject"
                            name="subject"
                            label="Subject"
                            value={subject.value}
                            placeholder="Subject"
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: true }}
                            error={subject.value === ''}
                            fullWidth
                        />
                        <TextField
                            id="description"
                            name="description"
                            label="Description"
                            placeholder="Description"
                            value={description.value}
                            error={description.value === ''}
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ required: true }}
                            fullWidth
                        />
                        <TextField
                            id="emailNotificationMessage"
                            name="emailNotificationMessage"
                            label="Email NotificationMessage"
                            placeholder="Email Notification Message"
                            value={emailNotificationMessage.value}
                            error={emailNotificationMessage.value === ''}
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{
                                required: true,
                                maxLength: 5000
                            }}
                            fullWidth
                            multiline
                        />
                        <TextField
                            id="pushNotificationMessage"
                            name="pushNotificationMessage"
                            label="Push Notification Message"
                            placeholder="Push Notification Message"
                            value={pushNotificationMessage.value}
                            error={pushNotificationMessage.value === ''}
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{
                                required: true,
                                maxLength: 150
                            }}
                            fullWidth
                            multiline
                        />
                    </CardContent>
                    <CardActions>
                        <div className="row around-xs" style={{ width: '100%' }}>
                            <Button
                                size="medium"
                                color="primary"
                                type="submit"
                            >
                                {id !== 'new' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="medium"
                                onClick={() => this.props.history.push('/app/messages-notifications')}
                            >
                                Cancel
                            </Button>
                            {
                                id !== 'new' &&
                                <Button
                                    size="medium"
                                    color="secondary"
                                    onClick={() => {
                                        if (window.confirm('Confirm required to delete this mileston')) {
                                            this.props.deleteMessagesNotification(id);
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            }
                        </div>
                    </CardActions>
                </form>
            </Card>
        );
    }

    render() {
        const { currentMessagesNotification, loadingMessagesNotifications } = this.props;
        const { id } = this.props.match.params;
        return (
            <AuxHoc>
                <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                {
                    loadingMessagesNotifications ?
                        <LoaderModal loadingMessagesNotifications={loadingMessagesNotifications} /> :
                        <div className="row around-xs">
                            <div className="col-12 col-md-6">
                                <div className="box">
                                    {
                                        currentMessagesNotification &&
                                        this.NotificationMessageCard()
                                    }
                                </div>
                            </div>
                        </div>
                }
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ messagesNotifications }) => {
    const { error, loadingMessagesNotifications, currentMessagesNotification } = messagesNotifications;

    return { error, loadingMessagesNotifications, currentMessagesNotification };
};

export default connect(mapStateToProps,
    {
        retrieveMessagesNotification,
        createMessagesNotification,
        updateMessagesNotification,
        deleteMessagesNotification,
    }
)(withStyles(styles)(MessagesNotification));
