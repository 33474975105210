// Checked
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'material-ui';
import { withStyles } from 'material-ui/styles';
import AddIcon from 'material-ui-icons/Add';
import Button from 'material-ui/Button';
import Tooltip from 'material-ui/Tooltip';
import Card, { CardActions, CardHeader } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import IconButton from 'material-ui/IconButton';
import ContentCopy from 'material-ui-icons/ContentCopy';
import AuxHoc from '../hoc/AuxHoc/AuxHoc';
import {
    duplicateCustomNotification,
    filterCustomNotifications,
    retrieveCustomNotificationsList,
    retrieveCustomNotification,
} from '../actions';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let container;



const styles = theme => ({
    fab: {
        margin: theme.spacing.unit * 2,
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3,
    },
});

class NotificationList extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.duplicate = this.duplicate.bind(this);

        this.state = {
            searchWords: '',
            wait: null
        };
    }

    componentDidMount() {
        this.props.retrieveCustomNotificationsList();
        this.props.retrieveCustomNotification(undefined, {});
        this.props.filterCustomNotifications([]);
    }

    handleChange(event) {
        const self = this;
        const { customNotificationList } = this.props;
        const searchWords = event.target.value;
        let { wait } = this.state;

        if (wait !== null) {
            clearTimeout(wait);
        }

        wait = setTimeout(() => {
            const searchWordsArray = searchWords.trim()
                .split(' ')
                .filter(word => word.length > 0)
                .map(word => word.toLowerCase());
            const filteredCustomNotifications = customNotificationList.filter(
                milestone => {
                    const matchesArray = searchWordsArray.filter(
                        word => milestone.title.toLowerCase().indexOf(word) >= 0
                    );
                    return matchesArray.length > 0;
                }
            );
            self.props.filterCustomNotifications(filteredCustomNotifications);
        }, 300);
        this.setState({ searchWords, wait });
    }

    duplicate(id) {
        this.props.duplicateCustomNotification(id)
            .catch((error) => {
                toast.error (error.response.data.message);
            });
    }

    renderCustomNotificationCards(customNotificationList) {
        const self = this;
        return (
            customNotificationList.length > 0 &&
            customNotificationList.map(customNotification => {
                const imgUrl = customNotification.url_image == null ?
                    customNotification.image_url
                    : customNotification.url_image;
                const customNotificationId = customNotification.id;
                return (
                    <div
                        key={customNotificationId}
                        className="col-12 col-md-6 col-lg-4 milestone-card-header"
                    >
                        <div className="box">
                            <Card key={customNotificationId}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            alt={'Milestone Image'}
                                            src={imgUrl}
                                        />
                                    }
                                    title={customNotification.title}
                                    onClick={() =>
                                        self.props.retrieveCustomNotification(customNotificationId, customNotification)
                                    }
                                />
                                <CardActions style={{ display: 'flex' }} disableActionSpacing>
                                    <IconButton
                                        aria-label="Duplicate milestone"
                                        onClick={() => self.duplicate(customNotificationId)}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        <ContentCopy />
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </div>
                    </div>
                );
            })
        );
    }

    render() {
        const { customNotificationList, customNotificationListFiltered } = this.props;

        return (
            <AuxHoc>
                <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                />
                <div className="row justify-content-center align-items-center search-bar">
                    <div className="col-6">
                        <div className="box">
                            <TextField
                                id="search"
                                name="search"
                                placeholder="Search by title"
                                value={this.state.searchWords}
                                onChange={this.handleChange}
                                margin="normal"
                                inputProps={{ required: false }}
                                autoFocus
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
                <div className="row milestone-cards-container">
                    {
                        customNotificationListFiltered.length > 0 ?
                            this.renderCustomNotificationCards(customNotificationListFiltered)
                            :
                            this.renderCustomNotificationCards(customNotificationList)
                    }

                </div>
                <Tooltip title="Create a new Custom Notification">
                    <Button
                        variant="fab"
                        color="secondary"
                        className={this.props.classes.absolute}
                        onClick={() => this.props.history.push('/app/custom-notification/new')}
                    >
                        <AddIcon />
                    </Button>
                </Tooltip>
            </AuxHoc>
        );
    }
}

const mapStateToProps = ({ customNotifications }) => {
    const { error, loading, customNotificationList, customNotificationListFiltered } = customNotifications;

    return { error, loading, customNotificationList, customNotificationListFiltered };
};

export default connect(mapStateToProps,
    {
        retrieveCustomNotificationsList,
        filterCustomNotifications,
        retrieveCustomNotification,
        duplicateCustomNotification,
    }
)(withStyles(styles)(NotificationList));
